import ConferenceInterface from "./ConferenceInterface";

export default class Conference implements ConferenceInterface {
    active_doc_id: number;
    agenda: string;
    allow_duplicate_connections: boolean | null;
    created_at: string;
    date_end: string;
    date_start: string;
    id: number;
    is_finished: boolean;
    is_for_testing: boolean;
    lector_is_streaming: boolean;
    name: string;
    open_for_guests: boolean;
    participants_mode: string;
    quorum_amnt: number | null;
    updated_at: string;
    used_servers: string;

    constructor(_data: ConferenceInterface) {
        this.active_doc_id = _data.active_doc_id;
        this.agenda = _data.agenda;
        this.allow_duplicate_connections = _data.allow_duplicate_connections;
        this.created_at = _data.created_at;
        this.date_end = _data.date_end;
        this.date_start = _data.date_start;
        this.id = _data.id;
        this.is_finished = _data.is_finished;
        this.is_for_testing = _data.is_for_testing;
        this.lector_is_streaming = _data.lector_is_streaming;
        this.name = _data.name;
        this.open_for_guests = _data.open_for_guests;
        this.participants_mode = _data.participants_mode;
        this.quorum_amnt = _data.quorum_amnt;
        this.updated_at = _data.updated_at;
        this.used_servers = _data.used_servers;
    }

}