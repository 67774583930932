import React from "react";
import "./sign_in.scss";
import {RailsField} from "../../../editors/rails_field/RailsField";
import {ApiEngine} from "api-engine";
import {UserContext} from "../../../../contexts/UserContext";
import Participant from "../../../models/participants/Participant";
import ParticipantInterface from "../../../models/participants/ParticipantInterface";

interface SignInProps {
  api: ApiEngine;
}

interface SignInState {
  cell: string;
  password: string;
  error: string | null;
}

export default class SignIn extends React.Component<SignInProps, SignInState> {

  constructor(_props: SignInProps) {
    super(_props);
    this.state = {
      cell: "",
      password: "",
      error: null
    }

    this.checkParticipant = this.checkParticipant.bind(this);
  }

  checkParticipant(): Promise<ParticipantInterface | null> {
    let me = this;

    return new Promise((_resolve, _reject) => {
      me.setState({ error: null }, () => {
        me.props.api.asyncFetch(`/api/participants/sign_in`,{
          method: "POST",
          body: JSON.stringify({
            cell: me.state.cell.replaceAll("+7","").replaceAll("(","").replaceAll(")","").replaceAll("-",""),
            password: me.state.password
          })
        }).then((_res) => {
          if (_res.error) {
            me.setState({error: _res.error}, () => {
              _reject(_res.error);
            });
            return;
          }
          // alert(JSON.stringify(_res.result));
          let _p = new Participant(_res.result);
          _p.jwt = _res.result.jwt;
          _resolve(_p);
        }).catch((_res) => {
          me.setState({error: _res.toString()}, () => {
            _reject(_res.toString());
          });
        });
      });
    });
  }

  render() {
    let me = this;
    let fields = [
      {
        title: "Номер телефона",
        var_name: "cell",
        type: "tel",
        imask: "+7(000)000-00-00",
        value: ""
      } as any,
      {
        title: "Пароль",
        var_name: "password",
        type: "password",
        value: ""
      } as any,
    ] as any[];
    let participant = this.context;
    return <div className={"sign-in-part"}>
      <p>Вход</p>
      {
        fields.map((_f:any, _fIndex: number) => {
          return <RailsField model={"User"}
                      update_url={""}
                      api={me.props.api}
                      blocked={false}
                      placeholder={_f.title}
                      disableSaveButton={true}
                      onChange={(_val) => {
                        let prevState = {...me.state} as any;
                        if (!_f.var_name) return;
                        prevState[_f.var_name] = _val;
                        me.setState(prevState);
                      }
                      }
                     {..._f}
          />
        })
      }

      <UserContext.Consumer>
        { ({participant, setParticipant }) => (
          <button style={{
            width: "80%",
            opacity: me.state.cell.length > 5 && me.state.password.length > 6 ? 1 : 0.5,
            pointerEvents: me.state.cell.length > 5 && me.state.password.length > 6 ? "all" : "none",
            background: "var(--color-main)",
            color: "var(--white)"
          }} onClick={() => {
            me.checkParticipant().then((_e: ParticipantInterface | null) => {
              if (_e) {
                let _p = new Participant(_e);
                _p.jwt = _e.jwt;
                setParticipant(_p);
              }
            }).catch((_res) => {})
          }}>ВОЙТИ</button>

        )
        }
      </UserContext.Consumer>
      { me.state.error &&
        <p style={{color: "var(--red)"}}>{me.state.error}</p>
      }

    </div>;
  }
}

SignIn.contextType = UserContext;