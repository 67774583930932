import React from "react";
import Participant from "../../models/participants/Participant";
import "./participant-card.scss";

interface ParticipantCardProps {
    participant: Participant
}

export default class ParticipantCard extends React.Component<ParticipantCardProps, any> {
    constructor(_props: ParticipantCardProps) {
        super(_props);
        this.state = {
        };
    }

    render() {
        let me = this;
        return <div className={`participant-card`} style={{pointerEvents: "none"}}>
            <h2>{me.props.participant.name()}</h2>
            {/*<p>{JSON.stringify(me.props.participant)}</p>*/}
            { me.props.participant.region_title &&
              <p>Субъект: <span style={{fontWeight: "600"}}>{me.props.participant.region_title}</span></p>
            }
            { me.props.participant.job &&
              <p>Должность: <span style={{fontWeight: "600"}}>{me.props.participant.job}</span></p>
            }
            <p style={{
                position: "absolute",
                top: "1px",
                right: "0px",
                fontSize: "8px",
                opacity: 0.3,
            }}>{me.props.participant.role}</p>
        </div>
    }
}