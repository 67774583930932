import React from "react";
import VideoControls from "./videocontrols/VideoControls";
import JanusConnector from "../../../gateways/janus/JanusConnector";
import janusBoilerPlate from "./janusBoilerPlate.json";
import "./video-grid.scss";
import "../../assets/shared/positioning.css";
import MyStreamView from "../my_stream_view/MyStreamView";
import Participant from "../../models/participants/Participant";
import ScreenFull from "screenfull";
import VideoRecorder from "./video-recorder/VideoRecorder";
import {param} from "jquery";
import {ApiEngine} from "api-engine";

interface VideoGridProps {
    stream?: MediaStream,
    janusConnectors: JanusConnector[],
    myself: Participant,
    conferenceId: number;
    api: ApiEngine;
}

interface VideoGridState {
    // stream: MediaStream,
    gridIndex: number
}

export default class VideoGrid extends React.Component<VideoGridProps,VideoGridState> {
    private videoGridContainerRef: React.RefObject<HTMLDivElement>;
    mounted: boolean;
    private remoteRefs: any;
    private janusConnectorRefs: any;
    private currentConnectedJanusIndex: number;
    private publishingJanusConnector: JanusConnector | null;

    constructor(_props: VideoGridProps) {
        super(_props);
        this.videoGridContainerRef = React.createRef<HTMLDivElement>();
        this.remoteRefs = {} as any;
        this.janusConnectorRefs = [];
        this.publishingJanusConnector = null;

        for(let _janusConnector of this.props.janusConnectors) {
            this.janusConnectorRefs.push(React.createRef<MyStreamView>());
            this.remoteRefs[_janusConnector.name] = [];
            for(let i = 0; i < 9; i++) {
                this.remoteRefs[_janusConnector.name].push(React.createRef<HTMLDivElement>());
            }
        }

        this.moveToFullscreen = this.moveToFullscreen.bind(this);
        this.changeGridIndex = this.changeGridIndex.bind(this);
        this.initStreamViews = this.initStreamViews.bind(this);

        this.state = {
            gridIndex: 0,

        }
        // @ts-ignore
        this.JanusClass = Janus;
        this.mounted = false;
        this.currentConnectedJanusIndex = 0;
    }

    changeGridIndex(_delta: number) {
        let me = this;
        let prevState ={...me.state};
        let nextVal = prevState.gridIndex + _delta;
        if (nextVal < 0) return;
        let elements = Array.from(document.getElementsByClassName("video-remote") as HTMLCollectionOf<HTMLElement> );
        let activeElements = [] as any[];
        for(let i = 0; i < elements.length; i++) {
            let element = elements[i];
            let numOfChildren = element.children.length;
            let classList = element.classList.length;
            if (numOfChildren > 2 && classList > 1) {
                activeElements.push(element);
            }
        }
        prevState.gridIndex = nextVal;

        if (prevState.gridIndex * 8 > activeElements.length) return;

        for(let el of activeElements) {
            el.style.display = "none";
        }

        for (let i = (prevState.gridIndex) * 8; i < (prevState.gridIndex + 1) * 8; i++) {
            let el = activeElements[i];
            if (!el) continue;
            console.log(i);
            el.style.display = "flex";
        }

        me.setState(prevState, () => {
            // let el = me.videoGridContainerRef.current;
            // el?.scrollBy(0, _delta * el?.clientHeight);
        });
    }

    moveVideoElementToFullscreen(parent: any) {
        parent.style.position = "absolute";
        parent.style.display = "flex";
        parent.style.margin = "0";
        parent.style.top = "0";
        parent.style.left = "0";
        parent.style.width = "100%";
        parent.style.minHeight = "100%";
        parent.style.zIndex = "10";
        parent.style.margin = "0";

        let pElement = parent.getElementsByTagName("p")[0];
        if (pElement) {
            pElement.style.bottom = 0;
            // pElement
        }
    }

    moveVideoElementToNormalscreen(parent: any) {
        parent.style.marginLeft = "3%";
        parent.style.marginRight = "3%";
        parent.style.marginBottom = "10px";

        parent.style.position = "relative";
        parent.style.width = " 27%";
        parent.style.top = "0";
        parent.style.left = "0";
        parent.style.width = "27%";
        parent.style.minHeight = "calc( (100% - 2 * 10px) / 3)";
        parent.style.height = "calc( (100% - 2 * 10px) / 3)";
        parent.style.zIndex = "unset";
        let pElement = parent.getElementsByTagName("p")[0];
        // if (pElement) {
        //     pElement.style.bottom = "-15%";
        //     // pElement
        // }

    }

    componentDidMount() {
        if (this.mounted) return;
        this.mounted = true;

        let me = this;
        document.addEventListener("VideoGrid", (_event: any) => {
            // alert(JSON.stringify(_event.detail));
            let el = null;
            if (_event.detail.title === "move_to_fullscreen") {
                el = document.getElementsByClassName(`video-for-${_event.detail.peer_id}`)[0];
            }

            if (_event.detail.title === "trigger-main") {
                el = document.getElementsByClassName(`is-main`)[0];
            }

            if (!el) { return}
            let parent = el.parentElement;

            if (parent) {
                if (_event.detail.value) {
                    let allOtherVideos = document.getElementsByClassName(`rounded`);
                    for (let i = 0; i < allOtherVideos.length; i++) {
                        let el = allOtherVideos[i];
                        let prnt = el.parentElement;
                        if (prnt) me.moveVideoElementToNormalscreen(prnt);
                    }
                    me.moveVideoElementToFullscreen(parent);
                } else {
                    me.moveVideoElementToNormalscreen(parent);
                }
            }
        });
        me.initStreamViews();
    }

    initStreamViews() {
        console.log("Init of stream views");
        let me = this;
        if (me.currentConnectedJanusIndex > (me.props.janusConnectors.length - 1)) {
            console.log(`Index is ${me.currentConnectedJanusIndex}, we are done exiting`)
            return;
        }
        let currentJanus = me.props.janusConnectors[me.currentConnectedJanusIndex];
        console.log(`Checking current janus: ${me.currentConnectedJanusIndex}`)
        if (currentJanus.successfullyConnectedAsPublisher === undefined) {
            console.log("Connection status is not set, figuing out why");
            if (currentJanus.connecting) {
                console.log("Still connecting, waiting more");
                setTimeout(me.initStreamViews, 300);
                return;
            }
            if (me.janusConnectorRefs[me.currentConnectedJanusIndex].current) {
                console.log("No connection command sent, so let's connect");
                me.janusConnectorRefs[me.currentConnectedJanusIndex].current.connect(!me.publishingJanusConnector);
            }
            setTimeout(me.initStreamViews, 300);
            return;
        }

        if (currentJanus.successfullyConnectedAsPublisher) {
            me.publishingJanusConnector = currentJanus;
        }

        me.currentConnectedJanusIndex++;
        setTimeout(me.initStreamViews, 300);
    }

    moveToFullscreen(_ref: React.RefObject<HTMLDivElement>) {
        if (!_ref.current) return;
        if (_ref.current.children.length > 0) {
            // screenfull.
            ScreenFull.request(_ref.current);
            // _ref.current?.requestFullscreen();
        }
    }

    render() {
        let me = this;
        return <div style={{position: "relative"}} className={'flex flex-column'}>
            <div className={`card-100`}>
                <h4 style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                    <span>Видео</span>
                    <button style={{borderRadius: "10px", padding: "7px", marginLeft: "auto"}} onClick={(e) => { me.moveToFullscreen(me.videoGridContainerRef)} }>Полный экран</button>
                </h4>

                <div ref={me.videoGridContainerRef}
                     className={"videogrid-container flex flex-row flex-wrap justify-start"}
                     style={{position: "relative", overflowY: "hidden"}}
                >
                    <div className={"back-to-normal"}
                         onClick={() => {
                             if (document.fullscreenElement) {
                                 document
                                   .exitFullscreen()
                                   .then(() => console.log("Document Exited from Full screen mode"))
                                   .catch((err) => console.error(err));
                             }
                         }}
                    >
                        Закрыть
                    </div>
                    {
                        me.props.janusConnectors.map((_janusConnector: JanusConnector, _key) => {
                            return <MyStreamView
                                        ref={me.janusConnectorRefs[_key]}
                                        key={`my-stream-${_key}`}
                                        myself={me.props.myself}
                                        localFeedJanusConnector={_janusConnector}/>
                        })
                    }
                    {
                        me.props.janusConnectors.map((_janusConnector: JanusConnector, _janusKey: number) => {
                            return <>
                                { [1,2,3,4,5,6,7,8,9].map((_x: number) => {
                                    const key = `remoteVideoRef${_x}-of-connector-${_janusConnector.name}-${_janusKey}` as keyof HTMLDivElement;
                                    // let targetRef = me[key] as React.RefObject<HTMLDivElement>;
                                    return <div key={key}
                                                ref={me.remoteRefs[_janusConnector.name][_x]}
                                                onClick={(e) => {
                                                    // alert(key);
                                                    me.moveToFullscreen(me.remoteRefs[_janusConnector.name][_x])}
                                                }
                                                className={`video-remote server-${_janusConnector.name}`}
                                                id={`videoremote-${_janusConnector.name}-${_x}`}>
                                        <div style={{left: "0%", top: "0%", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%", color: "#ededed"}}><i style={{}}
                                                className={"fal fa-2x fa-video-slash"} />
                                        </div>
                                        <button className={"fullscreen-exit"}
                                                onClick={() => {document.exitFullscreen().then(() => {
                                            document.exitFullscreen();
                                        });}}>Выйти из полного экрана</button>
                                    </div>;
                                })
                                }
                            </>
                        })
                    }
                    <div style={{display: "flex", width: "100%", background: "var(--black)", height: "100%"}}></div>
                    <div className={"fullscreen-grid-switcher"}>
                        <i onClick={() => { me.changeGridIndex(-1) } } className={"fal fa-angle-left"}></i>
                        <span style={{textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "20px"}}>
                        {me.state.gridIndex + 1}
                    </span>
                        <i onClick={() => {me.changeGridIndex(1)}}  className={"fal fa-angle-right"}></i>
                        { me.props.myself.fullAccess &&
                          <VideoRecorder style={{
                              position: "absolute",
                              // bottom: "10px",
                              // zIndex: "2",
                              // display: "flex",
                              right: "10px"
                          }}/>
                        }
                    </div>


                </div>
                <div className={"grid-switcher"}>
                    <i onClick={() => { me.changeGridIndex(-1) } } className={"fal fa-angle-left"}></i>
                    <span style={{textAlign: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "20px"}}>
                        {me.state.gridIndex + 1}
                    </span>
                    <i onClick={() => {me.changeGridIndex(1)}}  className={"fal fa-angle-right"}></i>
                    { me.props.myself.fullAccess &&
                      <VideoRecorder style={{
                          position: "absolute",
                          // bottom: "10px",
                          // zIndex: "2",
                          // display: "flex",
                          right: "10px"
                      }}/>
                    }
                </div>
            </div>

            <div style={{background: "transparent"}}  className={`card-100 mt-5`}>
                {
                    me.props.janusConnectors.map((_janusConnector: JanusConnector) => {
                        return <VideoControls localFeedJanusConnector={_janusConnector}
                                conferenceId={me.props.conferenceId}
                                myself={me.props.myself}
                                api={me.props.api} />
                    })
                }
            </div>
        </div>
    }
}