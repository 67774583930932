import React from "react";
import {RailsField} from "../rails_field/RailsField";
import "./field_editor.scss";

interface FieldEditorProps {
  field: RailsField;
  showing: boolean;
  closeAction: () => void
  saveAction: (_newCode: string) => void
}

interface FieldEditorState {
  newFieldObject: any;
  resultingJSON: string;
}

export default class FieldEditor extends React.Component<FieldEditorProps, FieldEditorState> {

  constructor(_props: FieldEditorProps) {
    super(_props);
    this.state = {
      newFieldObject: this.copyObject(_props.field.props),
      resultingJSON: ""
    }

    this.changeField = this.changeField.bind(this);
    this.changeFieldBoolean = this.changeFieldBoolean.bind(this);
  }

  copyObject(_any: any) {
    let newObj = {} as any;
    let fields = this.getProperties(_any);
    for(let _field of fields) {
      if (_any[_field])
        newObj[_field] = JSON.stringify(_any[_field]);
      else
        newObj[_field] = "null";
    }
    return newObj;
  }

  getProperties(_any: any):string[] {
    let res = Object.keys(_any).map((_field: string) => {
      if (_any[_field] && typeof _any[_field] === 'function') return "";

      try {
        JSON.stringify(_any[_field]);
        return _field;
      } catch (e) {
        return "";
      }
    }).filter((_e: any) => {
      return _e.length > 0;
    });

    let mainProperties = [
      "css",
      "customContainerClassName",
      "customContainerStyle",
      "var_name",
      "title",
      "inputElementStyle",
      "customInputStyle",
      "reactions",
      "use",
      "boolean_variants"
    ];

    // alert(res);
    for(let _prop of mainProperties) {
      if (res.indexOf(_prop) === -1)
        res.push(_prop);
    }

    return res;
  }

  toJSONString(_any: any) {
    let res = "";
    let dataToJoin = [];
    for(let _field of Object.keys(_any)) {
      let fieldRow = `"${_field}":${_any[_field]}`;
      dataToJoin.push(fieldRow);
    }
    // console.log(dataToJoin);
    // console.log(dataToJoin.join(","));
    res = JSON.stringify(JSON.parse("{" + dataToJoin.join(",") + "}"), null, 2);
    return res;
  }


  changeField(_e: React.ChangeEvent<HTMLTextAreaElement>, _fieldName: string) {
    let me = this;
    let value = _e.target.value;
    let prevState = {...this.state};
    prevState.newFieldObject[_fieldName] = value;
    this.setState(prevState, () => {
      let pState = {...me.state};
      try {
        pState.resultingJSON = me.toJSONString(me.state.newFieldObject);
      } catch (e) {
        pState.resultingJSON = "Ошибка!";
      }
      me.setState(pState);
    });
  }

  changeFieldBoolean(_e: React.ChangeEvent<HTMLInputElement>, _fieldName: string) {
    // alert(_e.currentTarget.value);
    let me = this;
    let value = _e.target.checked;
    let prevState = {...this.state};
    prevState.newFieldObject[_fieldName] = JSON.stringify(value);
    this.setState(prevState, () => {
      let pState = {...me.state};
      try {
        pState.resultingJSON = me.toJSONString(me.state.newFieldObject);
      } catch (e) {
        pState.resultingJSON = "Ошибка!";
      }
      me.setState(pState);
    });
  }

  render() {
    let me = this;

    return <div style={me.props.showing ? {display: "flex"} : {display: "none"} } className={"field-editor"}>
      <ul>
        <li>
          <p style={{fontWeight: 900, marginBottom: "30px"}} onClick={me.props.closeAction}>Закрыть</p>
          <p style={{fontWeight: 900, marginBottom: "30px"}} onClick={(_e) => {
            me.props.saveAction(me.toJSONString(me.state.newFieldObject))
          }}>Сохранить</p>
          <br/>
        </li>
        {
          Object.keys(me.state.newFieldObject).map((_field: string) => {
            return <li style={{background: "#ededed", padding: "10px", marginBottom: "10px"}}>
              <span>{_field}</span>
              {(me.state.newFieldObject[_field] === "true" || me.state.newFieldObject[_field] === "false") &&
                <input type={"checkbox"}
                       checked={(me.state.newFieldObject)[_field]==="true"}
                       // value={(me.state.newFieldObject)[_field]}
                       onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {me.changeFieldBoolean(_e, _field)}}
                />
              }
              {!((me.state.newFieldObject[_field] === "true" || me.state.newFieldObject[_field] === "false")) &&
                  <textarea onChange={(_e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    me.changeField(_e, _field)
                  }}
                  rows={1}
                  value={(me.state.newFieldObject)[_field]}
                  />
              }
            </li>;
        })
        }
      </ul>

      <p style={{marginLeft: "10px", whiteSpace: "pre-wrap", width: "70%", fontSize: "12px" }}
         key={JSON.stringify(me.state.newFieldObject)}
      >{me.state.resultingJSON}</p>

    </div>;
  }
}