import React from "react";
import "./landing.scss";
import mainImage from "./image.svg";
import {Link} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";
import Participant from "../models/participants/Participant";

export default class Main extends React.Component<any, any> {
  render() {
    let me = this;
    return <div className={"landing"}>
      <div className={"lead"}>
        <div className={"flex flex-column"}>
          <h2>Система ВКС с подсистемой голосования на базе технологии распределенного реестра.</h2>
          <h3 style={{width: "70%"}}>Инструмент для организации и обеспечения работы коллегиальных органов управления хозяйствующих субъектов.</h3>
          <div className={"flex flex-row"} style={{marginTop: "30px"}}>
            <Link to={"/login"}><button style={{textDecoration: "none", cursor: "pointer", marginRight: "14px", color: "#fff", background: "var(--color-red)"}}>ВОЙТИ В СИСТЕМУ</button></Link>
            <button style={{marginRight: "14px", color: "#fff", background: "rgba(255, 255, 255, 0.1)"}}>ОСТАВИТЬ ЗАЯВКУ</button>
          </div>
        </div>
      </div>
      <img src={mainImage}/>
    </div>;
  }
}