import React from "react";

interface VideoRecorderProps {
    style?: any
}

interface VideoRecorderState {
    stream: MediaStream | null,
    recording: boolean,
    starting: boolean,
    recorder: null | MediaRecorder,
    uploading: boolean,
    downloadUrl: string | null
}

function blobToDataURL(blob: Blob, callback: (_e: string | ArrayBuffer | null) => void) {
    var a = new FileReader();
    a.onload = function(e) {callback(e.target && e.target.result);}
    a.readAsDataURL(blob);
}


export default class VideoRecorder extends React.Component<VideoRecorderProps, VideoRecorderState> {
    private chunks: any[];
    private currentChunkIndex: number = 0;
    private weAreClosing: boolean = false;
    private preview: React.RefObject<HTMLVideoElement>;
    private recording: React.RefObject<HTMLVideoElement>;
    private writeRecordInterval: any = null;

    blob?: Blob;
    downloadButton: React.RefObject<HTMLAnchorElement>;

    constructor(props: VideoRecorderProps) {
        super(props);
        this.state = {
            stream: null,
            recording: false,
            starting: false,
            recorder: null,
            uploading: false,
            downloadUrl: null //localStorage.getItem("good_bye")
        }
        this.chunks = [];
        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startStream = this.startStream.bind(this);
        this.onRecordStop = this.onRecordStop.bind(this);
        this.preview  = React.createRef<HTMLVideoElement>();
        this.recording  = React.createRef<HTMLVideoElement>();
        this.downloadButton = React.createRef<HTMLAnchorElement>();
    }

    startStream(stream:MediaStream) {
        let me = this;
        return new Promise((_resolve) => {
            let prevState = {...me.state};
            prevState.stream = stream;
            prevState.downloadUrl = null;
            prevState.recorder = new MediaRecorder(stream);

            prevState.recorder.onstop = me.onRecordStop;
            prevState.recorder.ondataavailable = function(e: { data: any; }) {
                me.chunks.push(e.data);
                // console.log(e.data);
                // console.log("Written basckup data");
                // let blob = new Blob(me.chunks, { type: "video/webm" });
                // let url = window.URL.createObjectURL(blob);
                // let tempLink = document.createElement('a');
                // tempLink.href = url;
                // tempLink.setAttribute('download', 'запись.webm');
                // tempLink.click();
            }
            me.setState(prevState, function() {
                if (!me.preview.current) return;
                if (!me.downloadButton.current) return;
                me.preview.current.srcObject = stream;
                _resolve(true);
            });

        });
    }

    componentDidMount() {
        let me = this;
        let blobData = localStorage.getItem("current_recording");
        if (blobData) {
            fetch(blobData)
              .then(res => res.blob())
              .then((_b: Blob) => {
                  let recordedURL = window.URL.createObjectURL(_b);
                  me.setState({
                      downloadUrl: recordedURL
                  });
                  // const a = document.createElement("a") as HTMLAnchorElement;
                  // a.href = recordedURL;
                  // a.download = "true";
                  //
                  // document.append(a);
                  // a.click();
              });
        }
    }

    startRecording() {
        let me = this;

        localStorage.removeItem("good_bye");
        localStorage.removeItem("current_recording");

        window.addEventListener('beforeunload', () => {
            //@ts-ignore
            me.state.recorder.stop();
            me.blob = new Blob(me.chunks, { type: "video/webm" });
            if (!me.blob) return;
            let recordedURL = window.URL.createObjectURL(me.blob);
            let a = document.createElement("a");
            a.href = recordedURL;
            a.target = "_blank";
            a.download = `conf${(new Date()).getTime()}.webm`
            a.click();
        });

        me.chunks = [];

        let audioDeviceId = localStorage.getItem("audioDeviceId");
        // alert(audioDeviceId);
        let constraints = {
            video: {
                displaySurface: "window"
            },
            preferCurrentTab: true,
            systemAudio: "include",
            audio: {
                deviceId: audioDeviceId ? audioDeviceId : "default",
                echoCancellation: true,
                noiseSuppression: true,
                sampleRate: 44100,
                systemAudio: "include"
            }
        } as MediaStreamConstraints;
        // alert(JSON.stringify(constraints));
        navigator.mediaDevices.getDisplayMedia(constraints).then((_desktopStream: MediaStream) => {
            let videoTracks = _desktopStream.getVideoTracks();

            navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: audioDeviceId ? audioDeviceId : "default",
                    echoCancellation: false,
                    noiseSuppression: true
                }
            }).then((audioStream) => {
                let audioTracks = audioStream.getAudioTracks();
                let _stream = new MediaStream([videoTracks[0], audioTracks[0]]);
                me.startStream(_stream).then((_e) => {
                    let prevState = {...me.state};
                    if (me.state.recorder && (me.state.recorder.state !== "recording")) {
                        prevState.recording = true;
                        me.setState(prevState, () => {
                            // me.state.recorder
                            if (me.state.recorder) {
                                me.state.recorder.start();
                                setTimeout(() => {
                                    me.writeRecordInterval = setInterval(() => {
                                        me.state.recorder?.requestData();
                                    }, 100);
                                }, 5000);
                            }
                        });
                    } else {
                        prevState.recording = true;
                        me.setState(prevState, () => {
                            me.chunks = [];
                            if (me.state.recorder) me.state.recorder.stop();
                        });
                    }
                });
            });
        });
    }

    stopRecording() {
        let me = this;
        let prevState = {...this.state};
        prevState.recording = false;
        me.setState(prevState, () => {
            if (me.state.recorder) {
                me.state.recorder.stop();

                let prevState = {...me.state};
                prevState.recorder = null;
                me.setState(prevState, () => {
                    setTimeout(() => {
                        clearInterval(me.writeRecordInterval);
                    }, 10000);
                });
            }
        })
    }

    onRecordStop() {
        let me = this;
        let recorded = this.recording.current;
        me.blob = new Blob(me.chunks, { type: "video/webm" });
        if (!me.blob) return;
        let recordedURL = window.URL.createObjectURL(me.blob);
        if (me.weAreClosing) {
            let a = document.createElement("a") as HTMLAnchorElement;
            a.href =  recordedURL;
            a.click();

            // blobToDataURL(me.blob, (_url) => {
            //     if (_url) localStorage.setItem("good_bye", _url.toString());
            // });
            // a.download = "true";
            // document.append(a);
            // window.URL.revokeObjectURL(recordedURL);
        }


        if (me.recording.current) {
            me.recording.current.src = recordedURL;
        }
        let prevState = {...me.state};
        prevState.downloadUrl = recordedURL;


        me.setState(prevState);

        // alert(recordedURL);
        // let formData = new FormData();
        // formData.append("video_message", me.blob);
        // if (me.props.context) {
        //     formData.append("context", me.props.context);
        // }
        // let prevState = {... this.state};
        // prevState.uploading = true;
        // prevState.recording = false;
        // me.setState(prevState);
        // let url = "";
        // if (me.props.order) {
        //     url = `/admin/user_orders/${me.props.order.id}/upload_video`;
        //
        // }
        // if (me.props.pre_scan) {
        //     url = `/admin/pre_scans/${me.props.pre_scan.id}/upload_video`;
        // }
        // fetch(url, {
        //     method: 'POST',
        //     body: formData
        // }).then(
        //     response => response.json() // if the response is a JSON object
        // ).then(success => {
        //     let prevState = {... me.state};
        //     prevState.uploading = false;
        //     me.setState(prevState);
        //     // alert(JSON.stringify(success));
        //     // me.props.triggerSendAudio();
        // }).catch(
        //     error => console.log(error) // Handle the error response object
        // );
    }

    render() {
        let me = this;
        return <div style={me.props.style}>
            <div style={{   display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center"}}>
                {/*<p onClick={() => {*/}
                {/*    if (me.state.recorder)*/}
                {/*        console.log(me.state.recorder.requestData());*/}
                {/*}*/}
                {/*}>123123</p>*/}
                    <a download target={"_blank"}
                       key={me.state.downloadUrl}
                       href={me.state.downloadUrl ? me.state.downloadUrl : "#"}
                       style={{
                           padding: 0,
                           margin: 0,
                           marginRight: "5px",
                           fontSize: "8px",
                           color: "#ededed",
                           opacity: me.state.downloadUrl ? 1 : 0,
                           pointerEvents: me.state.downloadUrl ? "all" : "none"
                       }}
                    >Скачать</a>

                <span style={{  display: "flex",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                background: me.state.recording ? "#f00" : "#ed9999"
                            }}
                            onClick={me.state.recording ? me.stopRecording : me.startRecording}
                ></span>
            </div>
            <div style={{
                display: "none",
                position: "absolute",
                background: "#fff",
                padding: "10px",
                top: "10px",
                left: "10px"
            }}>
                <div key={`${me.state.uploading}-${me.state.recording}`}>
                    {me.state.recorder &&
                    <p>{me.state.recording ? 'Идет запись' : ''}</p>
                    }
                    {
                        me.state.uploading &&
                        <h3 style={{color: "#f00"}}>Идет выгрузка</h3>
                    }
                </div>

                <div className="left">
                    <div id="startButton" className="button">
                        Запись
                    </div>
                    <h2>Предпросмотр</h2>
                    <video ref={me.preview }
                           id="preview"
                           width="320"
                           height="240"
                           controls={true}
                           autoPlay
                           muted></video>
                </div>

                <div className="right">
                    <h2>Записано</h2>
                    <video ref={me.recording } id="recording" width="160" height="120" controls></video>
                    <a style={{display: 'none'}} ref={me.downloadButton} id="downloadButton" className="button">
                        Download
                    </a>
                </div>
            </div>
        </div>
    }
}

