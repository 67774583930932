import React from "react";
import "./login.scss";
import {Link, Navigate, Route, Routes} from "react-router-dom";
import SignIn from "./sign_in/SignIn";
import {ApiEngine} from "api-engine";
import {UserContext} from "../../../contexts/UserContext";

interface LoginProps {
  api: ApiEngine;
}

export default class Login extends React.Component<LoginProps, any> {

  constructor(_props: LoginProps) {
    super(_props);
    this.state = {
      mode: "sign_in"
    }
  }

  render() {
    let me = this;
    return <div className={"login"}>
      <h1>Вход в систему</h1>
      <div className={"login-zone"}>
        <div className={"tab-zone"}>
          <Link onClick={() => {me.setState({mode: "sign_in"})} } className={`tab-link ${me.state.mode === "sign_in" ? 'active' : ''}`} to={"/login/sign_in"}>ВХОД</Link>
          <Link style={{pointerEvents: "none", opacity: 0.4}} onClick={() => {me.setState({mode: "sign_up"})} } className={`tab-link ${me.state.mode === "sign_up" ? 'active' : ''}`} to={"/login/sign_up"}>РЕГИСТРАЦИЯ</Link>
        </div>
        <UserContext.Consumer>
          { ({participant, setParticipant}) => (
            <>
              { participant &&
                <Navigate to={"/"}/>
              }

              { !participant &&
                  <Routes>
                      <Route
                          path={"/sign_in/*"}
                          element={<SignIn api={me.props.api}/>}
                      />

                      <Route
                          path={"/*"}
                          element={<SignIn api={me.props.api}/>}
                      />

                      <Route
                          path={"/sign_up/*"}
                          element={<>Вы регаетесь</>}
                      />
                  </Routes>
              }
            </>
            )
          }
        </UserContext.Consumer>
      </div>
    </div>;
  }
}
