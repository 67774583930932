import JanusStrategyInterface from "./JanusStrategyInterface";
import JanusConnector from "../JanusConnector";
import $ from "jquery";

export default class ReceivingFeedJanusBaseStrategy implements JanusStrategyInterface {
    private subStreams: any;
    private slots: any;
    private simulcastStarted: any;
    private mids: any;
    get feedStreams(): any {
        return this._feedStreams;
    }

    set feedStreams(value: any) {
        this._feedStreams = value;
    }

    sfu: any;
    JanusClass: any;
    myid: any;
    mypvtid: any;
    mystream: any;
    remoteTracks: any;
    janusConnector: JanusConnector | null;
    subscriptions: any;
    feeds: any;

    private _feedStreams: any;
    private creatingSubscription: boolean;

    constructor() {
        this.mids = {};
        this.subStreams = {};
        this.creatingSubscription = false;
        this.remoteTracks = {};
        this._feedStreams = {};
        this.subscriptions = {};
        this.slots = {};
        this.feeds = {};
        this.simulcastStarted = {};
        // @ts-ignore
        this.JanusClass = Janus;
        this.janusConnector = null;
    }

    subscribeTo(_sources: any[]) {
        let me = this;
        // alert(2);
        if(me.creatingSubscription) {
            // Still working on the handle, send this request later when it's ready
            setTimeout(function() {
                me.subscribeTo(_sources);
            }, 500);
            return;
        }

        if (me.sfu) {
            let added = null, removed = null;
            for(let s in _sources) {
                let streams = _sources[s];
                for(let i in streams) {
                    let stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if(stream.disabled) {
                        // Unsubscribe
                        if(!removed)
                            removed = [];
                        removed.push({
                            feed: stream.id,	// This is mandatory
                            mid: stream.mid		// This is optional (all streams, if missing)
                        });
                        delete this.subscriptions[stream.id][stream.mid];
                        continue;
                    }
                    if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
                        console.log("Already subscribed to stream, skipping:", stream);
                        continue;
                    }
                    // Find an empty slot in the UI for each new source
                    if(!this.feedStreams[stream.id].slot) {
                        let slot;
                        for(let i=1;i<20;i++) {
                            if(!this.feeds[i]) {
                                slot = i;
                                this.feeds[slot] = stream.id;
                                this.feedStreams[stream.id].slot = slot;
                                this.feedStreams[stream.id].remoteVideos = 0;
                                this.feedStreams[stream.id].remoteVideos = 0;
                                this.feedStreams[stream.id].display = stream.display;
                                // $('#remote' + slot).removeClass('hide').html(escapeXmlTags(stream.display)).show();
                                break;
                            }
                        }
                    }
                    // Subscribe
                    if(!added)
                        added = [];
                    added.push({
                        feed: stream.id,	// This is mandatory
                        mid: stream.mid		// This is optional (all streams, if missing)
                    });
                    if(!this.subscriptions[stream.id])
                        this.subscriptions[stream.id] = {};
                    this.subscriptions[stream.id][stream.mid] = true;
                }
            }
            if((!added || added.length === 0) && (!removed || removed.length === 0)) {
                // Nothing to do
                return;
            }
            let update = { request: 'update' } as any;
            if(added)
                update.subscribe = added;
            if(removed)
                update.unsubscribe = removed;
            this.sfu.send({ message: update });
            return;
        }

        me.creatingSubscription = true;
        if (me.janusConnector === null) {
            setTimeout(function() {
                me.subscribeTo(_sources);
            }, 500);
            return;
        }

        me.janusConnector.janusInstance.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: me.janusConnector.opaqueId,
            success: function(_pluginHandle:any) {
                if (!me.janusConnector) return;
                me.success(_pluginHandle, me.janusConnector.janusInstance, _sources)
            },
            consentDialog: function(_on:boolean) {
                if (!me.janusConnector) return;
                me.consentDialog(_on, me.janusConnector.janusInstance);
            },
            iceState: function(_state: any) {
                if (!me.janusConnector) return;
                me.iceState(_state, me.janusConnector.janusInstance);
            },
            mediaState: function(_medium: any, _on: any, _mid: any, _janusInstance: any) {
                if (!me.janusConnector) return;
                me.mediaState(_medium, _on, _mid, me.janusConnector.janusInstance);
            },
            webrtcState: function(_on: any) {
                if (!me.janusConnector) return;
                me.webrtcState(_on, me.janusConnector.janusInstance);
            },
            slowLink: function(_uplink:any, _lost:any, _mid:any) {
                if (!me.janusConnector) return;
                me.slowLink(_uplink, _lost, _mid, me.janusConnector.janusInstance);
            },
            onmessage: function(_msg: any, _jsep: any) {
                if (!me.janusConnector) return;
                me.onmessage(_msg, _jsep, me.janusConnector.janusInstance);
            },
            onlocaltrack: function(_track:any, _on:any) {
                if (!me.janusConnector) return;
                me.onlocaltrack(_track, _on, me.janusConnector.janusInstance);
            },
            onremotetrack: function(_track:any, _mid:any, _on:any) {
                if (!me.janusConnector) return;
                me.onremotetrack(_track, _mid, _on, me.janusConnector.janusInstance);
            },
            oncleanup: function() {
                if (!me.janusConnector) return;
                me.oncleanup(me.janusConnector.janusInstance);
            }


        });
    }

    success(_pluginHandle: any, _janusInstance: any, _sources: any[]): void {
        // alert(3);
        this.sfu = _pluginHandle;
        this.remoteTracks = {};
        this.JanusClass.log("Plugin attached! (" + this.sfu.getPlugin() + ", id=" + this.sfu.getId() + ")");
        this.JanusClass.log("  -- This is a multistream subscriber");
        // // Prepare the streams to subscribe to, as an array: we have the list of
        // // streams the feed is publishing, so we can choose what to pick or skip
        let subscription = [];
        // alert(`Sources: ${_sources.length}`);
        for(let s in _sources) {
            let streams = _sources[s];
            // alert(`Source ${s}, streams: ${streams.length}`);
            for(let i in streams) {
                let stream = streams[i];
        //         // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
        //         this.JanusClass.log("Subscribed to " + stream.id + "/" + stream.mid + "?", subscriptions);
                if(this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
                    this.JanusClass.log("Already subscribed to stream, skipping:", stream);
                    continue;
                }
        //         // Find an empty slot in the UI for each new source
                if(!this.feedStreams[stream.id].slot) {
                    let slot;
                    for(let i=1;i<20;i++) {
                        if(!this.feeds[i]) {
                            slot = i;
                            this.feeds[slot] = stream.id;
                            this.feedStreams[stream.id].slot = slot;
                            this.feedStreams[stream.id].remoteVideos = 0;
                            $('#remote' + slot).removeClass('hide').html(stream.display).show();
                            break;
                        }
                    }
                }
                let feedDescr = {
                    feed: stream.id,	// This is mandatory
                    mid: stream.mid		// This is optional (all streams, if missing)
                };
                // alert(JSON.stringify(feedDescr));
                subscription.push(feedDescr);
                if(!this.subscriptions[stream.id])
                    this.subscriptions[stream.id] = {};
                this.subscriptions[stream.id][stream.mid] = true;
                // alert("Added subscriptions");
            }
        }
        // // We wait for the plugin to send us an offer
        if (!this.janusConnector) return;
        // alert("Subscibing");
        let subscribe = {
            request: "join",
            room: this.janusConnector.room,
            ptype: "subscriber",
            streams: subscription,
            use_msid: true,
            private_id: this.janusConnector.mypvtid,
        };
        // alert(JSON.stringify(subscribe));
        this.sfu.send({ message: subscribe });
    }

    consentDialog(_on: boolean, _janusInstance: any): void {
    }

    error(_error: any, _janusInstance: any): void {
    }

    iceState(_state: any, _janusInstance: any): void {
    }

    mediaState(_medium: any, _on: any, _mid: any, _janusInstance: any): void {
    }

    oncleanup(_janusInstance: any): void {
    }

    onlocaltrack(track: any, on: any, _janusInstance: any): void {
    }

    onmessage(msg: any, jsep: any, _janusInstance: any): void {
        let me = this;
        console.log(" ::: Got a message as subscriber :::" + msg);
        let event = msg["videoroom"];
        console.log(event);
        if(msg["error"]) {
            console.error(`Error: ${msg["error"]}`);
        } else if(event) {
            if(event === "attached") {
                // Now we have a working subscription, next requests will update this one
                this.creatingSubscription = false;
                console.log("Successfully attached to feed in room " + msg["room"]);
            } else if(event === "event") {
                // Check if we got an event on a simulcast-related event from this publisher
                let mid = msg["mid"];
                let substream = msg["substream"];
                let temporal = msg["temporal"];

                if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                    // Check which this feed this refers to
                    let sub = (this.subStreams)[mid];
                    let feed = this.feedStreams[sub.feed_id];
                    let slot = this.slots[mid];
                    if(!this.simulcastStarted[slot]) {
                        this.simulcastStarted[slot] = true;
                        // Add some new buttons
                        // addSimulcastButtons(slot, true);
                    }
                    // We just received notice that there's been a switch, update the buttons
                    // updateSimulcastButtons(slot, substream, temporal);
                }
            } else {
                // What has just happened?
            }
        }
        if(msg["streams"]) {
            // Update map of subscriptions by mid
            for(let i in msg["streams"]) {
                let mid = msg["streams"][i]["mid"];
                this.subStreams[mid] = msg["streams"][i];
                let feed = this.feedStreams[msg["streams"][i]["feed_id"]];
                if(feed && feed.slot) {
                    this.slots[mid] = feed.slot;
                    this.mids[feed.slot] = mid;
                }
            }
        }
        if(jsep) {
            // alert("Receiving: Handling SDP as well..." + jsep);
            // Answer and attach
            this.sfu.createAnswer(
                {
                    jsep: jsep,
                    // We only specify data channels here, as this way in
                    // case they were offered we'll enable them. Since we
                    // don't mention audio or video tracks, we autoaccept them
                    // as recvonly (since we won't capture anything ourselves)
                    tracks: [
                        { type: 'data' }
                    ],
                    success: function(jsep:any) {
                        if (!me.janusConnector) return;
                        // alert("Got SDP!");
                        // alert(jsep);
                        let body = { request: "start", room: me.janusConnector.room };
                        me.sfu.send({ message: body, jsep: jsep });
                    },
                    error: function(error: any) {
                        alert("WebRTC error:" + error);
                    }
                });
        }
    }

    onremotetrack(track: any, mid: any, on: any, _janusInstance: any): void {
        let me = this;
        console.log(track);
        console.log("Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":" + track);
        // Which publisher are we getting on this mid?
        let sub = me.subStreams[mid];
        let feed = me.feedStreams[sub.feed_id];
        // alert(feed.display);
        // alert(" >> This track is coming from feed " + sub.feed_id + ":" + feed);
        let slot = this.slots[mid];
        if(feed && !slot) {
            slot = feed.slot;
            this.slots[mid] = feed.slot;
            this.mids[feed.slot] = mid;
        }
        let idName = `remotevideo-${me.janusConnector?.name}` + slot + '-' + mid;
        let containerIdName = `videoremote-${me.janusConnector?.name}-${slot}`;
        // alert(" >> mid " + mid + " is in slot " + slot);
        if(!on) {
            // Track removed, get rid of the stream and the rendering
            $(`#${idName}`).remove();
            if(track.kind === "video" && feed) {
                feed.remoteVideos--;
                if(feed.remoteVideos === 0) {
                    // No video, at least for now: show a placeholder
                    if($('#videoremote'+  + slot + ' .no-video-container').length === 0) {
                        $('#videoremote'+  + slot).append(
                            '<div class="no-video-container">' +
                            '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                            '<span class="no-video-text">No remote video available</span>' +
                            '</div>');
                    }
                }
            }
            delete this.remoteTracks[mid];
            delete this.slots[mid];
            delete this.mids[slot];
            return;
        }
        // If we're here, a new track was added

        if($(`#${idName}`).length > 0)
            return;
        if(track.kind === "audio") {
            // New audio track: create a stream out of it, and use a hidden <audio> element
            let stream = new MediaStream([track]);
            this.remoteTracks[mid] = stream;
            // alert("Created remote audio stream:" + stream);
            // $('#videoremote'+  + slot).append('<audio class="hide record-audio-stream-from-here" id="remotevideo' + slot + '-' + mid + '" autoplay playsinline/>');
            let audioIdName = `remoteaudio-${me.janusConnector?.name}-${slot}-${mid}`;
            // alert($(`#${idName}`));
            let audioTagContent = `<audio class="hide record-audio-stream-from-here" id="${audioIdName}" autoplay playsinline />`;
            // alert(`${idName} appending ${audioTagContent}`);
            $(`#${containerIdName}`).append(audioTagContent);
            me.JanusClass.attachMediaStream($(`#${audioIdName}`).get(0), stream);
            if(feed.remoteVideos === 0) {
                // No video, at least for now: show a placeholder
                if($(`#${containerIdName}` + ' .no-video-container').length === 0) {
                    $('#videoremote'+  + slot).append(
                        '<div class="no-video-container">' +
                        '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                        '<span class="no-video-text">No remote video available</span>' +
                        '</div>');
                }
            }
        } else {
            // New video track: create a stream out of it
            feed.remoteVideos++;
            let dataName = this.feedStreams[sub.feed_id].display.split("#!#!#");
            let shownName = dataName[0];
            let peerId = dataName[1];
            let role = dataName[2];
            let userId = dataName[3];
            // alert(userId);
            $(`#${containerIdName}` + ' .no-video-container').remove();
            let stream = new MediaStream([track]);
            this.remoteTracks[mid] = stream;
            // alert("Created remote video stream:" + stream);
            // alert(feed.display);
            let isMainCharacter = false;
            // alert(role);
            if (role) {
                // alert(me.janusConnector?.myself);
                isMainCharacter = (role === "Выступающий");
            }

            $(`#${containerIdName}`).append(`<video style="opacity: 1" class="${isMainCharacter ? 'is-main' : ''} rounded centered video-for-${peerId} video-for-user-${userId}" id="` + idName + '" width=100% autoplay playsinline/>');
            // $('#videoremote'+  + slot).append(`<i class="fas fa-video fa-3x" style="color: #858585; position: absolute; top: 0%; left: 0%; margin: auto;"></i>`)
            // $(`#${containerIdName}`).append(`<p>${shownName}${me.janusConnector ? me.janusConnector.name : '--'}</p>`)
            $(`#${containerIdName}`).append(`<p data="server-${me.janusConnector ? me.janusConnector.name : '--'}">${shownName}</p>`)
            $(`#${containerIdName}`).css({display: "flex"});
            const event = new CustomEvent<any>('NewUser', {detail: this.feedStreams[sub.feed_id].display});
            document.dispatchEvent(event);
            // $('#videoremote'+  + slot).append(
            //     '<span class="label label-primary hide" id="curres'+slot+'" style="position: absolute; bottom: 0px; left: 0px; margin: 15px;"></span>' +
            //     '<span class="label label-info hide" id="curbitrate'+slot+'" style="position: absolute; bottom: 0px; right: 0px; margin: 15px;"></span>');
            me.JanusClass.attachMediaStream($(`#${idName}`).get(0), stream);
            // Note: we'll need this for additional videos too
            // if(!bitrateTimer[slot]) {
            //     $('#curbitrate' + slot).removeClass('hide').show();
            //     bitrateTimer[slot] = setInterval(function() {
            //         if(!$("#videoremote" + slot + ' video').get(0))
            //             return;
            //         // Display updated bitrate, if supported
            //         let bitrate = remoteFeed.getBitrate(mid);
            //         $('#curbitrate' + slot).text(bitrate);
            //         // Check if the resolution changed too
            //         let width = $("#videoremote" + slot + ' video').get(0).videoWidth;
            //         let height = $("#videoremote" + slot + ' video').get(0).videoHeight;
            //         if(width > 0 && height > 0)
            //             $('#curres' + slot).removeClass('hide').text(width+'x'+height).show();
            //     }, 1000);
            // }
        }
    }


    slowLink(uplink: any, lost: any, mid: any, _janusInstance: any): void {
    }


    webrtcState(_on: any, _janusInstance: any): void {
    }

    unsubscribeFrom(_id: any) {
        let me = this;
        // Unsubscribe
        let feed = this.feedStreams[_id];
        if(!feed) return;
        console.log("Feed " + _id + " (" + feed.display + ") has left the room, detaching");
        const event = new CustomEvent<any>('DeleteUser', {detail: feed.display});
        document.dispatchEvent(event);
        // let idName = `remotevideo-${me.janusConnector?.name}` + slot + '-' + mid;
        let containerIdName = `videoremote-${me.janusConnector?.name}-${feed.slot}`;
        $(`#${containerIdName}`).empty();
        $(`#${containerIdName}`).css({display: "none"});

        // $('#videoremote' + feed.slot).empty();
        // $('#videoremote' + feed.slot).css({display: "none"});

        delete this.simulcastStarted[feed.slot];
        delete this.feeds[feed.slot];
        this.feeds.slot = 0;
        delete this.feedStreams[_id];
        // Send an unsubscribe request
        let unsubscribe = {
            request: "unsubscribe",
            streams: [{ feed: _id }]
        };
        if(this.sfu != null)
            this.sfu.send({ message: unsubscribe });
        delete this.subscriptions[_id];
    }

}