import React from "react";
import SummaryCircularResultChart from "./summary_circular_diagram/SummaryCircularResultChart";
import SummaryBarResultChart from "./summary_circular_diagram/SummaryBarResultChart";
import NamesChart from "./names_chart/NamesChart";
import NameGetter from "./names_chart/NameGetter";

interface ChartUseCasesProps {
  possibleVariants?: string[];
  votingId: string | null;
  tableTrMode?: boolean;
  title?: string;
  number?: number;
}

interface ChartUseCasesStats {
  votings: any[];
  showingNames: any;
  maximums: any;
}

export default class ChartsUseCases extends React.Component<ChartUseCasesProps, ChartUseCasesStats> {

  constructor(_props: ChartUseCasesProps) {
    super(_props);

    this.state = {
      votings: [],
      showingNames: {},
      maximums: {}
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let me = this;
    // let url = `https://event.er-etg.tech/conferences/1/results?voting_id=${me.props.votingId}`;
    let url = `https://voteer.ru/okrugs/get_results?okrug_code=${me.props.votingId}`;
    fetch(url).then((e) => e.json()).then((e) => {
      let prevState = {...me.state};
      prevState.votings = e;
      me.setState(prevState, () => {
        setTimeout(() => {
          me.getData();
        }, 15000);
      });
    });
  }

  render() {
    let me = this;
    if (me.props.tableTrMode) {
      return <>
        { me.state.votings.map((_v, _vIndex) => {
          return <><tr style={{
            background: me.state.showingNames[`${_vIndex}`] ? "#ededed" : undefined
          }}>
              {me.props.number && <td>{me.props.number}</td>}
              <td onClick={() => {
                let showingNames = {...me.state.showingNames};
                showingNames[`${_vIndex}`] = !showingNames[`${_vIndex}`];
                me.setState({showingNames: showingNames});
              }} style={{
                cursor: "pointer",
                fontWeight: me.state.showingNames[`${_vIndex}`] ? 900 : 600
              }}>{me.props.title}</td>
              { me.props.possibleVariants && me.props.possibleVariants.map((_var: string, _varIndex: number) => {
                let resultData = _v.data.filter((_resultData: any, _resultIndex: number) => {
                  return _resultData.label === _var;
                })[0];
                if (!resultData) return <td align={"center"}>0</td>;
                return <td align={"center"}>{resultData.percentage}</td>
              })
              }
            </tr>
            { me.state.showingNames[`${_vIndex}`] &&
              _v.variants.map((_var: any, _varIndex: number) => {
                  let votes = _v.votes.filter((_v: any) => {
                    // console.log(_v.content);
                    let toSearch = `"candidateId":${_var.id},`;
                    let index = _v.content.indexOf(toSearch);
                    // console.log(toSearch);
                    // console.log(index);
                    return index > -1;
                  });
                  return <tr key={`v-${_vIndex}-${_varIndex}`}>
                    {me.props.number && <td>{me.props.number}.{_varIndex + 1}</td>}
                    <td>{_var.content}</td>
                    <td colSpan={100}>
                      {/*<p>{votes.length}</p>*/}
                      {
                        votes.map((_shownVote: any) => {
                          return <><span style={{whiteSpace: "nowrap"}}><NameGetter id={_shownVote.user_id}/></span><br/></>;
                        })
                      }
                      {/*<p>{JSON.stringify(_var)}</p>*/}
                      {/*<p>{JSON.stringify(votes)}</p>*/}
                      {/*<NamesChart votes={_v.votes}*/}
                      {/*            variants={_v.variants}*/}
                      {/*            participants={_v.participants}*/}
                      {/*            index={_vIndex + 1}*/}
                      {/*            is_rating={_v.is_rating}*/}

                      {/*/>*/}
                    </td>
                  </tr>
                })
              }
          </>;
        })
        }
      </>
    }

    return <div style={{color: "#454545"}} key={`${JSON.stringify(me.state.votings)}`}>
      { me.state.votings.map((_v, _vIndex) => {
        return <div key={`v-${_vIndex}`} className={"mt-1"} style={{background: "var(--white)", borderRadius: "10px", padding: "7px"}}>
          {/*<p>{JSON.stringify(_v)}</p>*/}
          { !_v.is_rating &&
              <SummaryCircularResultChart
                  amount_of_activated_key_pairs={_v.votes_number}
                  theme={_v.title}
                  metricName={"Голосов"}
                  label={"Результаты голосования по вопросу повестки"}
                  data={_v.data} />
          }
          { !!_v.is_rating &&
              <SummaryBarResultChart
                  amount_of_activated_key_pairs={_v.votes_number}
                  theme={_v.title}
                  metricName={"Голосов"}
                  label={"Результаты голосования по вопросу повестки"}
                  data={_v.data} />
          }

          { !!_v.show_names &&
              <div style={{
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "600px"
              }}>
                {/*{JSON.stringify(_v)}*/}
                <NamesChart votes={_v.votes}
                            variants={_v.variants}
                            participants={_v.participants}
                            index={_vIndex + 1}
                            is_rating={_v.is_rating}

                />
              </div>
          }

          <br/>
        </div>;
      })
      }
      {/*<Hider isOpen={false} showTitle={"Открыть"} closeTitle={"Закрыть"} heading={<h4 style={{color: "#fff"}}>Результаты нерейтингового голосования</h4>}>*/}
      {/*  <Header/>*/}
      {/*  <div className={"card mt-1"}>*/}
      {/*    <SummaryCircularResultChart theme={"Об избраннии Секретаря Сахалинского регионального Всероссийской политической партии «ЕДИНАЯ РОССИЯ»"} metricName={"Голосов"} label={"Результаты голосования по вопросу повестки"} data={[{label: "Иванов Г.В.", percentage: 25}, {label: "Петров Б.Б.", percentage: 75}]} />*/}
      {/*  </div>*/}
      {/*</Hider>*/}
      {/*<br/>*/}
      {/*<hr/>*/}
      {/*<br/>*/}
      {/*<Hider isOpen={false} showTitle={"Открыть"} closeTitle={"Закрыть"} heading={<h4 style={{color: "#fff"}}>Результаты рейтингового голосования</h4>}>*/}
      {/*  <Header/>*/}
      {/*  <div className={"card mt-1"}>*/}
      {/*    <SummaryBarResultChart theme={"Об избраннии Секретаря Сахалинского регионального Всероссийской политической партии «ЕДИНАЯ РОССИЯ»"} metricName={"Голосов"} label={"Результаты голосования по вопросу повестки"} data={[{label: "Иванов Г.В.", percentage: 25}, {label: "Петров Б.Б.", percentage: 75}, {label: "Сидоров Б.Б.", percentage: 175}]} />*/}
      {/*  </div>*/}
      {/*</Hider>*/}
    </div>
  }
}