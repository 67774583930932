import React from 'react';
import './App.css';
import Header from "./components/layout/header/Header";
import Participant from "./components/models/participants/Participant";
import {Navigate, Route, RouterProps, Routes} from "react-router-dom";
import Login from "./components/auth/Login/Login";
import Main from "./components/landing/Main";
import {ApiEngine, JWTContainer} from "api-engine";
import { UserContext } from './contexts/UserContext';
import Cabinet from "./components/user_panel/cabinet/Cabinet";
import Conference from "./components/conference/Conference";

interface AppProps {
  config: any;
  myself: Participant | null;
  api: ApiEngine;
  logo: JSX.Element | null;
}

interface AppState {
  myself: any;
}

export default class App extends React.Component<AppProps, AppState> {

  constructor(_props: AppProps) {
    super(_props);
    this.state = {
      myself: this.props.myself
    }

    this.setParticipant = this.setParticipant.bind(this);
  }

  setParticipant(_participant: Participant | null) {
    let me = this;

    me.setState({myself: _participant}, () => {
      if (_participant && _participant.jwt) {
        localStorage.setItem("jwt", _participant.jwt);
        localStorage.setItem("csrf", "disabled");
        me.props.api.sessionContainer.currentEntity = _participant;
        me.props.api.sessionContainer.currentUser = _participant;
        let restoredJwt = JWTContainer.tryToRestoreJWT();
        if (restoredJwt)
          me.props.api.sessionContainer.jwtContainer = restoredJwt;
      }
    });
  }

  render() {
    let me = this;
    return (
      <div style={{minHeight: "100vh"}}>
        <UserContext.Provider value={{participant: me.state.myself, setParticipant: (_participant: Participant) => {
            me.setParticipant(_participant);
          }}}>
          <Header/>
          <Routes>
            <Route
              path={"/"}
              element={<>
                { !me.state.myself &&
                    <Navigate to="/main" />
                }
                { me.state.myself &&
                    <Navigate to="/cabinet" />
                }
              </>}
            />

            <Route
              path={"/login/*"}
              element={<Login api={me.props.api} />}
            />

            <Route
              path={"/main/*"}
              element={<Main />}
            />

            <Route
              path={"/cabinet/*"}
              element={ me.state.myself ?
                <Cabinet api={me.props.api} />
                :
                <Navigate to={"/"} />
            } />

            <Route
              path={"/conferences"}
              element={
                <Navigate to={"/cabinet"} />
            } />
            <Route
              path={`/conferences/:id/*`}
              element={ me.state.myself ? <Conference api={me.props.api}
               config={me.props.config}
               logo={me.props.logo}
               myself={me.state.myself}
               serverURL={me.props.config.serverUrl}
              /> : <Navigate to={"/"} />}
            />
          </Routes>
        </UserContext.Provider>
      </div>
    );
  }
}