import React from "react";
import "../../../assets/shared/positioning.css";
import "./device-chooser-styles.scss";
import JanusConnector from "../../../../gateways/janus/JanusConnector";
import {HoverableItem, POSITIONING_TYPE} from "hoverable_item";
import Participant from "../../../models/participants/Participant";
import Api from "../../../../gateways/api/Api";
import VideoRecorder from "../video-recorder/VideoRecorder";
import NullWebsocketConnector from "../../../../gateways/temp_api/gateway/websockets/NullWebsocketConnector";
import {ApiEngine} from "api-engine";

interface DeviceChooserProps {
    janusConnector: JanusConnector,
    conferenceId: number;
    myself: Participant;
    api: ApiEngine;
    publishing: boolean;
}

interface DeviceChooserState {
    audioDevices: any[];
    videoDevices: any[];
    totalDevices: any[];
    videoChooserHidden: boolean;
    audioChooserHidden: boolean;
    videoDeviceId: any;
    audioDeviceId: any;
    audioMuted: boolean;
    videoMuted: boolean;
    audioDeviceLabel: string;
    videoDeviceLabel: string;
    askingForAWord: boolean;
    showingParticipantsGrid: boolean;
}

export default class DeviceChooser extends React.Component<DeviceChooserProps, DeviceChooserState> {
  private JanusClass: any;
  private audioSelectElementRef: React.RefObject<HTMLSelectElement>;
  private videoSelectElementRef: React.RefObject<HTMLSelectElement>;
  private wasInitialized: boolean = false;

    constructor(_props: DeviceChooserProps) {
      super(_props);
      this.state = {
          audioDevices: [],
          videoDevices: [],
          totalDevices: [],
          videoChooserHidden: true,
          audioChooserHidden: true,
          videoDeviceId: null,
          audioDeviceId: null,
          audioMuted: true,
          videoMuted: true,
          audioDeviceLabel: "По умолчанию",
          videoDeviceLabel: "По умолчанию",
          askingForAWord: false,
          showingParticipantsGrid: true
      };

      // this.initDevices = this.initDevices.bind(this);
      this.triggerChooser = this.triggerChooser.bind(this);
      this.initDevicesFromJanusConnector = this.initDevicesFromJanusConnector.bind(this);
      this.muteAudio = this.muteAudio.bind(this);
      this.muteVideo = this.muteVideo.bind(this);
      this.askWord = this.askWord.bind(this);
      this.muteDevicesAtStart = this.muteDevicesAtStart.bind(this);
      this.openMain = this.openMain.bind(this);
      this.openTribune = this.openTribune.bind(this);
      // this.restartCapture = this.restartCapture.bind(this);

      this.audioSelectElementRef = React.createRef<HTMLSelectElement>();
      this.videoSelectElementRef = React.createRef<HTMLSelectElement>();
    }

    askWord() {
        let me = this;
        if (me.props.api.websocketConnector instanceof NullWebsocketConnector) {
          let url = `/conferences/${me.props.conferenceId}/raise_hand?user_id=${me.props.myself.id}&peer_id=${me.props.myself.peer_id}`;

          me.props.api.asyncFetch(`/${url}`, {}).then((_e: any) => {
              let prevState = {...me.state};
              prevState.askingForAWord = !!_e.call_me;
              me.setState(prevState);
          });
        } else {
          let prevState = {...me.state};
          prevState.askingForAWord = !prevState.askingForAWord;
          me.setState(prevState, () => {

            me.props.api.websocketConnector.sendMessage(JSON.stringify({
              title: "trigger",
              field: "call_me",
              value: prevState.askingForAWord
            }));
          });
        }

    }

    muteAudio() {
        let me = this;
        return new Promise((_resolve) => {
          let prevState = {...me.state};
          prevState.audioMuted = !prevState.audioMuted;
          me.setState(prevState, () => {
              if (prevState.audioMuted) {
                // while(!me.props.janusConnector.publishingFeedJanusStrategy.sfu) continue;
                me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteAudio();
              } else
                  me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteAudio();
              if (!(me.props.api.websocketConnector instanceof NullWebsocketConnector)) {
                me.props.api.websocketConnector.sendMessage(JSON.stringify({
                  title: "trigger",
                  field: "is_muted",
                  value: prevState.audioMuted
                }));
              }
              _resolve(prevState.audioMuted);
          });
        });
    }

    muteVideo(_value: boolean | null = null) {
        let me = this;
        return new Promise((_resolve) => {
          let prevState = {...me.state};
          if (_value === null)
            prevState.videoMuted = !prevState.videoMuted;
          else
            prevState.videoMuted = _value;

          me.setState(prevState, () => {
              if (prevState.videoMuted) {
                me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteVideo();
              } else
                  me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteVideo();
              if (!(me.props.api.websocketConnector instanceof NullWebsocketConnector)) {
                me.props.api.websocketConnector.sendMessage(JSON.stringify({
                  title: "trigger",
                  field: "block_video",
                  value: prevState.videoMuted
                }));
              }
              _resolve(prevState.videoMuted);
          });
        });
    }

    triggerChooser(_chooserName:string) {
        let prevState = {...this.state};
        if ("audio" === _chooserName)
            prevState.audioChooserHidden = !prevState.audioChooserHidden;
        if ("video" === _chooserName)
            prevState.videoChooserHidden = !prevState.videoChooserHidden;

        this.setState(prevState);
    }


  componentDidMount() {
        if (this.wasInitialized) return;
        this.wasInitialized = true;
        let me = this;
        // @ts-ignore
        this.JanusClass = Janus;
        this.initDevicesFromJanusConnector();
        document.addEventListener("Control", (_event: any) => {
          console.log(_event);
          // if (!me.props.publishing) return;
          if (me.props.api.sessionContainer.currentEntity.peer_id === _event.detail.peer_id) {
            if (_event.detail.title === "change_video") {
              if (_event.detail.value) {
                me.setState({videoMuted: true}, () => {
                  me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteVideo();
                });
              } else {
                me.setState({videoMuted: false}, () => {
                  me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteVideo();
                });
              }
            }

            if (_event.detail.title === "change_audio") {
              console.log("change_audio command received");
                me.setState({audioMuted: true}, () => {
                  me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteAudio();
                });
            }

          }
        });
    }

    muteDevicesAtStart() {
      let me = this;
      if (me.props.janusConnector.publishingFeedJanusStrategy.sfu) {
        me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteVideo();
        me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteAudio();
      } else {
        setTimeout(me.muteDevicesAtStart, 2000);
      }
    }

    initDevicesFromJanusConnector() {
        let me = this;
        let prevState = {... me.state};
        prevState.audioDevices = me.props.janusConnector.audioDevices;
        prevState.videoDevices = me.props.janusConnector.videoDevices;
        me.setState(prevState, ()=> {
          me.muteDevicesAtStart();
          // me.muteAudio() .then((e) => {
          //   me.muteVideo();
          // })
        });
    }

    openMain() {
      let prevState = {...this.state};
      prevState.showingParticipantsGrid = false;
      this.setState(prevState, () => {
        let event = new CustomEvent("VideoGrid", {
          detail: {
            title: "trigger-main",
            value: true
          }
        });
        document.dispatchEvent(event);
      });
      // alert(1);
    }

    openTribune() {
      let prevState = {...this.state};
      prevState.showingParticipantsGrid = true;
      this.setState(prevState, () => {
        let event = new CustomEvent("VideoGrid", {
          detail: {
            title: "trigger-main",
            value: false
          }
        });
        document.dispatchEvent(event);
      });
    }

    // initDevices(_devices: any[]) {
    //     let me = this;
    //     if (_devices.length === 0) {
    //         setTimeout(() => {
    //             me.JanusClass.listDevices(me.initDevices);
    //         }, 2000);
    //         return;
    //     }
    //     // alert(_devices.length);
    //     console.log(_devices.length);
    //     // $('#devices').removeClass('hide');
    //     // $('#devices').parent().removeClass('hide');
    //     // $('#choose-device').click(me.restartCapture);
    //     // let audio = $('#audio-device').val();
    //     // let video = $('#video-device').val();
    //     // $('#audio-device, #video-device').find('option').remove();
    //     //
    //     let audioDevices = [] as any[];
    //     let videoDevices = [] as any[];
    //
    //     _devices.forEach(function(device:any) {
    //     //
    //     //     var label = device.label;
    //     //     if(!label || label === "")
    //     //         label = device.deviceId;
    //     //     var option = $('<option value="' + device.deviceId + '">' + label + '</option>');
    //         if(device.kind === 'audioinput') {
    //             audioDevices.push(device);
    //         }
    //         if(device.kind === 'videoinput') {
    //             videoDevices.push(device);
    //         }
    //     });
    //
    //     let prevState = {... me.state};
    //     prevState.audioDevices = audioDevices;
    //     prevState.videoDevices = videoDevices;
    //     me.setState(prevState, () => {
    //
    //     });
    // }
    //

    setAudio(_ev: any) {
        let me = this;
        return new Promise((_resolve, _reject) => {
            if (!me.props.janusConnector.publishingFeedJanusStrategy.sfu) {
                _reject(false);
                return
            }
            let prevState = {...me.state};
            // if (_ev.target) {
                prevState.audioChooserHidden = true;
                prevState.audioDeviceId = _ev.target.value;
                localStorage.setItem("audioDeviceId", _ev.target.value);
                prevState.audioDeviceLabel = me.state.audioDevices.filter((_x: any) => {
                    return _x.deviceId === _ev.target.value
                })[0].label;
                // alert(prevState.audioDeviceLabel);
            // } else {
            //     prevState.audioDeviceId = _ev;
            // }
            me.setState(prevState, () => {
                me.props.janusConnector.publishingFeedJanusStrategy.sfu.replaceTracks({
                    tracks: me.generateTracksData(),
                    success: () => {
                        if (me.state.videoMuted)
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteVideo();
                        else
                          setTimeout(() => {
                            console.log("Boom");
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteVideo();
                          }, 2000);

                        if (me.state.audioMuted)
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteAudio();
                        else
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteAudio();

                    }});
                _resolve(true);
                // alert(JSON.stringify(me.generateTracksData()));
            });

        });
        // alert(_ev.target.value);
    }

    setVideo(_ev: any) {
        // alert(_ev.target.value);
        let me = this;
        let prevState = {...this.state};
        if (_ev.target.value !== "screen") {
            prevState.videoChooserHidden = true;
            prevState.videoDeviceId = _ev.target.value;
            // alert(_ev.target.value);
            prevState.videoDeviceLabel = me.state.videoDevices.filter((_x: any) => {
                return _x.deviceId === _ev.target.value
            })[0].label;
        } else {
            prevState.videoDeviceId = "screen";
        }

        me.setState(prevState, () => {
            if (me.props.janusConnector.publishingFeedJanusStrategy.sfu) {
                me.props.janusConnector.publishingFeedJanusStrategy.sfu.replaceTracks({
                    tracks: me.generateTracksData(),
                    success: () => {
                        if (me.state.videoMuted)
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteVideo();
                        else
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteVideo();

                        if (me.state.audioMuted)
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.muteAudio();
                        else
                            me.props.janusConnector.publishingFeedJanusStrategy.sfu.unmuteAudio();
                    }
                });
            }
            // alert(JSON.stringify(me.generateTracksData()));
        });
    }

    generateTracksData() {
        let me = this;
        let constraints = [] as any[];

        let audioDeviceId = me.state.audioDeviceId;
        if (!audioDeviceId) audioDeviceId = me.props.janusConnector.audioDevice;

        let videoDeviceId = me.state.videoDeviceId;
        if (!videoDeviceId) videoDeviceId = me.props.janusConnector.videoDevice;

        constraints.push({
            type: 'audio',
            mid: '0',	// We assume mid 0 is audio
            capture: { deviceId: { exact: audioDeviceId } }
        });

        if (me.state.videoDeviceId !== "screen") {
            if (me.state.videoDevices.length > 0) {
                constraints.push({
                    type: 'video',
                    mid: '1',	// We assume mid 0 is audio
                    capture: { deviceId: { exact: videoDeviceId } }
                });
            }
        } else {
            constraints.push({
                type: 'screen',
                mid: '1',	// We assume mid 0 is audio
                capture: true
            });
        }

        console.log(constraints);

        return constraints;
    }

    

    render() {
        let me = this;
        return <div style={{ position: "relative",
                          marginTop: "2px",
                          width: "100%",
                          marginBottom: "1px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "equally-spaced",
                          alignContent: "center",
                          alignItems: "center"
            }}
                 className={''}
                  key={`audio-muted-${me.state.audioMuted ? 1 : 2}`}
        >
                <div onClick={me.openMain}
                     className={"button"}
                     style={{   cursor: "pointer", width: "100%",
                       position: "relative", alignContent: "center", alignItems: "center",
                       background: "var(--color-main)", color: "var(--white)",
                       borderRadius: "10px", height: "60px", display: "flex"
                }}>
                  <i style={{cursor: "pointer",
                    marginLeft: "8px",
                    fontSize: "calc(6px + 0.26vw)",
                    opacity: (!me.state.showingParticipantsGrid ? 1 : 0.5),
                    color: "#fff"
                  }}
                   className={`fas fa-podium mr-2`}/>
                  <p style={{ margin: 0,
                    marginLeft: "9px",
                    fontSize: "calc(6px + 0.26vw)",
                    fontWeight: (!me.state.showingParticipantsGrid ? "900" : "600"),
                    }}
                  >Ведущий</p>
                </div>

                <div onClick={me.openTribune}
                     className={"button ml-5"}
                     style={{   cursor: "pointer", width: "100%",
                       position: "relative", alignContent: "center", alignItems: "center",
                       background: "var(--color-main)", color: "var(--white)",
                       borderRadius: "10px", height: "60px", display: "flex"}}>
                  <i style={{cursor: "pointer",
                    marginRight: "7px",
                    marginLeft: "7px",
                    fontSize: "calc(6px + 0.26vw)",
                    opacity: (!!me.state.showingParticipantsGrid ? 1 : 0.3),
                    color: "#fff"
                  }}
                     className={`fas fa-users mr-2`}/>
                  <p style={{ margin: 0,
                    marginRight: "4px",
                    marginLeft: "4px",
                    fontSize: "calc(6px + 0.26vw)",
                    fontWeight: (!!me.state.showingParticipantsGrid ? "900" : "600"),
                    textAlign: "left",
                  }}
                  >Сетка<br/>участников</p>
                </div>
              <div onClick={me.askWord}
                   className={"button"}
                   style={{   cursor: "pointer",
                     marginLeft: "7px",
                     width: "100%",
                     position: "relative",
                     alignContent: "center",
                     alignItems: "center",
                     height: "60px",
                     paddingLeft: "5px",
                     paddingRight: "0px",
                     background: "var(--color-main)",
                     color: "var(--white)",
                     borderRadius: "10px",
                     display: "flex"}}>
                <i style={{cursor: "pointer",
                  marginLeft: "7px",
                  marginRight: "10px",
                  fontSize: "calc(9px + 0.15vw)",
                  color: !me.state.askingForAWord ? "#fff" : "var(--red)"}}
                   className={`fas fa-circle mr-5`}/>
                <p style={{ margin: 0,
                  marginRight: "10px",
                  fontSize: "calc(6px + 0.26vw)",
                  textAlign: "left",
                }}
                >Запрос на выступление</p>
              </div>
              <div className={"button"}
                   key={`audio-mode-${me.state.audioMuted ? "muted" : "active"}`}
                   style={{
                     marginLeft: "7px",
                     position: "relative",
                     alignContent: "center",
                     alignItems: "center",
                     // paddingLeft: "20px",
                     background: me.state.audioMuted ? "var(--red)" : "var(--green_600)",
                     color: "var(--white)",
                     borderRadius: "10px",
                     display: "flex"}} >
                <div style={{
                  cursor: "pointer",
                  display: "flex",
                  height: "60px",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
                     onClick={(e) => {me.muteAudio()} }
                >
                  <i style={{ marginLeft: "12px",
                    marginRight: "12px",
                    position: "relative",
                    left: me.state.audioMuted ? "-5px" : 0,
                    width: "10px",
                    cursor: "pointer",
                  }}
                     className={`fal ${me.state.audioMuted ? "fa-microphone-slash" : "fa-microphone"} mr-5`}
                  />
                </div>
                <HoverableItem
                  triggerItem={<div style={{
                    pointerEvents: "all",
                    cursor: "pointer",
                    height: "60px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    // marginLeft: "5px",
                    // width: "50px",
                    paddingLeft: "5px",
                    paddingRight: "12px",
                    borderLeft: "1px solid rgba(255, 255, 255, 0.3)"
                  }}>
                    <i style={{pointerEvents: "all", cursor: "pointer"}}
                       className={"fal fa-angle-down ml-5"}></i>
                  </div>
                  }
                  onClose={() => {
                    if (me.audioSelectElementRef.current) me.audioSelectElementRef.current.blur();
                  }}
                  activeTriggerItem={
                    <div style={{
                      pointerEvents: "all",
                      cursor: "pointer",
                      height: "60px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      // marginLeft: "5px",
                      // width: "50px",
                      paddingLeft: "5px",
                      paddingRight: "12px",
                      borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
                      background: "rgba(40,40,40,0.2)",
                      borderRadius: "0px 10px 10px 0px"
                    }}>
                      <i style={{ cursor: "pointer"}}
                         className={"fal fa-angle-down ml-5"}></i>
                    </div>
                  } whereToShow={POSITIONING_TYPE.bottom_left}>
                  <div className={"modal-menu"}>
                    <p style={{marginTop: 0, fontSize: "10px", color: "var(--black)"}}>Текущее устройство аудиовхода: {me.state.audioDeviceLabel}</p>
                    <select style={{}}
                            onChange={(e) => { me.setAudio(e)} }
                            ref={me.audioSelectElementRef}
                    >
                      <option value={""}>Выберите микрофон</option>
                      {
                        me.state.audioDevices.map((_audio: any) => {
                          return <option key={_audio.deviceId} value={_audio.deviceId}>{_audio.label}</option>;
                        })
                      }
                    </select>
                  </div>
                </HoverableItem>
              </div>

              <div className={"button ml-5"} style={{
                position: "relative",
                // alignContent: "center",
                // alignItems: "center",
                // paddingRight: "20px",
                background: me.state.videoMuted ? "var(--red)" : "var(--green_600)",
                color: "var(--white)",
                borderRadius: "10px",
                display: "flex",
                height: "60px",
              }}>
                <div style={{
                  cursor: "pointer",
                  display: "flex",
                  height: "60px",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
                     onClick={(e) => {me.muteVideo()} }
                >
                  <i style={{ marginLeft: "6px",
                    marginRight: "10px",
                    position: "relative",
                    width: "10px"
                  }}
                     className={`fal ${me.state.videoMuted ? "fa-video-slash" : "fa-video"} `}/>
                </div>
                {/*<div style={{position: "absolute", opacity: 0.3, top: 0, right: "33px", display: "flex", height: "60px", width: "1px", background: "#fff"}}></div>*/}

                <HoverableItem
                  onClose={() => {
                    if (me.videoSelectElementRef.current) me.videoSelectElementRef.current.blur();
                  }}
                  triggerItem={
                    <div style={{
                      pointerEvents: "all",
                      cursor: "pointer",
                      height: "60px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      marginLeft: "5px",
                      // width: "50px",
                      paddingRight: "12px",
                      paddingLeft: "10px",
                      borderLeft: "1px solid rgba(255, 255, 255, 0.3)"
                    }}>
                      <i className={"fal fa-angle-down"}></i>
                    </div>
                  }
                  activeTriggerItem={
                    <div style={{
                      pointerEvents: "all",
                      cursor: "pointer",
                      height: "60px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      marginLeft: "5px",
                      // width: "50px",
                      paddingRight: "12px",
                      paddingLeft: "10px",
                      borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
                      background: "rgba(40,40,40,0.2)",
                      borderRadius: "0px 10px 10px 0px"
                    }}>
                      <i  style={{position: "relative" }}
                          className={"fal fa-angle-down"}></i>
                    </div>
                  } whereToShow={POSITIONING_TYPE.center_left}>
                  <div className={"modal-menu"}>
                    <p style={{marginTop: 0, fontSize: "10px", color: "var(--black)"}}>Текущее устройство видеовхода: {me.state.videoDeviceLabel}</p>
                    <select style={{}}
                            onChange={(e) => { me.setVideo(e)} }
                            ref={me.videoSelectElementRef}
                    >
                      <option value={""}>Выберите камеру</option>
                      <option value={"screen"}>Экран</option>
                      {
                        me.state.videoDevices.map((_video: any) => {
                          return <option key={_video.deviceId}
                                         value={_video.deviceId}>{_video.label}</option>;
                        })
                      }
                    </select>
                  </div>
                </HoverableItem>
              </div>
            </div>
    }
}