import ParticipantInterface from "./ParticipantInterface";

export default class Participant implements ParticipantInterface {
    cell: string;
    created_at: string | undefined;
    updated_at: string | undefined;
    email: string;
    first_name: string;
    id: number;
    is_tester: boolean | null;
    job: string;
    last_name: string;
    middle_name: string;
    organization: string;
    show_in_participants: boolean | null;
    peer_id?: string | undefined;
    region_title?: string | undefined;
    role?: string | undefined;

    is_muted?: boolean;
    block_video?: boolean;
    fullAccess:boolean = true;
    is_on_fullscreen: boolean = false;
    call_me: boolean = false;
    jwt?: string;


    static nullParticipant() {
        return new Participant({
            role: "nobody",
            region_title: "",
            cell: "",
            created_at: "",
            email: "",
            first_name: "",
            id: 0,
            is_tester: null,
            job: "",
            last_name: "",
            middle_name: "",
            organization: "",
            show_in_participants: false,
            updated_at: "",
            peer_id: ""
        });
    }

    generatePeerId() {
        let res = `new_${Math.round(Math.random() * 100000)}_${Math.round(Math.random() * 100000)}_${Math.round(Math.random() * 100000)}`;
        // alert(res);
        return res;
    }

    constructor(_data: ParticipantInterface) {
        this.cell = _data.cell;
        this.region_title = _data.region_title;
        this.created_at = _data.created_at;
        this.email = _data.email;
        this.first_name = _data.first_name;
        this.id = _data.id;
        this.is_tester = _data.is_tester;
        this.job = _data.job;
        this.last_name = _data.last_name;
        this.middle_name = _data.middle_name;
        this.organization = _data.organization;
        this.show_in_participants = _data.show_in_participants;
        this.updated_at = _data.updated_at;
        if (_data.role)
            this.role = _data.role;
        this.name = this.name.bind(this);
        this.peer_id = _data.peer_id ? _data.peer_id : this.generatePeerId();

        // alert(this.name());
    }



    name():string {
        let res = [];
        if (this.last_name) res.push(this.last_name);
        if (this.first_name) res.push(this.first_name);
        if (this.middle_name) res.push(this.middle_name);
        return res.join(" ");
    }

}