import * as React from "react";
import {ApiEngine} from "api-engine";

interface TranslatedTextProps {
  content: string,
  fallback: string,
  api: ApiEngine,
  i18nUrl: string,
  disableTranslation?: boolean;
  onTranslated?: (_term:string, _translatedTerm: string) => void;
  textTransformationFunc?: (_wordChar: string, _charIndex: number) => string;
}

export default class TranslatedText extends React.Component<TranslatedTextProps, any> {
  constructor(_props: TranslatedTextProps) {
    super(_props);

    this.state = {
      shown: this.props.fallback
    }
  }

  componentDidMount() {
    let me = this;
    if (me.props.disableTranslation) return;

    me.props.api.prioritizedAsyncFetchWithCache(`${me.props.i18nUrl}?term=${me.props.content}`, {}, -10).then((e: any) => {
      if (!e.translation) return;
      if (e.translation.indexOf("translation missing") > -1) return;
      let translation = e.translation;
      if (me.props.textTransformationFunc) {
        let stringAsArray = [...e.translation];
        translation = stringAsArray.map(me.props.textTransformationFunc).join("");
      }
      let prevState = {...me.state};
      prevState.shown = translation;
      me.setState(prevState, () => {
        if (me.props.onTranslated) me.props.onTranslated(me.props.content, translation);
      });
    });
  }

  render() {
    let me = this;
    return (<>{me.state.shown}</>);
  }
}