import JWTContainer from "../models/JWTContainer";
import ApiEngine from "../gateway/ApiEngine";

export default class SessionContainer<UserClass> {
    private meUrl: string;
    private _currentEntity: any | null;
    private _jwtContainer?: JWTContainer | null | undefined;
    myself: any;
    entityClassAsObject: any;
    private _apiEngine: ApiEngine | null

    get apiEngine(): ApiEngine {
        if (!this._apiEngine) throw "No API engine set";
        return this._apiEngine;
    }

    set apiEngine(value: ApiEngine) {
        this._apiEngine = value;
    }

    constructor(_entityClassAsObject: any, _meUrl: string) {
        this._apiEngine = null;
        this.meUrl = _meUrl;
        this._currentEntity = null;
        this.entityClassAsObject = _entityClassAsObject;
        this.jwtContainer = JWTContainer.tryToRestoreJWT();
    }

    get currentEntity(): UserClass  {
        return this._currentEntity;
    }

    set currentEntity(value: UserClass) {
        this._currentEntity = value;
    }

    set jwtContainer(value: JWTContainer | null | undefined) {
        this._jwtContainer = value;
        if (this._jwtContainer) {
            // alert(123);
            this._jwtContainer.writeToLocalStorage();
        }
    }

    get jwtContainer():JWTContainer | null  | undefined {
        return this._jwtContainer;
    }

    checkUser():Promise<UserClass> {
        let me = this;
        if (!me.apiEngine) throw new Error("Не задан API Engine");
        console.log("checkUser");
        return new Promise((resolve, reject) => {
            me.apiEngine.asyncFetch(me.meUrl, {}).then((e:any) => {
                if (e && me._jwtContainer) {
                    me.currentEntity = (new me.entityClassAsObject(e.uuid)) as UserClass;
                    resolve(me.currentEntity);
                    // alert(JSON.stringify(e));
                } else {
                    reject(false);
                }
            }, (e) => {
                if (me._jwtContainer) me._jwtContainer.revoke();
            });
        });
    }


}