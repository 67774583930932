import React from "react";
import {ApiEngine} from "api-engine";
import Participant from "../../models/participants/Participant";
import MulticircleSpinner from "../../loaders/spinners/multicircle-spinner/MulticircleSpinner";
import {Link, Route, Routes} from "react-router-dom";
import FullYearCalendar from "./calendar/FullYearCalendar";

interface ConferencesProps {
  mode: string;
  api: ApiEngine;
  participant: Participant;
}

interface ConferencesState {
  conferences: null | any[];
}

export default class Conferences extends React.Component<ConferencesProps, ConferencesState> {

  binded: boolean = false;

  constructor(_props: ConferencesProps) {
    super(_props);

    this.state = {
      conferences: null
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (this.binded) return;
    let me = this;
    this.binded = true;
    this.getData().then((_conferencesPackage: any) => {
      me.setState({conferences: _conferencesPackage.result})
    });
  }

  getData() {
    let me = this;
    return new Promise((_resolve) => {
      me.props.api.asyncFetch(`/api/participants/${me.props.participant.id}/conferences?mode=${me.props.mode}`,{}).then((_res: any) => {
        _resolve(_res);
      });
    });
  }

  render() {
    let me = this;

    return <div className={"conferences-list"}>
      <br/>
      <br/>
      { !me.state.conferences &&
        <MulticircleSpinner/>
      }

      {
        me.state.conferences &&
        <div className={"flex flex-row justify-between"}>
          <div className={"card"}
               style={{ width: "46%"}}>
            <h2>ВЫБОР ЗАСЕДАНИЯ</h2>
            <div className={"content"}>
              {
                me.state.conferences.map((_conf: any, _confIndex: number) => {
                  let d = new Date(_conf.date_start);
                  let dateStr = `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes() ? `0${d.getMinutes()}` : d.getMinutes()} ${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}.${d.getMonth() < 10 ? `0${d.getMonth()}` : d.getMonth()}.${d.getFullYear()}`;
                  return <Link key={`conf-link-${_confIndex}`}
                               className={"conf-link"}
                               to={`/conferences/${_conf.id}`}>
                    <p>{_conf.title}</p>
                    { _conf.date_start &&
                        <p>{dateStr}</p>
                    }
                  </Link>
                })
              }
              {/*{JSON.stringify(me.state.conferences)}*/}
            </div>
          </div>
          <div className={"card"} style={{ width: "46%"}}>
            <h2>КАЛЕНДАРЬ ЗАСЕДАНИЙ</h2>
              <div className={"content"}>
                <FullYearCalendar api={me.props.api}
                                  onCommand={(_res) => {
                                    alert(JSON.stringify(_res));
                                  }} />
              </div>
          </div>
        </div>
      }
    </div>;
  }
}