import React from "react";
import ScreenFull from "screenfull";
import Participant from "../../models/participants/Participant";
import Voting from "../votings/Voting";
import CircleSpinner from "../../loaders/spinners/CircleSpinner";
import MulticircleSpinner from "../../loaders/spinners/multicircle-spinner/MulticircleSpinner";
import "./conference-content.scss";

interface ConferenceControlsProps {
    activeDocumentId: number | null,
    serverUrl: string
    myself: Participant
}

interface ConferenceControlsState {
    title: string | null;
    plainHtmlContent: string | null,
    activeDocumentId: number | null,
    fullscreen: boolean,
    checkFullsrcreenIntervalId: any;
    activeVoting: any | null;
}

export default class ConferenceContentPart extends React.Component<ConferenceControlsProps, ConferenceControlsState> {
    contentRef: React.RefObject<HTMLDivElement>;
    initialized: boolean = false;
    private contentScrollableRef: React.RefObject<HTMLDivElement>;

    constructor(_props: ConferenceControlsProps) {
        super(_props);

        this.state = {
            title: null,
            activeDocumentId: this.props.activeDocumentId,
            plainHtmlContent: null,
            fullscreen: false,
            checkFullsrcreenIntervalId: null,
            activeVoting: null
        }

        this.contentRef = React.createRef<HTMLDivElement>();
        this.contentScrollableRef = React.createRef<HTMLDivElement>();
        this.requestFullscreen = this.requestFullscreen.bind(this);
        this.checkFullscreen = this.checkFullscreen.bind(this);
        this.getData = this.getData.bind(this);
    }

    checkFullscreen() {
        if (!document.fullscreenElement) {
            let prevState = {...this.state};
            prevState.fullscreen = false;
            this.setState(prevState);
        }
    }

    componentWillUnmount() {
        if (this.initialized) return;
        this.initialized = true;

        let prevState = {...this.state};
        clearInterval(prevState.checkFullsrcreenIntervalId);
        this.setState(prevState);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let me = this;
        if (!me.props.activeDocumentId) {
            // alert("123");
            return;
        } else {
            // alert(456);
        }
        me.setState({
            plainHtmlContent: null,
            activeVoting: null
        }, () => {

            let url = new URL(`${me.props.serverUrl}/api/documents/${me.props.activeDocumentId}?user_id=${me.props.myself.id}`);
            // alert(url);
            fetch(url).then((_res) => {
                return _res.text();
            }).then((_e) => {
                try {
                    // alert(_e);
                    let objCont = JSON.parse(_e);
                    // alert(objCont);
                    if (objCont.votings) {
                        me.setState({
                            title: objCont.title,
                            activeVoting: objCont.votings,
                            plainHtmlContent: objCont.plainHtmlContent}) ;
                        return;
                    }
                    return;
                } catch (e) {
                    // alert(url);
                    // alert(_e);
                }

                let prevState = {...me.state};
                prevState.activeVoting = null;
                // prevState.checkFullsrcreenIntervalId = setInterval(me.checkFullscreen, 500);
                prevState.plainHtmlContent = _e;
                me.setState(prevState);
            });
        });

    }

    requestFullscreen() {
        let me = this;
        let prevState = {...me.state};
        prevState.fullscreen = !prevState.fullscreen;
        me.setState(prevState, () => {
            let element = me.contentRef.current;
            if (!element) return;
            if (prevState.fullscreen) {
                ScreenFull.request(element); //.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        });
    }

    render() {
        let me = this;
        return <div
                    key={`${me.state.activeVoting ? JSON.stringify(me.state.activeVoting) : '-'}-${me.state.activeDocumentId ? me.state.activeDocumentId : '-'} ${me.state.plainHtmlContent ? me.state.plainHtmlContent : '-'}`}
                    className={"card"}
                    ref={me.contentRef}
                    style={{width: "100%", height: "60vh"}}>
            <h4 className={"flex flex-row justify-between content-center items-center"}>
                <span>
                    Показ
                </span>
                {/*<i onClick={me.requestFullscreen} className={"fal fa-expand"}></i>*/}
                <button className={"show-on-fullscreen"} style={{borderRadius: "10px", padding: "7px", marginLeft: "auto"}} onClick={me.requestFullscreen }>Выйти из полного экрана</button>
                <button className={"hide-on-fullscreen"} style={{borderRadius: "10px", padding: "7px", marginLeft: "auto"}} onClick={me.requestFullscreen }>Полный экран</button>
            </h4>
            <div
              className={"cool-overflow"}
              ref={me.contentScrollableRef}
              style={{height: me.state.fullscreen ? "90vh" : "60vh", overflowY: "scroll", borderRadius: "10px"}}>
                {me.state.title && <h3 style={{marginLeft: "20px"}}>{me.state.title}</h3>}
                {/*<p>{JSON.stringify(me.state)}</p>*/}
                { me.state.plainHtmlContent &&
                    <div style={me.state.fullscreen ? {marginTop: "1vh", height: "100%", width: "auto"} : {width: "100%", height: "70%"}}
                         dangerouslySetInnerHTML={{__html: me.state.plainHtmlContent}}></div>
                }
                { me.state.activeVoting &&
                  <div style={{marginLeft: "20px", pointerEvents: "all"}}>
                      <Voting content={me.state.activeVoting}
                              myself={me.props.myself}
                              parent={me.contentScrollableRef}
                      />
                  </div>
                }

                {!me.state.plainHtmlContent && !me.state.activeVoting &&
                  <div className={"flex flex-row justify-center"}
                       style={{marginTop: "40px"}}
                  >
                      {/*<p>Here</p>*/}
                      <MulticircleSpinner/>
                      {/*<CircleSpinner circleColor={"#1B196C"}/>*/}
                  </div>
                }
            </div>
        </div>
    }
}