import React from 'react';

import {Chart, registerables } from "chart.js";
import {ChartType} from 'chart.js';
Chart.register(...registerables);

interface SummaryCircularResultChartProps {
  label: string;
  theme: string;
  metricName: string;
  amount_of_activated_key_pairs: number;
  data: {
    label: string,
    percentage: number
  }[];
}

const backgroundColors = [
  "#103BAE",
  "#B00000",
  "#19183D",
  "#5965b3",
  "#1b7a1b",
  "#2787f5",
  "rgb(255, 99, 132)",
  "#ffc107",
  "#45688e",
  "#792ec0",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D",
  "#19183D"
];

export default class SummaryBarResultChart extends React.Component<SummaryCircularResultChartProps, any> {
  private chartRef: React.RefObject<HTMLCanvasElement>;
  private chart: any;
  constructor(_props:SummaryCircularResultChartProps) {
    super(_props);
    this.chartRef = React.createRef<HTMLCanvasElement>();
    this.chart = null;

    this.compileDataSet = this.compileDataSet.bind(this);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  compileDataSet() {
    const labels = this.props.data.sort((a: { label: string; percentage: number }, b: { label: string; percentage: number }) => {
      if ( a.percentage === b.percentage) return 0;
      return  a.percentage > b.percentage ? -1 : 1;
    }).map((x) => {return x.label});
    const data = this.props.data.sort((a: { label: string; percentage: number }, b: { label: string; percentage: number }) => {
      if (a.percentage === b.percentage) return 0;
      return a.percentage > b.percentage ? -1 : 1;
    }).map((x) => {return x.percentage});


    return  {
      labels: labels,
      datasets: [{
        label: this.props.metricName,
        maxBarThickness: 48,
        borderRadius: 7,
        data: data,
        backgroundColor: backgroundColors,
        hoverOffset: 4
      }]
    };
  }

  componentDidMount() {
    let me = this;
    if (!me.chartRef.current) return;

    let doughnutChartType: ChartType = "bar";
    const config = {
      type: doughnutChartType,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        indexAxis: 'y' as const,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: false,
            color: "#fff"
          }
        },
        axes: {
          x: {
            display: false
          },
          y: {
            display: false
          }
        }
      },

      data: me.compileDataSet(),
    };

    this.chart = new Chart(me.chartRef.current, config);
  }

  render() {
    let me = this;
    return <div className={"flex flex-row w-100 justify-center"}>
      <div className={"flex flex-column w-80"}>
        {/*<h1 style={{textTransform: "uppercase"}}>{me.props.label}</h1>*/}
        <div className={"v-spacer-1"}></div>
        <div className={"flex flex-row justify-around w-70"}>
          <div className={"flex flex-column justify-center"}>
            {
              this.props.data.sort((a: { label: string; percentage: number }, b: { label: string; percentage: number }) => {
                if ( a.percentage === b.percentage) return 0;
                return  a.percentage > b.percentage ? -1 : 1;
              }).map((_e, _index) => {
                return <div className={"flex flex-row align-center items-center mb-2 justify-between"}>
                  <div className={"flex flex-row align-center items-center"}>
                    <span className={"flex mr-2"} style={{borderRadius: "50%", minWidth: "20px", width: "20px", height: "20px", background: backgroundColors[_index]}}></span>
                    <p style={{textTransform: "uppercase", fontSize: "var(--font-size-4)"}} className={"w-70"}>{_e.label}</p>
                  </div>
                  <div className={"h-spacer-5"}></div>
                  <p style={{fontSize: "var(--font-size-4)"}} ><b>{_e.percentage}</b></p>
                </div>;
              })
            }
            <hr/>
            <div className={"flex flex-row align-center items-center mb-2 justify-between mt-1"} style={{opacity: 0.8}}>
              <p style={{textTransform: "uppercase", fontSize: "var(--font-size-4)"}} className={"w-70"}>Всего голосов</p>
              <p style={{fontSize: "var(--font-size-4)"}}>{me.props.data.map((e) => e.percentage).reduce((_prevValue: number, _currValue: number, _currIndex: number, _arr: number[]) => {
                return _prevValue + _currValue;
              }, 0)
              }</p>
            </div>
            <div className={"flex flex-row align-center items-center mb-2 justify-between mt-1"} style={{opacity: 0.8}}>
              <p style={{textTransform: "uppercase", fontSize: "var(--font-size-4)", marginRight: "8px"}} className={"w-70"}>Количество принятых электронных бюллетеней</p>
              <p style={{fontSize: "var(--font-size-4)"}}>{me.props.amount_of_activated_key_pairs}</p>
            </div>
          </div>
          <div className={"h-spacer-2"}></div>
          <div className={"flex w-25 relative"}>
            <canvas style={{width: "30vw"}} ref={me.chartRef}></canvas>
          </div>
        </div>
      </div>
    </div>;
  }
}
