import * as React from "react";
import "./chosen-element.css";
import {ApiEngine} from "api-engine";

interface AsyncSelectProps {
  api: ApiEngine;
  url?: string;
  placeholder: string;
  onChange?: (_val: any) => void;
  style?: any;
  inputStyle?: any;
  angleDownElement?: JSX.Element;
  value?: any;
  starting_data?: any[];
  disableUpdate?: boolean;
  use?: [{"path": string[], "as": "string"}];
  sourceOfData?: any;
}

interface AsyncSelectState {
  data: any[];
  query: string;
  value: any;
  shownValue: string;
  hideMenu: boolean;
}

export default class AsyncSelect extends React.Component<AsyncSelectProps, AsyncSelectState> {
  inintialized: boolean;
  private getDataTimeout: NodeJS.Timeout;
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(_props: AsyncSelectProps) {
    super(_props);

    let addedValue = null;

    if (this.props.value) {
      if (parseInt(this.props.value).toString() === this.props.value.toString()) {
        addedValue = parseInt(this.props.value);
      } else {
        addedValue = this.props.value;
      }
    }

    this.state = {
      data: this.props.starting_data ? this.props.starting_data : [],
      query: "",
      value: addedValue,
      shownValue: "",
      hideMenu: true
    };

    this.getDataTimeout = setTimeout(() => {}, 0);
    this.inintialized = false;

    this.getData = this.getData.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.setValue = this.setValue.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.focus = this.focus.bind(this);

    this.inputRef = React.createRef<HTMLInputElement>();

  }

  componentDidMount() {
    this.getData();
  }

  focus() {
    // alert("focusing");
    let el = this.inputRef.current;
    if (el) {
      el.focus();
      setTimeout(() => {
        if (el) el.click();
      }, 300);
    }
  }

  openMenu(): Promise<any> {
    let me = this;
    return new Promise((_resolve) => {
      let prevState = {...me.state};
      prevState.hideMenu = false;
      me.setState(prevState, () => {
        _resolve(true);
      });
    });
  }

  closeMenu() {
    let prevState = {...this.state};
    prevState.hideMenu = true;
    this.setState(prevState);
  }

  getData() {
    let me = this;
    if (!me.props.url) return;
    if (!!me.props.disableUpdate) return;
    let delimiter = me.props.url.indexOf("?") > -1 ? "&" : "?";
    let url = `${me.props.url}${delimiter}q=${me.state.query}`;
    if (this.props.value) {
      url += `&include_id=${me.props.value}`;
    }
    // alert(url);
    me.props.api.asyncFetch(url, {}).then((_e: any) => {
      let prevState = {...me.state};
      // prevState.hideMenu = false;
      prevState.data = _e;
      me.setState(prevState, () => {
        if (!me.inintialized && me.props.value && (_e.length > 0)) {
          me.inintialized = true;
          let value = _e.filter((_x: any[]) => {
            return _x[0] === me.state.value
          })[0];
          me.setValue(value, true);
        }
      });
    });
  }

  setQuery(_e: React.ChangeEvent<HTMLInputElement> | null):Promise<any> {
    let me = this;
    let pathModificator = "";
    return new Promise((_resolve) => {
      let val = "";

      if (_e) {
        let target = _e.currentTarget;
        if (!target) return;
        val = target.value;
      }

      if (me.props.use) {
        if (me.props.sourceOfData) {
          let additionToPath = [] as any[];
          for(let _usedParam of me.props.use) {
            let res = me.props.sourceOfData.getDataUsingPath(_usedParam.path);
            additionToPath.push(`${_usedParam.as}=${res}`);
          }
          pathModificator = `&${additionToPath.join("&")}`;
          // alert(pathModificator);
          // console.log(me.props.sourceOfData);
        }
      }
      let prevState = {...me.state};
      prevState.query = `${val}${pathModificator}`;
      // alert(prevState.query);
      prevState.shownValue = val;

      me.setState(prevState, () => {
        _resolve(true);
      });
    });
  }

  setValue(_value: any, _ignoreChange: boolean) {
    let me = this;
    let prevState = {...me.state};
    if (Array.isArray(_value)) {
      prevState.value = _value[0];
      prevState.shownValue = _value[1];
    } else {
      prevState.value = _value;
      prevState.shownValue = _value;
    }
    prevState.hideMenu = true;
    me.setState(prevState, () => {
      if (!_ignoreChange && me.props.onChange) {
        me.props.onChange(_value[0]);
      }
    });
  }

  render() {
    let me = this;
    return <div key={`12-${me.state.value}`}
                onMouseLeave={me.closeMenu}
                style={ me.props.style ? me.props.style : { position: "relative" }}>
      {/*<p>{me.state.data.length}</p>*/}
      {/*<p>{me.state.query}</p>*/}
      {/*<p>{me.props.url}</p>*/}
      {/*<p>{me.state.value}</p>*/}
      {/*<p>{parseInt(me.props.value).toString() === me.props.value ? 1 : 2}</p>*/}
      {/*<p>Val: {me.props}</p>*/}

      {/*<p>{JSON.stringify(me.props)}</p>*/}

      <input style={{...me.props.inputStyle, width: "100%"}}
             ref={me.inputRef}
             onFocus={() => {
               me.setQuery(null).then((_e) => {
                 clearTimeout(me.getDataTimeout);
                 me.getDataTimeout = setTimeout(() => {
                   me.getData();
                   me.openMenu();
                 }, 500);
               });
              }
             }
             onClick={me.openMenu}
             value={me.state.shownValue}
             onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
               me.setQuery(_e).then((_e) => {
                 clearTimeout(me.getDataTimeout);
                 me.getDataTimeout = setTimeout(() => {
                   me.getData();
                   me.openMenu();
                 }, 500);
               });
              }
             } className={"field"} placeholder={me.props.placeholder} />
      {/*<p>{JSON.stringify(me.state.data)}</p>*/}
      {/*<p>{me.state.value}</p>*/}
      { (!me.state.hideMenu) && (me.state.data.length > 0) &&
          <div style={{borderRadius: "10px", zIndex: 10, padding: "10px", paddingBottom: 0, overflowY: "scroll", maxHeight: "300px", background: "#fff", position: "absolute", top: "90%", left: "10px"}}>
            { me.state.data.map((_x: any, _variantIndex: number) => {
                let style = (_x[0] === me.state.value) ? {fontWeight: 900} : {};
                return <div style={
                          { ...style,
                            cursor: "pointer",
                            marginBottom: "14px"
                          }
                        }
                        className={"chosen-variant"}
                        key={`variant-${_variantIndex}-${_x[0]}`}
                        onClick={(e) => {me.setValue(_x, false)}
                }>{_x[1]}</div>;
              })
            }
          </div>
        }
      { !me.props.angleDownElement &&
          <i style={{position: "absolute", top: "20px", color: "#858585"}} className={"fal fa-angle-down fa-2x"}/>
      }
      { me.props.angleDownElement &&
        me.props.angleDownElement
      }
    </div>;
    // return <h3>12312</h3>
  }
}