import JanusStrategyInterface from "../JanusStrategyInterface";
import $ from "jquery";
import JanusConnector from "../../JanusConnector";

export default class PublishingFeedJanusBaseStrategy implements JanusStrategyInterface {
    set myid(value: any) {
        if (this.janusConnector) this.janusConnector.myid = value;
        this._myid = value;
    }
    set mypvtid(value: any) {
        if (this.janusConnector) this.janusConnector.mypvtid = value;
        this._mypvtid = value;
    }

    sfu?: any;
    protected _myid?: any;
    protected _mypvtid?: any;
    mystream?: any;
    localTracks: any;
    localVideos: number;
    bitrateTimer: any[];
    simulcastStarted: any;
    JanusClass: any;

    constructor() {
        this.localTracks = {};
        this.localVideos = 0;
        this.bitrateTimer = [];
        this.simulcastStarted = {};

        // @ts-ignore
        this.JanusClass = Janus;
        this.janusConnector = null;
    }

    success(_pluginHandle: any): void {
        this.sfu = _pluginHandle;
        let message = "Plugin attached! (" +  this.sfu.getPlugin() + ", id=" +  this.sfu.getId() + ")";
        // alert(message);
        console.log(message);
    }

    consentDialog(_on: boolean, _janusInstance: any): void {
        // alert("Consent dialog should be " + (_on ? "on" : "off") + " now");
    }

    error(_error: any, _janusInstance: any): void {
        console.error(_janusInstance);
        console.error(_error);
        // alert(`Error: ${_error}`);
    }

    iceState(_state: any, _janusInstance: any): void {
        // alert("ICE state changed to " + _state);
    }

    mediaState(_medium: any, _on: any, _mid: any, _janusInstance: any): void {
        // alert("Janus " + (_on ? "started" : "stopped") + " receiving our " + _medium + " (mid=" + _mid + ")");
    }

    webrtcState(_on: any, _janusInstance: any): void {
        // if (_on)
        // alert("Janus says our WebRTC PeerConnection is " + (_on ? "up" : "down") + " now");
    }

    slowLink(uplink: any, lost: any, mid: any, _janusInstance: any): void {
        alert("Janus reports problems " + (uplink ? "sending" : "receiving") +
            " packets on mid " + mid + " (" + lost + " lost packets)");
    }

    onmessage(msg: any, jsep: any, _janusInstance: any): void {
        // alert(`Message: ${JSON.stringify(msg)} + ${JSON.stringify(jsep)}`);
        // alert(" ::: Got a message (publisher) :::" + msg);
        let event = msg["videoroom"];
        // alert("Event: " + event);
        if(event === undefined || event === null) return;
            if(event === "joined") {

                // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                this.myid = msg["id"];
                this.mypvtid = msg["private_id"];
                // alert("Successfully joined room " + msg["room"] + " with ID " + this.myid);
                // if(subscriber_mode) {
                //     $('#videojoin').hide();
                //     $('#videos').removeClass('hide').show();
                // } else {
                if (this.janusConnector) {
                    if (this.janusConnector.publishing) {
                        this.publishOwnFeed();
                    } else {
                        // alert(`${this.janusConnector.name} is unpublishing`);
                        this.janusConnector.successfullyConnectedAsPublisher = false;
                    }
                }
                // }
                // Any new feed to attach to?
                // alert(2);
                if (msg["publishers"] && this.janusConnector && this.janusConnector.receivingFeedJanusStrategy) {
                    let list = msg["publishers"];
                    // alert("Got a list of available publishers/feeds:" + list.length);
                    let sources = null;
                    for (let f in list) {
                        if (list[f]["dummy"])
                            continue;
                        let id = list[f]["id"];
                        let display = list[f]["display"];
                        let streams = list[f]["streams"];
                        for (let i in streams) {
                            let stream = streams[i];
                            stream["id"] = id;
                            stream["display"] = display;
                        }
                        let slot = this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] ? this.janusConnector.receivingFeedJanusStrategy.feedStreams[id].slot : null;
                        let remoteVideos = this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] ? this.janusConnector.receivingFeedJanusStrategy.feedStreams[id].remoteVideos : 0;
                        this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] = {
                            id: id,
                            display: display,
                            streams: streams,
                            slot: slot,
                            remoteVideos: remoteVideos
                        }
                        //         Janus.debug("  >> [" + id + "] " + display + ":", streams);
                        if (!sources)
                            sources = [];
                        sources.push(streams);
                    }

                    if (sources)
                        this.janusConnector.receivingFeedJanusStrategy.subscribeTo(sources);
                    // }
                }
            } else {
                if (msg["publishers"]) {
                    let list = msg["publishers"];
                    console.log("Got a list of available publishers/feeds:", list);

                    let sources = null;

                    for (let f in list) {
                        if (list[f]["dummy"])
                            continue;
                        let id = list[f]["id"];
                        let display = list[f]["display"];
                        let streams = list[f]["streams"];

                        for (let i in streams) {
                            let stream = streams[i];
                            stream["id"] = id;
                            stream["display"] = display;
                        }

                        if (!this.janusConnector) continue;
                        let slot = this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] ? this.janusConnector.receivingFeedJanusStrategy.feedStreams[id].slot : null;
                        let remoteVideos = this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] ? this.janusConnector.receivingFeedJanusStrategy.feedStreams[id].remoteVideos : 0;
                        this.janusConnector.receivingFeedJanusStrategy.feedStreams[id] = {
                            id: id,
                            display: display,
                            streams: streams,
                            slot: slot,
                            remoteVideos: remoteVideos
                        }
                        //             Janus.debug("  >> [" + id + "] " + display + ":", streams);
                        if (!sources)
                            sources = [];
                        sources.push(streams);
                    }
                    if (sources && this.janusConnector)
                        this.janusConnector.receivingFeedJanusStrategy.subscribeTo(sources);
                } else if (msg["leaving"]) {
                    //         // One of the publishers has gone away?
                    let leaving = msg["leaving"];
                    console.log("Publisher left: " + leaving);
                    this.janusConnector?.receivingFeedJanusStrategy.unsubscribeFrom(leaving);
                } else if (msg["unpublished"]) {
                    // One of the publishers has unpublished?
                    let unpublished = msg["unpublished"];
                    console.log("Publisher left: " + unpublished);
                    if (unpublished === 'ok') {
                        // That's us
                        this.sfu.hangup();
                        return;
                    }
                    this.janusConnector?.receivingFeedJanusStrategy.unsubscribeFrom(unpublished);
                }
            }
            if(msg["error"]) {
                console.error(this.janusConnector);
                console.error(msg);
                if (msg["error_code"] === 432) {
                    if (this.janusConnector) this.janusConnector.successfullyConnectedAsPublisher = false;
                    return;
                }
            //         if(msg["error_code"] === 426) {
            //             // This is a "no such room" error: give a more meaningful description
            //             bootbox.alert(
            //                 "<p>Apparently room <code>" + myroom + "</code> (the one this demo uses as a test room) " +
            //                 "does not exist...</p><p>Do you have an updated <code>janus.plugin.videoroom.cfg</code> " +
            //                 "configuration file? If not, make sure you copy the details of room <code>" + myroom + "</code> " +
            //                 "from that sample in your current configuration file, then restart Janus and try again."
            //             );
            //         } else {
            //             bootbox.alert(msg["error"]);
            //         }
            //     }
            }

            if (jsep) {
                // alert("Handling SDP as well..." + jsep);
                if (this.janusConnector) this.janusConnector.successfullyConnectedAsPublisher = true;

                this.sfu.handleRemoteJsep({ jsep: jsep });
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
                let audio = msg["audio_codec"];
                if(this.mystream && this.mystream.getAudioTracks() && this.mystream.getAudioTracks().length > 0 && !audio) {
                    // Audio has been rejected
                    alert("Our audio stream has been rejected, viewers won't hear us");
                }
                let video = msg["video_codec"];
                if(this.mystream && this.mystream.getVideoTracks() && this.mystream.getVideoTracks().length > 0 && !video) {
                    // Video has been rejected
                    alert("Our video stream has been rejected, viewers won't see us");
                    // Hide the webcam video
                    if (this.janusConnector && this.janusConnector.elementToPutChildrenTo) {
                        $(this.janusConnector.elementToPutChildrenTo).append(
                            '<div class="no-video-container">' +
                            '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                            '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                            '</div>');
                    }
                }
            }
    }

    onlocaltrack(_track: any, _on: any, _janusInstance: any): void {
        _track.enabled = false;
        let toDelete = document.getElementsByClassName("my-track");
        for(let i = 0; i < toDelete.length; i++) {
            let el = toDelete[i];
            el.remove();
        }
        // alert(" ::: Got a local track event :::");
        // alert("Local track " + (_on ? "added" : "removed") + ":" + _track);
        // alert(" ::: Got a local track event ::: " + _on);
        // alert("Local track " + (_on ? "added" : "removed") + ":"+ _track);
        // We use the track ID as name of the element, but it may contain invalid characters
        let trackId = _track.id.replace(/[{}]/g, "");
        if(!_on) {
            // Track removed, get rid of the stream and the rendering
            let stream = this.localTracks[trackId];
            if(stream) {
                try {
                    let tracks = stream.getTracks();
                    for(let i in tracks) {
                        let mst = tracks[i];
                        if(mst)
                            mst.stop();
                    }
                } catch(e) {}
            }
            if(_track.kind === "video") {
                $('#myvideo' + trackId).remove();
                this.localVideos--;
                if(this.localVideos === 0) {
                    // No video, at least for now: show a placeholder
                    // if (this.janusConnector && this.janusConnector.elementToPutChildrenTo) {
                    //     $(this.janusConnector.elementToPutChildrenTo).append(
                    //         '<div class="no-video-container">' +
                    //         '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                    //         '<span class="no-video-text">No webcam available</span>' +
                    //         '</div>');
                    // }
                }
            }
            delete this.localTracks[trackId];
            return;
        }
        // If we're here, a new track was added
        let stream = this.localTracks[trackId];
        if(stream) {
            // We've been here already
            return;
        }

        if(_track.kind === "audio") {
            // We ignore local audio tracks, they'd generate echo anyway
            if(this.localVideos === 0) {
                // No video, at least for now: show a placeholder
                if (this.janusConnector && this.janusConnector.elementToPutChildrenTo) {
                    // $(this.janusConnector.elementToPutChildrenTo).append(
                    //     '<div class="no-video-container">' +
                    //     '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                    //     '<span class="no-video-text">No webcam available</span>' +
                    //     '</div>');
                }
            }
        } else {
            // alert("Adding track element")
            // New video track: create a stream out of it
            this.localVideos++;
            $('#videolocal .no-video-container').remove();
            stream = new MediaStream([_track]);
            this.localTracks[trackId] = stream;
            // alert("Created local stream:" + stream);
            // alert(stream.getTracks());
            // alert(stream.getVideoTracks());
            // alert("1");
            if (this.janusConnector) {
                // alert("2");
                if (this.janusConnector.elementToPutChildrenTo) {
                    // alert(3);
                    // alert($(this.janusConnector.elementToPutChildrenTo));
                    $(this.janusConnector.elementToPutChildrenTo).append('<video class="rounded centered my-track" id="myvideo' + trackId + '" width=100% autoplay playsinline muted="muted"/>');

                    this.JanusClass.attachMediaStream($('#myvideo' + trackId).get(0), stream);
                    // alert(2345678);
                }
            }
            // alert("4");
        }
        if(this.sfu.webrtcStuff.pc.iceConnectionState !== "completed" &&
            this.sfu.webrtcStuff.pc.iceConnectionState !== "connected") {
        }
    }

    onremotetrack(_track: any, _mid: any, _on: any, _janusInstance: any): void {

    }

    oncleanup(_janusInstance: any): void {
        alert("Проблемы со связью. Проверьте настройки браузера или антивирусного ПО.");
    }

    publishOwnFeed() {
        // We want sendonly audio and video (uncomment the data track
        // too if you want to publish via datachannels as well)
        let tracks = [];
        tracks.push({ type: 'audio', capture: { deviceId: { exact: this.janusConnector?.audioDevice } }, recv: false });
        tracks.push({ type: 'video', capture: { deviceId: { exact: this.janusConnector?.videoDevice } }, recv: false, simulcast: false });
        //~ tracks.push({ type: 'data' });
        let me = this;

        this.sfu.createOffer(
            {
                tracks: tracks,
                success: function(jsep: any) {
                    // alert("Got publisher SDP!");
                    // alert(jsep);
                    let publish = {
                        request: "configure",
                        audio: true,
                        video: true,
                        display_name: "Display"
                    };
                    me.sfu.send({ message: publish, jsep: jsep });
                },
                error: function(error: any) {
                    me.publishOwnFeed();
                }
            });
    }

    janusConnector: JanusConnector | null;

    subscribeTo(sources: any): void {
    }

    feedStreams: any;

    unsubscribeFrom(_id: any): void {
    }
}



