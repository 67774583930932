import React, {ChangeEvent} from "react";
import "./field_view.css";

import found_type_dictionary from "../../../dictionaries/found_type_dictionary.json";
import possible_input_types from "../../../dictionaries/possible_input_types.json";

import TranslatedText from "../../localization_components/translated_text/TranslatedText";
import Checkbox from "../../interactivity_components/checkbox/Checkbox";
import {IMaskInput} from 'react-imask';
import {RailsFieldProps} from "./interfaces/RailsFieldProps";
import AsyncSelect from "../../editors/async_select/AsyncSelect";
import Hint from "../../interactivity_components/hint/Hint";
import {Style} from "react-style-tag";
import {Md5} from 'ts-md5';
import FieldEditor from "../field_editors/FieldEditor";

interface RailsFieldState {
  inputMode: "text";
  value: any;
  valueIsChanged: any;
  loading: boolean;
  fileValue: any;
  showingEditor: boolean;
  blocked: boolean;
  error: string | null;
}

class RailsField extends React.Component<RailsFieldProps, RailsFieldState> {
  private imaskRef: React.RefObject<HTMLInputElement>;
  private fileuploadRef: React.RefObject<HTMLInputElement>;
  private inputRef: React.RefObject<any>;

  static restoreValueOfField(_value: any, _field: any) {
    if (_value === undefined || _value === null) return null;
    let foundTypeDictionary = {...found_type_dictionary} as any;
    let detectedFieldType = foundTypeDictionary[_field.type];
    let possibleInputTypes = {...possible_input_types} as any;
    let chosenInputType = possibleInputTypes[detectedFieldType];
    let value = null as any;

    if ((_value !== undefined) && (_value !== null)) {
      if ( (chosenInputType === "datetime-local")) {
        // alert(1);
        try {
          value = (new Date(_value)).toISOString().slice(0, -1);
        } catch (e) {
          console.error(`Попытка задать как дату значение ${_value}`);
        }
      } else {
        if ( (chosenInputType === "datetime-local") || (chosenInputType === "date")) {
          try {
            value = (new Date(_value)).toISOString().substring(0,10);
          } catch (e) {
            console.error(`Попытка задать как дату значение ${_value}`);
          }
        } else {
          value = _value;
          if (_field.type === "boolean" && !_field.boolean_variants) {
            value = (value === "true");
          }
        }
      }
    }

    return value;
  }

  constructor(_props: RailsFieldProps) {
    super(_props);
    // console.log(this.props);
    // console.log(_props.type);
    let foundTypeDictionary = {...found_type_dictionary} as any;
    let detectedFieldType = foundTypeDictionary[this.props.type];
    let possibleInputTypes = {...possible_input_types} as any;
    let chosenInputType = possibleInputTypes[detectedFieldType];

    let value = null;
    let valueToProcess = this.props.value;
    if ((valueToProcess === undefined) && this.props.enableCaching) {
      valueToProcess = localStorage.getItem(this.cacheName());
    }

    if (this.props.rawContent) {
      console.log(`${this.props.var_name} = ${this.props.rawContent[this.props.model]}`);
      let givenValue = this.props.rawContent[this.props.model][this.props.var_name];
      if (givenValue) {
        valueToProcess = givenValue;
        console.log(`Given value: ${valueToProcess}`);
      }
    }

    if ((valueToProcess !== undefined) && (valueToProcess !== null)) {
      if ( (chosenInputType === "datetime-local")) {
        // alert(1);
        try {
          value = (new Date(valueToProcess)).toISOString().slice(0, -1);
        } catch (e) {
          console.error(`Попытка задать как дату значение ${valueToProcess}`);
        }
      } else {
        if ( (chosenInputType === "datetime-local") || (chosenInputType === "date")) {
          try {
            value = (new Date(valueToProcess)).toISOString().substring(0,10);
          } catch (e) {
            console.error(`Попытка задать как дату значение ${valueToProcess}`);
          }
        } else {
          value = valueToProcess;
          if (this.props.type === "boolean" && !this.props.boolean_variants) {
            value = (value === "true");
          }
        }
      }
    }

    this.state = {
      valueIsChanged: false,
      inputMode: chosenInputType,
      loading: false,
      value: value,
      fileValue: null,
      showingEditor: false,
      blocked: this.props.blocked,
      error: null
    };

    this.imaskRef = React.createRef<HTMLInputElement>();
    this.fileuploadRef = React.createRef<HTMLInputElement>();
    this.triggerValue = this.triggerValue.bind(this);
    this.updateNewValue = this.updateNewValue.bind(this);
    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.sendFile = this.sendFile.bind(this);
    this.cacheName = this.cacheName.bind(this);
    this.triggerEditor = this.triggerEditor.bind(this);
    this.reactivateValue = this.reactivateValue.bind(this);


    this.uploadFile = this.uploadFile.bind(this);
    this.focus = this.focus.bind(this);

    this.getAfterAction = this.getAfterAction.bind(this);
    this.editorCallback = this.editorCallback.bind(this);
    this.onFileUploadChange = this.onFileUploadChange.bind(this);

    this.block = this.block.bind(this);
    this.unblock = this.unblock.bind(this);

    this.inputRef = React.createRef<any>();

  }

  block() {
    let prevState = {...this.state};
    prevState.blocked = true;
    this.setState(prevState);
  }

  unblock() {
    let prevState = {...this.state};
    prevState.blocked = false;
    this.setState(prevState);
  }

  onFileUploadChange(_e: React.ChangeEvent<HTMLInputElement>) {
    let me = this;
    if (me.props.auto_upload) {
      me.sendFile();
    }
    // if (me.props.upl)
  }

  editorCallback(_newProps: string) {
    // alert(_newProps);
    if (this.props.editorCallback) this.props.editorCallback(_newProps);
  }

  triggerEditor() {
    let prevState = {...this.state};
    prevState.showingEditor = !prevState.showingEditor;
    this.setState(prevState);
  }

  static cacheName(_obj: any) {
    return "form-cache-" + Md5.hashStr([
      JSON.stringify(_obj.id),
      JSON.stringify(_obj.model),
      JSON.stringify(_obj.var_name),
      JSON.stringify(_obj.type),
      JSON.stringify(_obj.belongs_to),
      JSON.stringify(_obj.href),
      JSON.stringify(_obj.imask),
      JSON.stringify(_obj.placeholder),
      JSON.stringify(_obj.hint),
      JSON.stringify(_obj.no_translation),
      JSON.stringify(_obj.disable_translation),
      JSON.stringify(_obj.showContent)
    ].join("-"));
  }

  cacheName() {
    return RailsField.cacheName(this.props);
  }

  focus() {
    let el = this.imaskRef.current;
    if (!el) {
      el = this.inputRef.current;
    }
    if (el) {el.focus();}
  }

  triggerValue() {
    this.updateNewValue(!this.state.value);
  }

  componentDidMount() {
    if (this.imaskRef && this.imaskRef.current) {
      let el = this.imaskRef.current;
      el.setAttribute("class", "field");
      el.setAttribute("type", this.props.type);
      if (this.props.placeholder)
        el.setAttribute("placeholder", this.props.placeholder);
    }

    // if (this.state.value && this.props.belongs_to) {}

  }

  reactivateValue() {
    let me = this;
    console.log(`reactivating ${this.props.var_name} with value '${me.state.value}' and in cache was '${localStorage.getItem(me.cacheName())}' and in props was '${me.props.value}'`);
    return new Promise((_resolve) => {
      if (me.state.value === undefined || me.state.value === null) {
        _resolve(true);
        return;
      }
      if (me.props.enableCaching && (me.state.value !== null) && (me.state.value !== undefined) && (localStorage.getItem(me.cacheName()) !== undefined)) {
        console.log(`${this.props.var_name} Caching enabled`);
        // this.on
        // if (this.props.onChange) {
        //   alert("reactivateValue");
        this.updateNewValue(me.state.value).then(() => {
          if (me.props.onChange) {
            console.log(`${this.props.var_name} Onchange callback`);
            let afterCommands = me.getAfterAction(me.state.value);
            if (afterCommands) {
              console.log(`AFTER ACTION: ${this.props.var_name} Executing after commands`);
              me.props.onChange(me.state.value, afterCommands);
            } else {
              console.log("NO AFTER ACTION");
            }
            console.log(`${this.props.var_name} Onchange done`);
          }
          _resolve(true);
        });
        // }
      } else {
        _resolve(true);
      }

    });
  }

  getAfterAction(_value: any) {
    let value = _value.toString();
    console.log(`GETTING AFTER ACTION FOR ${this.props.var_name} on value '${value}'`);
    if (this.props.reactions) {
      let reaction = this.props.reactions[value];
      console.log(this.props.reactions);
      return reaction;
    } else {
      console.log("NO REACTIONS");
    }
  }

  updateNewValue(_value: any) {
    let me = this;
    return new Promise((_resolve) => {
      let prevState = {...me.state};
      prevState.value = _value;
      prevState.valueIsChanged = true;

      if (me.props.enableCaching) {
        console.log("Setting in Cache");
        localStorage.setItem(me.cacheName(), _value.toString());
      }

      this.setState(prevState, () => {
        let afterCommands = me.getAfterAction(_value);
        if (afterCommands) {
          console.log(`There are afterCommands`);
          console.log(afterCommands);
          // alert(JSON.stringify(afterCommands));
        }
        if (me.props.onChange) {
          // if (afterCommands) {
          //   alert("On change");
          // }
          console.log("Onchange callback");
          me.props.onChange(prevState.value, afterCommands);
          console.log("Onchange done");
        }

        _resolve(prevState.value);
      });
    });
  }

  onTextAreaChange(_e: React.ChangeEvent<any>) {
    let target = _e.currentTarget;
    if (!target) return;
    let value = target.value;
    this.updateNewValue(value);
  }

  getValue() {
    if (Array.isArray(this.state.value))
      return this.state.value[0];
    return this.state.value;
  }

  saveValue() {
    let parsel = {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        "mode": 'cors'
      },
      body: JSON.stringify({
        id: this.props.id,
        model: this.props.model,
        value: this.state.value,
        field: this.props.var_name
      })
    };

    let me = this;
    let prevState = {...me.state};
    prevState.loading = true;
    me.setState(prevState, () => {
      me.props.api.asyncFetch(me.props.update_url,
        parsel).then((e: any) => {
        let prevState = {...me.state};
        prevState.loading = false;
        prevState.error = e.error;
        prevState.valueIsChanged = false;
        me.setState(prevState);
      });
    });
  }

  uploadFile() {
    let me = this;
    let uploadFileEl = this.fileuploadRef.current;
    if (uploadFileEl) {
      let listener = (_event: Event) => {
        if (!uploadFileEl) return;
        if (uploadFileEl.files && (uploadFileEl.files.length > 0)) {
          let prevState = {...me.state};
          prevState.fileValue = uploadFileEl.files;
          // alert(uploadFileEl.files);
          // prevState.fileValue.map((_x: any) => {
          //   alert(_x);
          // });
          me.setState(prevState);
        }

      };
      // uploadFileEl.removeEventListener("change", listener);
      uploadFileEl.addEventListener("change", listener);
      uploadFileEl.click();
    }
  }

  sendFile() {
    let me = this;
    if (!me.state.fileValue) {
      console.error("No file value provided");
      return;
    }
    let fileData = new FormData();
    for(let fileObjIndex = 0; fileObjIndex < me.state.fileValue.length; fileObjIndex++ ) {
      fileData.append(`file_url[${fileObjIndex}]`, me.state.fileValue[fileObjIndex]);
      fileData.append("var_name", me.props.var_name);
    }
    let prevState = {...me.state};
    prevState.loading = true;
    me.setState(prevState, () => {
      if (me.props.href) {
        me.props.api.asyncFetch(me.props.href, {
          method: "POST",
          body: fileData
        }).then((_e: any) => {
          let prevState = {...me.state};
          prevState.value = _e.url;
          prevState.fileValue = null;
          prevState.loading = false;
          me.setState(prevState, () => {
            me.updateNewValue(_e.url);
          });
        });
      } else {
        console.error("No href data provided");
      }
    });
  }

  stringToRegex(str: string) {
    // @ts-ignore
    const main = str.match(/\/(.+)\/.*/)[1]

    // @ts-ignore
    const options = str.match(/\/.+\/(.*)/)[1]

    return new RegExp(main, options)
  }

  render() {
    let me = this;
    let customInputStyle = {};
    if (me.props.inputElementStyle) customInputStyle = me.props.inputElementStyle;
    let usedImask = null as any;
    if (me.props.imask) {
      if (me.props.imask.startsWith("/") && me.props.imask.endsWith("$/")) {
        // alert(me.props.imask.slice(1, me.props.imask.length - 1));
        usedImask = me.stringToRegex(me.props.imask);//new MaskedRegExp({mask: new RegExp(me.props.imask.slice(1, me.props.imask.length - 1), "i")});
      } else {
        usedImask = me.props.imask;
      }
    }
    let developerModeStyle = me.props.developerMode ? {
      position: "relative"
    } as any : {};
    if (me.props.disabled) {
      developerModeStyle.pointerEvents = "none";
    }

    if (me.state.blocked) {
      developerModeStyle.pointerEvents = "none";
    }

    return <div className={"rails-main-container"} style={developerModeStyle as any}>
      { me.props.developerMode &&
          <i className={"fal fa-edit"} style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "#fff",
            padding: "8px",
            borderRadius: "50%",
            zIndex: 10,
            boxShadow: "3px 3px 6px #000"
          }}
             onClick={me.triggerEditor}
          ></i>
      }

      { me.props.developerMode &&
        <FieldEditor  field={me}
                      showing={me.state.showingEditor}
                      saveAction={me.editorCallback}
                      closeAction={me.triggerEditor}/>
      }
      <div key={me.state.inputMode}
                style={me.props.customContainerStyle ? me.props.customContainerStyle : {position: "relative"}}
                className={`rails-field ${me.props.customContainerClassName}`}>
      {/*<p>{!!me.state.value ? 1 : 2}{me.state.value}</p>*/}

      { (me.props.showContent) && <p>{Object.keys(me.props)}</p> }
      { me.props.css && <Style>{me.props.css}</Style> }
      <span className={"rails-field-label"}>
        { (typeof me.props.title === 'string') &&
            <TranslatedText i18nUrl={"/polymorphic_flow/i18n"}
                            api={me.props.api}
                            disableTranslation={me.props.disable_translation}
                            content={`${me.props.model}.${me.props.title}`}
                            fallback={me.props.no_translation ? (me.props.placeholder ? me.props.placeholder : me.props.title) : me.props.title}
            />
        }
      </span>
      {/*<span className={"h-spacer-1"}></span>*/}
      { !me.props.belongs_to && (me.props.type !== "file") &&
          <>
            { me.props.imask &&
                <IMaskInput
                    inputRef={me.imaskRef}
                    mask={usedImask}
                    radix="."
                    value={me.state.value}
                    onComplete={(value, mask) => { me.updateNewValue(value);}}
                />
            }
            { !me.props.imask && me.props.type === "text" &&
                <textarea
                  onChange={(_e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    me.onTextAreaChange(_e);
                  }}
                  ref={me.inputRef}
                  rows={1}
                  className={"field"}
                  value={me.state.value}
                  style={me.props.immutable ? {...customInputStyle, pointerEvents: "none", opacity: "0.4"} : customInputStyle}
                />
            }

            { !me.props.imask && (me.props.type !== "boolean") && (me.props.type !== "text") &&
                <input type={me.state.inputMode}
                       onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                         me.onTextAreaChange(_e);
                       }}
                       ref={me.inputRef}
                       style={me.props.immutable ? {...customInputStyle, pointerEvents: "none", opacity: "0.4"} : customInputStyle}
                       className={"field"}
                       name={me.props.var_name}
                       placeholder={me.props.placeholder}
                       value={me.state.value}
                       key={me.state.inputMode}
                       accept={me.props.accept}
                />
            }

            { (me.props.type === "boolean") &&
                <Checkbox value={me.state.value}
                          title={me.props.title}
                          boolean_variants={me.props.boolean_variants}
                          onChange={me.updateNewValue} />
            }
          </>
      }

      {
        me.props.belongs_to && (me.props.type !== "file") &&
          <AsyncSelect api={me.props.api}
                       ref={me.inputRef}
                       value={me.state.value}
                       starting_data={me.props.starting_data}
                       disableUpdate={me.props.disableUpdate}
                       use={me.props.use}
                       sourceOfData={me.props.sourceOfData}
                       url={`${me.props.href}`}
                       placeholder={me.props.placeholder ? me.props.placeholder : ""}
                       onChange={(_newValue: any) => { me.updateNewValue(_newValue);}}
                       angleDownElement={<i className="fal fa-angle-down" style={{position: "absolute", right: "10px", top: "20%"}}></i>} />
      }


      { me.state.valueIsChanged && !me.props.disableSaveButton &&
          <div style={me.state.loading ? {opacity: 0.4, pointerEvents: "none", boxShadow: "1px 1px 3px #b8b9be, -1px -1px 1px #fff"} : {} }  onClick={me.saveValue} className={"save-button"}>
            { !me.state.loading &&
                <i className={"fal fa-save"}></i>
            }

            { me.state.loading &&
                <i style={{
                  opacity: me.state.value ? 1 : 0.1,
                  margin: "0",
                  fontSize: "var(--font-size-4)",
                  color: "#000"
                }} className={"far fa-cloud-upload loaded-text"} />
            }
          </div>
      }

      {
        (me.props.type === "file") &&
          <div className={"file-upload-container"}>
            { !me.state.fileValue &&
              <span onClick={me.uploadFile}><u>{me.state.value ? "Выбрать новый файл" : "Выбрать файл"}</u></span>
            }
            <>
              { me.state.fileValue &&
                  <div>
                    { Array.from(me.state.fileValue).map((_fileValue: any, _fileIndex: number) => {
                      return <div key={`uploaded-file-${_fileIndex}`} className={"flex flex-row"}>
                        <p>{_fileValue.name}</p>
                      </div>;
                    })
                    }
                    <i onClick={me.sendFile} style={me.state.loading ? {
                      cursor: "none",
                      opacity: 0.5,
                      pointerEvents: "none"
                    } : {cursor: "pointer"}} className={"fas fa-cloud-upload"}></i>
                  </div>
              }
            </>
            <input style={{display: "none"}}
                   ref={me.fileuploadRef}
                   type={"file"}
                   accept={me.props.accept}
                   multiple={!!me.props.multiple}
                   onChange={me.onFileUploadChange}
            />
            { me.props.show_previews &&
                <div className={"previews"}>
                  {
                    me.state.value && (me.state.value.indexOf(";") === -1) &&
                      <a key={`preview`}
                         href={me.state.value}
                         target={"_blank"}>
                        <img className={"preview"}
                             src={me.state.value} />
                      </a>
                  }

                  {
                    me.state.value && (me.state.value.indexOf(";") > -1) &&
                    me.state.value.split(";").map((_view: any, _previewIndex: number) => {
                      return <a key={`preview-${_previewIndex}`}
                                href={_view}
                                target={"_blank"}>
                        <img className={"preview"}
                                  src={_view}/>
                      </a>;
                    })
                  }
                </div>
            }
          </div>
      }
      { me.props.hint &&
        <Hint style={{position: "absolute", top: (window.innerWidth < 600) ? "50%" : "unset", right: "10px"}}>
          <div style={{
            background: "var(--black)",
            color: "var(--white)",
            padding: "10px",
            borderRadius: "10px"}}>{me.props.hint}</div>
        </Hint>
      }
    </div>
    { me.state.error && <p style={{fontWeight: "600", marginTop: 0, marginBottom: "10px", color: "#f00", marginLeft: "auto", maxWidth: "300px"}}>{me.state.error}</p>}
      {/*<p>||{me.state.value}||{JSON.stringify(me.props.rawContent)}</p>*/}
  </div>
  }
}

export {RailsField};