import React from "react";
import "./names-chart-style.scss";
import NameGetter from "./NameGetter";

interface NamesChartProps {
  votes: any[];
  variants: any[];
  participants: any[];
  index: number;
  is_rating?: boolean;
}

interface NamesChartState {
  participants: any[];
}

export default class NamesChart extends React.Component<NamesChartProps, NamesChartState> {

  constructor(_props: NamesChartProps) {
    super(_props);
    this.state = {
      participants: []
    }

    this.getParticipantsNames = this.getParticipantsNames.bind(this);

  }

  async componentDidMount() {
    let me = this;
    let prevState = {...me.state};
    prevState.participants = me.getParticipantsNames();
    me.setState(prevState, () => {
    });
  }

  getParticipantsNames() {
    let me = this;
    let participants = me.props.participants.map((_x,_partIndex) => {
      return {
        id: _x,
        name: _x
      };
    })
    // alert(participants);
    return participants;
  }



  render() {
    let me = this;

    return <table key={JSON.stringify(me.state.participants)} className={"names-chart"}>
      <tbody>
        {/*<tr style={{fontWeight: "600"}}>*/}
        {/*  <td></td>*/}
        {/*  <td></td>*/}
        {/*  { me.props.variants.map((_var: any, _varIndex: number) => {*/}
        {/*      return <td key={`var-${_varIndex}`}>{_var.content}</td>;*/}
        {/*    })*/}
        {/*  }*/}
        {/*</tr>*/}

        { !me.props.is_rating &&
          <>
            <tr style={{fontWeight: 900}}>
                <td>№</td>
                <td>Фамилия Имя Отчество</td>
                <td  colSpan={33}>Голос</td>
            </tr>
            {
              me.state.participants.map((_participant: any, _voteIndex: number) => {
                let _participantId = _participant.id;
                return <tr>
                  <td>{_voteIndex + 1}</td>
                  <td style={{textAlign: "left"}}>
                    <NameGetter id={_participantId}/>
                  </td>

                  {me.props.variants.map((_var: any, _varIndex: number) => {
                    let isThereVotes = me.props.votes.filter((_x: any) => {
                      console.log(`${_x.user_id} -- ${_participantId}`)
                      // alert(JSON.stringify(_x));
                      let userOk = (_x.user_id === _participantId);
                      let variantOk = (_x.content.indexOf(`candidateId":${_var.id},`) > -1);
                      return (userOk) && variantOk;
                    });
                    if (isThereVotes.length === 0) {
                      return <></>;
                    }

                    return <td key={`var-${_varIndex}`}>
                      {/*{_var.content}*/}
                      {/*<p>{isThereVotes.length}</p>*/}
                      {isThereVotes.length > 0 &&
                          <div style={{margin: "auto"}}>
                            {/*<i className={"fal fa-check fa-2x"}></i>*/}
                            {_var.content}
                            {/*{JSON.stringify(_var)}*/}
                          </div>
                      }
                    </td>;
                  })
                  }
                </tr>;
              })
            }
          </>
        }

        { !!me.props.is_rating &&
            <>
              <tr style={{fontWeight: 900}}>
                  <td>№</td>
                  <td>Вариант</td>
                  <td  colSpan={33}>Проголосовавшие</td>
              </tr>
              {
                me.props.variants.map((_var: any, _varIndex: number) => {
                  let participants = me.state.participants.filter((_participant) => {
                    let votes = me.props.votes.filter((_vote) => {
                      let userOk = (_vote.user_id === _participant.id);
                      // alert(JSON.stringify(_vote));
                      let variantOk = (_vote.content.indexOf(`candidateId":${_var.id}`) > -1);
                      return userOk && variantOk;
                    });
                    return votes.length > 0;
                  });
                  return <tr>
                    <td>{_varIndex + 1}</td>
                    <td>{_var.content}</td>
                    <td>
                      {
                        participants.map((_participant, _partIndex) => {
                          return <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: 'flex-start',
                                    alignContent: "flex-start"
                          }}>
                            <NameGetter id={_participant.id}/>
                          </div>;
                        })
                      }
                    </td>
                  </tr>;
                  })
                }
                </>
              }
      </tbody>
    </table>
  }
}