import React from "react";
import Participant from "../../../models/participants/Participant";
import {ApiEngine} from "api-engine";

interface ChatProps {
    api: ApiEngine;
    myself: Participant;
    conferenceId: number;
}

interface ChatState {
    messages: any[];
    messageContent: string;
    generation: number;
    sending: boolean;
}

export default class Chat extends React.Component<ChatProps, ChatState> {
    private messagesRef: React.RefObject<HTMLDivElement>;
    fastSending: boolean;
    initiated: boolean;

    constructor(_props: ChatProps) {
        super(_props);

        this.state = {
            messages: [],
            messageContent: "",
            generation: 0,
            sending: false
        };

        this.getMessages = this.getMessages.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.setMessageContent = this.setMessageContent.bind(this);
        this.connectWs = this.connectWs.bind(this);

        this.fastSending = false;
        this.initiated = false;

        this.messagesRef = React.createRef<HTMLDivElement>();

        this.getMessagesViaHttpGet = this.getMessagesViaHttpGet.bind(this);
    }

    componentDidMount() {
        if (this.initiated) return;
        this.initiated = true;
        this.getMessagesViaHttpGet();
    }

    getMessagesViaHttpGet() {
        let me = this;
        me.getMessages().then(me.connectWs);
    }

    connectWs() {
        let me = this;
        if (!me.props.api.websocketConnector.alive) {
            setTimeout(me.connectWs, 3000);
            return;
        }
        me.props.api.websocketConnector.sendMessage(JSON.stringify({
            "title": "send_message"
        }));

        document.addEventListener("Messaging", (_ev: any) => {
            let prevState = {...me.state};
            if (_ev.detail.title === "RESTART") {
                // alert(123);
                me.getMessagesViaHttpGet();
                return;
            }
            let _msg = JSON.parse(_ev.detail.content);
            // alert(_msg.user);
            // alert(JSON.stringify(_ev.detail.content));
            prevState.generation += 1;

            // prevState.messages.unshift(_msg);
            prevState.messages.push(_msg);
            console.log(_msg);
            me.setState(prevState, () => {
                let current = me.messagesRef.current;
                if (current) {
                    current.scrollTo(0, current.scrollHeight);
                }
            });
        });
        // me.props.api.sendUsingWebSocket(JSON.stringify({
        //     "title": "send_message"
        // }));
    }

    getMessages() {
        let me = this;
        let url = `/api/messages?conference_id=${me.props.conferenceId}&code=31fw23212e123213121`;
        return new Promise((_resolve) => {
            me.props.api.asyncFetch(url, {}).then((_res: any) => {
                let prevState = {...me.state};
                prevState.messages = _res as any[];
                prevState.messages =  prevState.messages.reverse().filter((_x: any) => {return _x.user;} );
                me.setState(prevState, () => {
                    let current = me.messagesRef.current;
                    if (current) {
                        current.scrollTo(0, current.scrollHeight);
                    }
                    _resolve(true);
                    // setTimeout(me.getMessages, 3000);
                });
                return;
                // setTimeout(me.getMessages, 3000);
            });
        });
    }

    setMessageContent(_e: React.ChangeEvent<HTMLInputElement>) {
        let prevState = {...this.state};
        prevState.messageContent = _e.target.value;
        this.setState(prevState);
    }

    sendMessage() {
        let me = this;
        if (me.fastSending) return;
        me.fastSending = true;
        let prevState = {...me.state};
        prevState.sending = true;
        me.setState(prevState, () => {
            if (me.props.api.websocketConnector.sendMessage(JSON.stringify({title: "send_message", content: me.state.messageContent}))) {
                let prevState = {...me.state};
                prevState.messageContent = "";
                prevState.sending = false;
                me.fastSending = false;
                me.setState(prevState);
            } else {
                me.fastSending = false;
                setTimeout(me.sendMessage, 1000);
            }
        });
        // let url = new URL(`${me.props.api.serverUrl}/messages/create?user_id=${me.props.myself.id}&conference_id=${me.props.conferenceId}&content=${encodeURI(me.state.messageContent)}`);
        // me.props.api.getUrl(url, {method: "post",
        //         // content: me.state.messageContent,
        //         // user_id: me.props.myself.id,
        //         // conference_id: me.props.conferenceId
        // }).then((_res) => {
        //     // alert(_res);
        // });

    }

    render() {
        let me = this;
        return <div className={"card chat"} style={{marginTop: "14px", height: "100%"}}>
            <h4>Обращение к председателю</h4>
            <div ref={me.messagesRef}
                 key={`${JSON.stringify(me.state.messages)}${me.state.generation}`}
                 style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                    maxHeight: "calc(25vh - 32px - 60px)"
                }}>
                { me.state.messages.map((_msg: any, _msgIndex: number) => {
                      return <div key={`msg-${_msgIndex}-${_msg.id}`} style={{
                          width: "40%",
                          position: "relative",
                          height: "fit-content",
                          padding: "6px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: (_msg.user_id === me.props.myself.id) ? "auto" : "10px",
                          marginRight: (_msg.user_id === me.props.myself.id) ? "10px" : "auto",
                          background: (_msg.user_id === me.props.myself.id) ? "var(--azure_a100)" : "var(--gray_100)",
                          border: (_msg.user_id === me.props.myself.id) ? "2px solid var(--blue_400)" : "2px solid var(--gray_200)",
                          color: (_msg.user_id === me.props.myself.id) ? "var(--white)" : "var(--black)"
                      }} >
                          <span style={{
                              position: "absolute",
                              top: "3px",
                              left: "5px",
                              fontSize: "10px",
                              whiteSpace: "nowrap"
                          }}>{_msg.user.last_name} {_msg.user.first_name} {_msg.user.middle_name}</span>
                          <span style={{
                              textAlign: "left",
                              marginTop: "14px",
                              display: "flex",
                              fontSize: "12px",
                              width: "100%",
                              wordBreak: "break-all"
                          }}>{_msg.content}</span>
                      </div>;
                  })
                }
            </div>
            <div style={{marginTop: "auto",
                padding: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                background: "var(--gray_200)",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderRadius: "0 0 7px 7px "
            }}>
                <input placeholder={"Ваше сообщение"}
                       style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            padding: "10px",
                            marginRight: "15px"
                       }}
                       onKeyDown={(_ev: React.KeyboardEvent<HTMLInputElement>) => {
                           if (_ev.code === 'Enter') me.sendMessage();
                       }}
                       value={me.state.messageContent}
                       onChange={me.setMessageContent}
                />
                <button
                  onClick={me.sendMessage}
                  style={me.state.messageContent.length > 0 ? {cursor: "pointer"} : {cursor: "pointer", opacity: 0.5, pointerEvents: "none"}}>
                    <i style={{borderRadius: "10px",
                        border: "none",
                        padding: "10px",}} className={"fal fa-paper-plane"}></i>
                </button>
            </div>
        </div>;
    }
}