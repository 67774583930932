import React from "react";
import ApiEngine from "../../../gateways/temp_api/gateway/ApiEngine";
import SessionContainer from "../../../gateways/temp_api/session/SessionContainer";
import Participant from "../../models/participants/Participant";
import ChartsUseCases from "../../charts/ChartsUseCases";
import "./voting.scss";

interface VotingProps {
  content: any;
  myself: Participant;
  parent: React.RefObject<HTMLDivElement>;
}

interface VotingState {
  data: any;
  status: any;
  sendingVotes: boolean;
  voteUploadStages: string[];
  allVotingVariants: string[];
  showNames: boolean;
  dictionary: any;
}

export default class Voting extends React.Component<VotingProps, VotingState> {
  private api: ApiEngine;
  private meRef: React.RefObject<HTMLDivElement>;

  constructor(_props: VotingProps) {
    super(_props);

    this.state = {
      data: {},
      dictionary: {},
      status: {},
      sendingVotes: false,
      voteUploadStages: [],
      allVotingVariants: [],
      showNames: false
    }

    this.getAllVotingVariants = this.getAllVotingVariants.bind(this);

    this.sendVote = this.sendVote.bind(this);
    this.sendVotes = this.sendVotes.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.unSelectAll = this.unSelectAll.bind(this);
    this.goUp = this.goUp.bind(this);

    this.api = new ApiEngine("https://voteer.ru", 0, new SessionContainer<any>(String, "/"));
    this.api.startQueue();

    this.meRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.getAllVotingVariants();
  }

  getAllVotingVariants() {
    let me = this;
    let variants = [] as string[];
    let showNames = true;
    let _dict = {} as any;
    for(let _voting of me.props.content) {
      showNames = showNames && _voting.show_names;
      for(let _variant of _voting.vote_variants) {
        _dict[`${_variant.voteer_id}`] = _variant.name;
        if (variants.indexOf(_variant.name) === -1)
          variants.push(_variant.name);
      }
    }

    me.setState({
      dictionary: _dict,
      allVotingVariants: variants,
      showNames: showNames
    });

  }

  sendVote(_okrugCode: string) {
    let me = this;

    return new Promise((_resolve) => {

      let voting = me.props.content.filter((_v: any) => {
          return _v.vote_code === _okrugCode;
      })[0];

      if (!voting) {
        alert("Не обнаружено голосование");
        return;
      }

      let voteVariant = voting.vote_variants.filter((_x: any) => {
        return _x.voteer_id === me.state.data[_okrugCode];
      })[0];

      if (!voteVariant) {
        alert("No vote variant");
        return;
      }

      let chosen = [me.state.data[_okrugCode]].map((_x) => {
        return {
          candidateId: _x,
          title: voteVariant.name
        };
      });
      // alert(JSON.stringify(me.state.data));

      let url = `/users/upload_vote`;
      let data = {
        method: "post",
        body: JSON.stringify({
          vote: {
            content: chosen,
            okrug_code: voting.vote_code
          },
          user_id: me.props.myself.id
        })
      };

      let status = {...me.state.status};
      status[_okrugCode] = "Отправляется";
      me.setState({
        status: status
      }, () => {
        me.api.asyncFetch(url, data).then((res: any) => {
          let prevState = {...me.state};
          me.setState(prevState, () => {
            let status = {...me.state.status};
            status[_okrugCode] = res.status;
            me.setState({
              status: status
            }, () => {
              _resolve(true);
            });
          });
        }, () => {
          setTimeout(() => {
            me.sendVote(_okrugCode).then(() => {
              _resolve(true);
            })
          }, 2000);
        });
      })
    });
  }

  goUp() {
    // this.meRef.current?.scrollTo(0,0);
    this.props.parent.current?.scrollTo(0,0);
  }

  async sendVotes() {
    let me = this;
    me.setState({
      sendingVotes: true,
      voteUploadStages: ["encrypting"]
    }, () => {
      me.meRef.current?.scrollTo(0,0);
      setTimeout(() => {
        me.setState({
          voteUploadStages: ["encrypting", "signing"]
        }, () => {
          setTimeout( () => {
            me.setState({
              voteUploadStages: ["encrypting", "signing", "sending"]
            }, async () => {
            for (let key of Object.keys(me.state.data)) {
                // alert(key);
                await me.sendVote(key);
            }
          });}, 1400);
        });
      }, 500);
    })
    // alert("Done");
  }

  unSelectAll(_masterVariant: string) {
    if (_masterVariant !== "ЗА") return;
    let me = this;
    let _newData = {...me.state.data} as any;
    for(let _voting of me.props.content) {
      for(let _variant of _voting.vote_variants) {
        if (_variant.name === _masterVariant) {
          delete _newData[`${_voting.vote_code}`];
          break;
        }
      }
    }
    me.setState({data: _newData});
  }

  selectAll(_masterVariant: string) {
    if (_masterVariant !== "ЗА") return;
    let me = this;
    let _newData = {...me.state.data} as any;
    for(let _voting of me.props.content) {
      for(let _variant of _voting.vote_variants) {
        if (_variant.name === _masterVariant) {
          _newData[`${_voting.vote_code}`] = _variant.voteer_id;
          break;
        }
      }
    }
    me.setState({data: _newData});
  }

  render() {
    let me = this;

    // alert(me.props.myself.role);
    const acceptedRoles = ["Выступающий", "Участник"];
    if (!me.props.myself.role || (acceptedRoles.indexOf(me.props.myself.role) === -1)) {
      return <p>У вас нет полномочий голосовать на этой встрече</p>
    }

    return <div
      className={"votings"}
      ref={me.meRef}
    >
      <h2 style={{margin: 0}}>{me.props.content.name}</h2>
      {
        (Object.keys(me.state.data).length === me.props.content.length) &&
        Object.keys(me.state.data).map((_x: string) => {
          return me.state.dictionary[`${me.state.data[_x]}`];
        }).every((_x: string) => {return _x.toLowerCase() === "за"}) &&
          <button
              style={{
                cursor: "pointer",
                background: "#fff",
                marginBottom: "20px",
                marginRight: "20px",
                fontSize: "16px",
                fontWeight: "600",
                padding: "10px",
                border: "2px solid var(--color-main)",
                color: "var(--color-main)"
              }}
              onClick={() => {
                me.unSelectAll("ЗА");
              }}>Отменить выбор «За» по всем вопросам повестки</button>
      }
      { ((Object.keys(me.state.data).length !== me.props.content.length) || !(Object.keys(me.state.data).map((_x: string) => {
          return me.state.dictionary[`${me.state.data[_x]}`];
        }).every((_x: string) => {return _x.toLowerCase() === "за"})))
        && me.props.content && !me.props.content[0].showing_results && !me.state.sendingVotes && me.state.allVotingVariants.indexOf("ЗА") > -1 &&
          <button
              style={{
                cursor: "pointer",
                background: "#fff",
                marginBottom: "20px",
                marginRight: "20px",
                fontSize: "16px",
                fontWeight: "600",
                padding: "10px",
                border: "2px solid var(--color-main)",
                color: "var(--color-main)"
              }}
              onClick={() => {
                me.selectAll("ЗА");
              }}>Проголосовать «За» по всем вопросам повестки</button>
      }
      {
        Object.keys(me.state.data).length === me.props.content.length &&
          <button
              onClick={me.sendVotes}
              style={{
                marginBottom: "20px",
                cursor: "pointer",
                fontSize: "22px",
                padding: "10px",
                background: "var(--color-main)",
                color: "#fff",
                opacity: me.state.sendingVotes ? 0.5 : 1,
                pointerEvents: me.state.sendingVotes ? 'none' : "all"
              }}
          >Отправить электронны{me.props.content.length > 1 ? "е" : "й"} бюллетен{me.props.content.length > 1 ? "и" : "ь"}</button>
      }

      <div style={{position: "relative"}}>
        { !me.state.sendingVotes &&
            <table border={1}>
                <thead>
                <tr>
                    <th style={{
                      fontSize: "10px",
                      position: "sticky",
                      background: "#ededed",
                      boxShadow: "3px 3px 8px #555",
                      top: 0
                    }}>№
                    </th>
                  {me.props.content[0] &&
                      <th style={{
                        minWidth: "320px",
                        fontSize: "10px",
                        position: "sticky",
                        background: "#ededed",
                        boxShadow: "3px 3px 8px #555",
                        top: 0
                      }}>Вопрос</th>
                  }
                  {me.state.allVotingVariants.map((_var: string, _varIndex: number) => {
                    return <th align={"center"}
                               style={{
                                 minWidth: "90px",
                                 fontSize: "10px",
                                 position: "sticky",
                                 background: "#ededed",
                                 boxShadow: "3px 3px 8px #555",
                                 top: 0
                               }}
                               key={`key-${_varIndex}`}
                    >{_var}</th>
                  })
                  }
                </tr>
                </thead>
                <tbody>
                {
                  me.props.content.map((_voting: any, _votingIndex: number) => {
                    if (_voting.showing_results) {
                      return <ChartsUseCases
                        number={_votingIndex + 1}
                        possibleVariants={me.state.allVotingVariants}
                        title={_voting.name}
                        tableTrMode={true}
                        votingId={_voting.vote_code}/>
                    }

                    return <tr key={`voting-${_votingIndex}`}>
                      <td>{_votingIndex + 1}</td>
                      <td style={{whiteSpace: "pre-wrap"}}>{_voting.name}</td>
                      {
                        me.state.allVotingVariants.map((_varinantNameCandidate: string, _varIndex: number) => {
                          let _variant = _voting.vote_variants.filter((_v_v: any) => {
                            return _v_v.name === _varinantNameCandidate;
                          })[0];
                          if (!_variant) {
                            return <td></td>;
                          }
                          return <td align={"center"}>
                            <div style={{
                              display: "flex",
                              width: "40px",
                              height: "40px",
                              flexDirection: "row",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "10px",
                              border: "2px solid #858585",
                              cursor: "pointer",
                              margin: 0,
                              borderRadius: "10px"
                            }}
                                 onClick={() => {
                                   let oldData = {...me.state.data};
                                   oldData[`${_voting.vote_code}`] = _variant.voteer_id;
                                   me.setState({data: oldData});
                                 }}
                            >
                              {(me.state.data[`${_voting.vote_code}`] === _variant.voteer_id) &&
                                  <i style={{margin: 0, marginLeft: "auto", marginRight: "auto", color: "var(--red)"}}
                                     className={"fas fa-check fa-2x"}></i>
                              }
                            </div>
                          </td>
                        })
                      }
                    </tr>
                  })
                }
                </tbody>

            </table>
        }

        { me.props.content.length > 2 &&
            <button
                onClick={me.goUp}
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  cursor: "pointer",
                  fontSize: "22px",
                  padding: "10px",
                  color: "var(--color-main)",
                  border: "1px solid var(--color-main)",
                  backgroundColor: "#fff",
                  opacity: me.state.sendingVotes ? 0.5 : 1,
                  pointerEvents: me.state.sendingVotes ? 'none' : "all"
                }}
            >Наверх</button>
        }
      </div>

      {
        me.state.sendingVotes &&
        <div style={{ position: "absolute",
                      overflow: "visible",
                      maxHeight: "100%",
                      top: 0,
                      padding: "20px",
                      paddingTop: 0,
                      paddingBottom: 0,
                      left: "0",
                      width: "100%",
                      height: "100%",
                      background: "rgba(255,255,255,1)"
        }}>
          { me.state.voteUploadStages.indexOf("encrypting") > -1 &&
              <li id="encrypting" className={"bulletin-status mb-2"}>
                  <i className={"fad fa-shield fa-3x"}></i>
                  <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Шифрую бюллетен{me.props.content.length === 1 ? "ь" : "и"}</p>
              </li>
          }
          { me.state.voteUploadStages.indexOf("signing") > -1 &&
              <li id="signing" className={"bulletin-status mb-2"}>
                  <i className={"fad fa-file-signature fa-3x"}></i>
                  <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Подписываю бюллетен{me.props.content.length === 1 ? "ь" : "и"}</p>
              </li>
          }
          { me.state.voteUploadStages.indexOf("sending") > -1 &&
              <li id="sending" className={"bulletin-status mb-2"}>
                  <i className={"fad fa-cloud-upload-alt fa-3x"}></i>
                  <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Отправляю бюллетен{me.props.content.length === 1 ? "ь" : "и"}</p>
              </li>
          }
          {/*<li id="vote-status" className={"bulletin-status mb-2"}>*/}
          {/*    <i className={"fal fa-circle-notch fa-3x fa-spin"}></i>*/}
          {/*    <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>{me.state.voteStatus}</p>*/}
          {/*</li>*/}
          {/*{ me.state.voteUploadStages.indexOf("verified") > -1 &&*/}
          {/*<p>{me.props.content.length}</p>*/}
          {/*<p>{Object.keys(me.state.status).length}</p>*/}
            { (me.state.voteUploadStages.indexOf("sending") > -1) &&
              <li style={{color: "#333"}} key={`${Object.keys(me.state.status).length}-${me.props.content.length}`} id="verified" className={"bulletin-status mb-2"}>
                    <i className={"fad fa-check fa-3x"}></i>
                    <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Отправлено бюллетеней: {Object.keys(me.state.status).length}. Из них принято: {Object.keys(me.state.status).filter((_x: any) => {  return (me.state.status[_x] === "Голос принят")} ).length}</p>
              </li>
            }
          {/*}*/}
          {/*{*/}
          {/*  me.props.content.map((_voting: any, _votingIndex: number) => {*/}
          {/*    if (me.state.status[_voting.vote_code])*/}
          {/*      return <div>*/}
          {/*        <p>{_voting.name}</p>*/}
          {/*        <p style={{fontSize: "10px", width: "320px"}}>{me.state.status[_voting.vote_code]}</p>*/}
          {/*      </div>;*/}
          {/*    else*/}
          {/*      return <p>{_voting.name}</p>;*/}
          {/*  })*/}
          {/*}*/}


        </div>
      }

      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  }
}