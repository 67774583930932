import React from 'react';
import './App.css';
import ConferenceView from "./components/ConferenceView";
import JanusConnector from "./gateways/janus/JanusConnector";
import Participant from "./components/models/participants/Participant";
import {ApiEngine} from "api-engine";

interface ConfAppProps {
    videogridJanusConnectors: JanusConnector[];
    conferenceId: number;
    serverURL: string;
    api: ApiEngine;
    myself: Participant;
    logo?: JSX.Element | null;
    blockMobile: boolean;
}

export default class ConfApp extends React.Component<ConfAppProps, any> {

    constructor(_props: any) {
        super(_props);
    }

    render() {
        let me = this;
        return <ConferenceView    myself={me.props.myself}
                                  api={me.props.api}
                                  logo={me.props.logo}
                                  videogridJanusConnectors={me.props.videogridJanusConnectors}
                                  serverUrl={me.props.serverURL}
                                  conferenceId={me.props.conferenceId}
                                  blockMobile={me.props.blockMobile}
        />;
    }
}

