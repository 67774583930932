import * as React from "react";
import "./hint.css";

interface HintProps {
  style?: any;
  children: JSX.Element | JSX.Element[];
  hintTriggerElement?: JSX.Element | JSX.Element[];
}

interface HintState {
  showingHint: boolean;
  mouseInside: boolean;
}

export default class Hint extends React.Component<HintProps, HintState> {
  constructor(_props: HintProps) {
    super(_props);
    this.state = {
      showingHint: false,
      mouseInside: false
    };
    this.showHint = this.showHint.bind(this);
    this.hideHint = this.hideHint.bind(this);
    this.triggerHint = this.triggerHint.bind(this);
  }

  showHint() {
    let prevState = {...this.state};
    prevState.showingHint = true;
    prevState.mouseInside = true;
    this.setState(prevState);
  }

  hideHint() {
    let prevState = {...this.state};
    prevState.showingHint = false;
    prevState.mouseInside = false;
    this.setState(prevState);
  }

  triggerHint(_e: React.MouseEvent<HTMLDivElement>) {
    _e.stopPropagation();
    let me = this;
    let prevState = {...me.state};
    prevState.showingHint = !prevState.showingHint;
    me.setState(prevState, () => {
      // setTimeout(me.hideHint, 23000);
    });
  }

  render() {
    let me = this;
    return <div onMouseEnter={me.showHint}
                onMouseLeave={me.hideHint}
                onClick={(_e: React.MouseEvent<HTMLDivElement>) => { me.triggerHint(_e)}}
                className={"hint"} style={me.props.style}>
      {!me.props.hintTriggerElement &&
          <i className={"fas fa-info-circle"}></i>
      }
      {
        !!me.props.hintTriggerElement && me.props.hintTriggerElement
      }
      <div style={me.state.showingHint ? {display: "flex"} : {} } className={"hint-content"}>
        {me.props.children}
      </div>
    </div>;
  }

}