import React from "react";
import Document from "../../../models/documents/Document";
import "../../../../components/assets/shared/positioning.css";
import "../../../../components/assets/shared/elements.scss";
import "./documents-control-view.scss";
import Participant from "../../../models/participants/Participant";
import {ApiEngine} from "api-engine";

interface DocumentControlInterfaceProps {
    document: Document,
    active: boolean,
    serverUrl: string,
    triggerLoading: () => void,
    onContextMenuOpen?: () => void,
    onContextMenuClose?: () => void,
    api: ApiEngine,
    myself: Participant
}

interface DocumentControlInterfaceState {
    document: Document,
    currentPage: number | null,
    loading: boolean,
    showControlView: boolean
}

export default class DocumentControlView extends React.Component<DocumentControlInterfaceProps,DocumentControlInterfaceState> {
  constructor(props: DocumentControlInterfaceProps) {
    super(props);
    this.state = {
        document: this.props.document,
        currentPage: this.props.document.shown_page,
        loading: false,
        showControlView: false
    };

    this.sendCommand = this.sendCommand.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.showControlWindow = this.showControlWindow.bind(this);
    this.hideControlWindow = this.hideControlWindow.bind(this);
  }

  sendCommand(_command:string) {
    let me = this;
    if (!me.props.myself.fullAccess) {
      return;
    }
    let prevState = {...me.state};
    let url = `${me.props.serverUrl}/documents/${me.state.document.id}/${_command}`;
    if ("choose_page" === _command) {
      me.props.api.websocketConnector.sendMessage(JSON.stringify({
        title: "set_page",
        document_id: me.state.document.id,
        value: me.state.currentPage
      }));
      return;
      // url += `?page_num=${me.state.currentPage}`;
    }
    if ("next_page" === _command) {
      prevState.currentPage = (prevState.currentPage !== null) ? prevState.currentPage + 1 : 1;
      me.setState(prevState, () => {
        me.sendCommand("choose_page");
      })
      return;
    }

    if ("prev_page" === _command) {
      prevState.currentPage = (prevState.currentPage !== null) ? prevState.currentPage -1 : 1;
      me.setState(prevState, () => {
        me.sendCommand("choose_page");
      })
      return;
    }

    if ("switch_to_document" === _command) {
      // url = `${me.props.serverUrl}/conferences/${me.state.document.conference_id}/${_command}?active_doc_id=${me.state.document.id}`;
      me.props.api.websocketConnector.sendMessage(JSON.stringify({
        title: "switch_document",
        document_id: me.state.document.id
      }))
      return;
    }

    if ("trigger_voting" === _command) {
      // url = `${me.props.serverUrl}/conferences/${me.state.document.conference_id}/${_command}?active_doc_id=${me.state.document.id}`;
      me.props.api.websocketConnector.sendMessage(JSON.stringify({
        title: "trigger_voting",
        document_id: me.state.document.id
      }))
      return;
    }

    if ("result_voting" === _command) {
      me.props.api.websocketConnector.sendMessage(JSON.stringify({
        title: "result_voting",
        document_id: me.state.document.id
      }))
      return;
    }

    prevState.loading = true;
    me.props.triggerLoading();
    me.setState(prevState, () => {
      fetch(new URL(url)).then((e) => {
      }).then((e) => {
        prevState = {...me.state};
        prevState.loading = false;
        me.setState(prevState, () => {
            me.props.triggerLoading();
        });
      })
    });
  }

  setCurrentPage(event: React.ChangeEvent<HTMLInputElement>) {
      let pageNum = event.target.value;
      let me = this;
      let prevState = {...me.state};
      prevState.currentPage = parseInt(pageNum);
      me.setState(prevState, () => {
          me.sendCommand("choose_page");
      });
  }

  showControlWindow() {
    let me = this;
    let prevState = {...me.state};
    prevState.showControlView = true;
    me.setState(prevState, () => {
      if (me.props.onContextMenuOpen && me.props.active) me.props.onContextMenuOpen();
    });
  }

  hideControlWindow() {
    let me = this;
    let prevState = {...me.state};
    prevState.showControlView = false;
    me.setState(prevState, () => {
        if (me.props.onContextMenuClose && me.props.active) me.props.onContextMenuClose();
    });
  }

  render() {
    let me = this;
    return <div style={{display: "flex",
                        transition: "500ms",
                        overflow: "hidden",
                        flexDirection: "column",
                        borderRadius: "10px",
                        // border: (me.props.active && me.state.showControlView )? "1px solid var(--gray_70)" : "none",
                        border: (me.props.active )? "1px solid var(--gray_70)" : "none",
                        background: (me.props.active )? "--gray_800_alpha72" : "transparent",
                }}
                onMouseLeave={me.hideControlWindow}
                onMouseEnter={me.showControlWindow}>
      <div key={`doc-${JSON.stringify(me.props.document)}`}
                  style={{pointerEvents: "all", minHeight: "45px", width: "(97%)"}}

                  className={`${me.props.active ? 'is-active' : ''} document-control-view flex flex-row items-center content-center`}>
        <i className={'fal fa-poll-people fa-1x ng-scope'} />

        <p style={{minWidth: "120px"}}
           onClick={(e) => { me.sendCommand("switch_to_document") } }
           className={"mr-5"}>{me.state.document.name}</p>

        { me.props.active && me.props.document.can_download && me.props.document.file_url &&
          <a className={"dowmload-button"}
             style={{marginLeft: "auto"}}
             href={`${me.props.serverUrl}/${me.props.document.file_url}`}
             target={"_blank"}>
              {/*<i className={"fas fa-cloud-download"}/>*/}
              <i className={"fas fa-angle-double-down"}/>
              Скачать
          </a>
        }
      </div>
      { ((me.props.active && (me.props.myself.fullAccess))) &&
        <div style={{
                  overflow: "hidden",
                  position: "relative",
                  background: "var(--gray_50)",
                  padding: "10px",
              }}
              className={"flex flex-column content-center items-center"}>
          { !me.props.document.voting_in_action && !me.props.document.show_voting_results &&
            <div style={{width: "100%"}} className={"flex flex-row justify-between content-center items-center"}>
              <p style={{marginLeft: 0, marginRight: "auto"}}>Слайд</p>
              <i style={{marginRight: "4px", cursor: "pointer"}}
                 className={"fal fa-arrow-left"}
                 onClick={(e) => {
                  me.sendCommand("prev_page")
              }}></i>
                { (me.state.currentPage !== null) &&
                    <input className={'mr-5'}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => { me.setCurrentPage(e); } }
                           style={{padding: "7px",
                                textAlign: "center",
                                maxWidth: "30px",
                                borderRadius: "10px",
                                border: "1px solid var(--gray_100)"
                           }}
                           value={me.state.currentPage}
                    />
                }
              <i  style={{cursor: "pointer"}}
                  className={"fal fa-arrow-right"}
                  onClick={(e) => {
                  me.sendCommand("next_page")
              }}></i>
            </div>
          }
          <br/>
          { !me.props.document.show_voting &&
            <div  style={{width: "100%"}}
                  className={`w-100 flex flex-row justify-between items-center content-center`}>
              <p style={{marginRight: "s"}}>Голосование</p>
              { !me.props.document.show_voting_results &&
                <div className={`w-100 flex flex-row`}
                     style={{justifyContent: "flex-end"}}
                >
                  { !me.props.document.voting_in_action &&
                    <span style={{marginRight: "20px"}} onClick={(e) => {
                        me.sendCommand("trigger_voting")
                    }} className={'voting-control'}>
                      <i className={"fal fa-play"}/>
                      <p>Запустить голосование</p>
                    </span>
                  }
                  {!!me.props.document.voting_in_action &&
                    <span onClick={(e) => {
                        me.sendCommand("trigger_voting")
                    }} className={'voting-control'}>
                      <i className={"fal fa-pause"}/>
                      <p>Остановить голосование</p>
                    </span>
                  }
                </div>
              }
              {!me.props.document.voting_in_action &&
                <span onClick={(e) => {
                          me.sendCommand("result_voting")
                      }}
                      className={'voting-control'}
                      style={me.props.document.show_voting_results ? {color: "#00abff"} : {} }
                >
                    <i className={"fal fa-flag-checkered"}/>
                    <p>{me.props.document.show_voting_results ? "Подведены итоги" : "Подвести итоги"}</p>
                </span>
              }
            </div>
          }
          {/*>p style="font-size: 7px; margin-right: 4px; line-height: 1.01; text-align: right;" ng-if="!d.voting_in_action" Запустить голосование*/}
          {/*p style="font-size: 7px; margin-right: 4px; line-height: 1.01; text-align: right;" ng-if="!!d.voting_in_action" Остановить голосование*/}
          {/*i ng-click="finishVotingResults(d);" class="fal fa-flag-checkered" style="margin-left: 10px;"*/}
        </div>
      }
    </div>;
  }
}