import React from "react";
import Participant from "../../models/participants/Participant";
import ParticipantListView from "./ParticipantListView";
import Conference from "../../models/conferences/Conference";
import "./participants-list.scss";
import {ApiEngine} from "api-engine";

interface ParticipantsProps {
    serverUrl: string,
    conferenceId: number,
    conference: Conference,
    myself: Participant,
    api: ApiEngine
}

interface ParticipantsState {
    quorum: number,
    participants: Participant[];
    conference: Conference;
    pingInterval: any;
    generation: number;
}


export default class Participants extends React.Component<ParticipantsProps, ParticipantsState> {
  private eventSource: EventSource | null;
  private mounted: boolean;
  private needToReconnect: boolean;

  constructor(_props: ParticipantsProps) {
      super(_props);

      this.state = {
          quorum: 10000000,
          participants: [],
          conference: this.props.conference,
          pingInterval: null,
          generation: 0
      };

      this.needToReconnect = true;

      this.getData = this.getData.bind(this);
      this.introduceSelf = this.introduceSelf.bind(this);
      this.onConferenceEventHandler = this.onConferenceEventHandler.bind(this);
      this.initWebsocket = this.initWebsocket.bind(this);
      this.eventSource = null;
      this.mounted = false;
  }

  introduceSelf() {
    let me = this;
    let url = `/api/active_call_sessions/register_peer?session_id=${me.props.myself.peer_id}&user_id=${me.props.myself.id}&conference_id=${me.props.conferenceId}`;
    return new Promise((_resolve) => {
      me.props.api.asyncFetch(`${url}`, {}).then((_res) => {
        _resolve(true);
      });
    });
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    let me = this;
    me.introduceSelf().then((_e) => {
      me.getData().then((e) => {
        me.initWebsocket();
      });
    });

  }

  componentWillUnmount() {
    document.removeEventListener("Conference", this.onConferenceEventHandler);
  }

  initWebsocket() {
    let me = this;
    me.props.api.websocketConnector.sendMessage(JSON.stringify({
      title: "connect_conference",
      conferenceId: me.props.conferenceId
    }));
    document.addEventListener("Conference", me.onConferenceEventHandler, false);
  }

  onConferenceEventHandler(_data: any) {
    let me = this;
    // alert(JSON.stringify(_data.detail));
    // console.log(_data.detail);
    if (_data.detail === "RESTART") {
      me.getData().then((e) => {
        me.initWebsocket();
      });
      return;
    }
    if (!_data.detail) return;
    if (!_data.detail.filter) return;

    // console.log(_data.detail);

    let participants = _data.detail.filter((_e: any) => {return !!_e && !!_e.user;} ).map((_participantData: any) => {
      // alert(JSON.stringify(_participantData));
      let p = new Participant(_participantData.user);
      p.role = _participantData.role;
      p.call_me = _participantData.call_me;
      p.is_on_fullscreen = _participantData.is_on_fullscreen;
      p.peer_id = _participantData.session_id;
      p.is_muted = _participantData.is_muted;
      p.block_video = _participantData.block_video;
      // alert(`video-for-user-${_participantData.user_id}`);
      let videoElements = Array.from(document.getElementsByClassName(`video-for-user-${_participantData.user_id}`)  as HTMLCollectionOf<HTMLElement>);
      // alert(videoElements.length);
      for(let elIndex = 0; elIndex < videoElements.length; elIndex++) {
        let el = videoElements[elIndex];
        // alert(p.block_video);
        // console.log(`${p.last_name}[${elIndex}] - ${p.block_video} - ${p.block_video?.toString()} - ${p.block_video === false}`);
        el.style.opacity = (p.block_video === false) ? "1" : "0";
        // alert(`op-${el.style.opacity}`);
        // if (p.block_video) {}
      }
      return p;
    });
    // alert("Soring");
    participants.sort((_a: Participant, _b: Participant) => {
      if  (_a.last_name > _b.last_name) return 1;
      if  (_a.last_name < _b.last_name) return -1;
      return 0;
    });
    // participants.reverse();
    participants.sort((x: Participant, y: Participant) => {
      return (x.call_me === y.call_me)? 0 : x.call_me? -1 : 1;
    });
    // participants = participants.filter((_val: Participant, _index: number, _self: Participant[]) => {
    //   return _index === _self.findIndex((t: Participant) => (
    //     t.id === _val.id
    //   ));
    // });
    let prevState = {... me.state};
    prevState.participants = participants;
    prevState.generation += 1;
    me.setState(prevState, () => {
    })
  }

  getDataLoop() {
      let me = this;
      me.getData().then(() => {
        setTimeout(() => {
          me.getDataLoop();
        }, 5000);
      })
  }

  getData() {
      let me = this;
      return new Promise((resolve) => {
        resolve(true);
        return;
        // let url = new URL(`${me.props.serverUrl}/active_call_sessions/index?conference_id=${me.props.conferenceId}`);
          let url = new URL(`${me.props.serverUrl}/active_call_sessions/index?session_id=${me.props.myself.peer_id}&user_id=${me.props.myself.id}&conference_id=${me.props.conferenceId}`);
          fetch(url).then((_e) => _e.json()).then((_participantsData) => {
            // alert(JSON.stringify(_participantsData));
            // alert(_participantsData.result);
            // alert(JSON.parse(_participantsData.result)[0]);
            let participants = JSON.parse(_participantsData.result).filter((_e: any) => {return !!_e && !!_e.user;} ).map((_participantData: any) => {
              let p = new Participant(_participantData.user);
              // alert(JSON.stringify(_participantData));
              p.role = _participantData.role;
              p.call_me = _participantData.call_me;
              p.is_on_fullscreen = _participantData.is_on_fullscreen;
              p.peer_id = _participantData.session_id;
              p.is_muted = _participantData.is_muted;
              return p;
            });

            participants = participants.filter((_val: Participant, _index: number, _self: Participant[]) => {
             return _index === _self.findIndex((t: Participant) => (
                 t.id === _val.id
             ));
            });

            participants.sort((_a: Participant, _b: Participant) => {
              if  (_a.last_name > _b.last_name) return 1;
              if  (_a.last_name < _b.last_name) return -1;
              return 0;
            });

            // participants.reverse();

            participants.sort((x: Participant, y: Participant) => {
              return (x.call_me === y.call_me)? 0 : x.call_me? -1 : 1;
            });

            let prevState = {... me.state};
            prevState.participants = participants;
            me.setState(prevState, () => {
              resolve(true);
            })
          });
      });
  }

  render() {
      let me = this;
      return <div className={"participants"}
                  key={`${me.state.generation}-${JSON.stringify(me.state.participants)}`} >
        {/*<p>{me.state.generation}</p>*/}
        <div className={`flex flex-row align-center items-center`}>
              <h4 className={`flex flex-row content-center items-center`}>
                  <span>
                      Участники ({me.state.participants.length})
                  </span>
                  { (me.props.conference.quorum_amnt !== null) && (me.state.participants.length >= me.props.conference.quorum_amnt) &&
                      <div className="quorum-ok">Кворум</div>
                  }
              </h4>

          </div>
          <div style={{display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      maxHeight: "80vh",
                      overflowY: "scroll"
          }}>
            {
                me.state.participants.map((_participant, partIndex) => {
                    return <ParticipantListView key={`participant-${partIndex}`}
                                                participant={_participant}
                                                api={me.props.api}
                                                myself={me.props.myself}
                    />
                })
            }
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
      </div>;
  }
}