import React from "react";
import "./user_cabinet.scss";
import {Link, Navigate, Route, Routes} from "react-router-dom";
import SignIn from "../../auth/Login/sign_in/SignIn";
import Conferences from "../conferences/Conferences";
import {ApiEngine} from "api-engine";
import { UserContext } from "../../../contexts/UserContext";
import Conference from "../../conference/Conference";

interface CabinetProps {
  api: ApiEngine;
}

interface CabinetState {
  mode: string;
}

export default class Cabinet extends React.Component<CabinetProps, CabinetState> {

  constructor(_props: any) {
    super(_props);
    this.state = {
      mode: ""
    }
  }

  render() {
    let me = this;

    return <div className={"user-cabinet"}>
      <br/>
      <div className={"tab-switcher"}>
        <Link onClick={() => {me.setState({mode: ""})}} style={me.state.mode === '' ? {} : { opacity: 0.5} } className={'tab-switcher-button'} to={"/cabinet/conferences/agenda"}>Ближайшие заседания</Link>
        <Link onClick={() => {me.setState({mode: "archive"})}} style={me.state.mode === 'archive' ? {} : { opacity: 0.5} } className={'tab-switcher-button'} to={"/cabinet/conferences/archive"}>Архив заседаний</Link>
      </div>

      <UserContext.Consumer>
        {({participant, setParticipant}) => (
          <>
            {participant &&
              <Routes>
                <Route
                  path={"/*"}
                  element={<Conferences mode={"agenda"}
                                        key={'agenda'}
                                        api={me.props.api}
                                        participant={participant}
                  />}
                />
                <Route
                  path={"/conferences/archive/*"}
                  element={<Conferences mode={"archive"}
                                        key={'archive'}
                                        api={me.props.api}
                                        participant={participant}
                  />}
                />
              </Routes>
            }
          </>
        )
        }
      </UserContext.Consumer>
    </div>
  }
}