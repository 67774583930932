import React from "react";

interface GetterProps {
  id: number;
}

export default class NameGetter extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      content: "" //this.props.id
    };
  }

  componentDidMount() {
    let me = this;
    let url = `https://erconf.ru/users/who_is?id=${this.props.id}`;
    fetch(url, {}).
    then((_e)=> _e.json()).then(
      (_e) => {
        let prevState = {...me.state};
        prevState.content = _e.name;
        me.setState(prevState);
      }
    )//.et
  }

  render() {
    let me = this;
    return <span>{me.state.content}</span>
  }
}