import React from "react";
import {Route, Routes, useParams} from "react-router-dom";
import PublishingFeedJanusBaseStrategy
  from "../../gateways/janus/strategies/publishing/PublishingFeedJanusBaseStrategy";
import ReceivingFeedJanusBaseStrategy from "../../gateways/janus/strategies/ReceivingFeedJanusBaseStrategy";
import JanusConnector from "../../gateways/janus/JanusConnector";
import ConfApp from "../../ConfApp";
import {ApiEngine} from "api-engine";
import Participant from "../models/participants/Participant";

interface ConferenceProps {
  api: ApiEngine;
  myself: Participant;
  config: any;
  logo: JSX.Element | null;
  serverURL: string;
}

export default function Conference(_props: ConferenceProps) {
  const params = useParams();
  console.log(_props.myself);
  let name = _props.myself.name();
  let myself = _props.myself;
  myself.fullAccess = true; //(params.get("full_access") === "true") || (_user.role === "Выступающий") || (_user.role === "Ведущий");

  let configurationOfConnectors = {
    microphones: [],
    videoGrids: _props.config.video.videoGrids
  }

  let januses = [];
  for (let _janusData of configurationOfConnectors.videoGrids) {
    let publishingStrategy = new PublishingFeedJanusBaseStrategy();
    // let publishingStrategy = new PublishingOnlyVideoFeedJanusBaseStrategy();
    // let publishingStrategy = new PublishingOnlyAudioFeedJanusBaseStrategy();
    let receivingFeedStrategy = new ReceivingFeedJanusBaseStrategy();
    // let janusConnector = new JanusConnector(myself,"1",1234, "wss://megagrid.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);
    let janusConnector = new JanusConnector(myself,_janusData.name, _janusData.roomName, _janusData.serverUrl, publishingStrategy, receivingFeedStrategy);
    januses.push(janusConnector);
  }
  let publishingStrategy = new PublishingFeedJanusBaseStrategy();
  // let publishingStrategy = new PublishingOnlyVideoFeedJanusBaseStrategy();
  // let publishingStrategy = new PublishingOnlyAudioFeedJanusBaseStrategy();
  let receivingFeedStrategy = new ReceivingFeedJanusBaseStrategy();
  // let janusConnector = new JanusConnector(myself,"1",1234, "wss://megagrid.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);
  let janusConnector = new JanusConnector(myself,"1",1234, "wss://recordings.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);

  return <>
    { params.id &&
      <ConfApp myself={myself}
               api={_props.api}
               logo={_props.logo}
               serverURL={_props.serverURL}
               conferenceId={parseInt(params.id)}
               videogridJanusConnectors={januses}
               blockMobile={_props.config.layout.block_mobile}
      />
    }
  </>

}