import React from 'react';
import ReactDOM from 'react-dom/client';
import "./components/assets/shared/colors.css";
import "./components/assets/shared/elements.scss";
import "./components/assets/shared/positioning.css";
import './index.css';
import Participant from "./components/models/participants/Participant";
import App from "./App";

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ApiEngine, SessionContainer, WebsocketConnector} from "api-engine";

// return;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultUrl = "https://function4.tech/api/flow/config_params";
let configUrl = process.env.REACT_APP_CONF_URL ? process.env.REACT_APP_CONF_URL : defaultUrl;

fetch(configUrl).then((_e) => {return _e.json()}).then((_x) => {
  let config = _x;
  let serverURL = config.serverUrl;
  let sessionContainer = new SessionContainer<any>(Participant, "/api/participants/me");
  let api = new ApiEngine(serverURL, 0, sessionContainer);
  api.startQueue();
  api.websocketConnector = new WebsocketConnector("/api/ws", api);
  sessionContainer.checkUser().then((_e) => {
    let user: Participant | null = new Participant(_e);
    const router = createBrowserRouter([
      {
        path: `/*`,
        index: true,
        element: <App config={config}
                      myself={user}
                      api={api}
                      logo={null}
        />
      }
    ], {basename: process.env["PUBLIC_URL"]});
    root.render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  }).catch((_e) => {
    let user: Participant | null = null;
    const router = createBrowserRouter([
      {
        path: `/*`,
        index: true,
        element: <App config={config}
                      myself={user}
                      api={api}
                      logo={null}
        />
      }
    ], {basename: process.env["PUBLIC_URL"]});
    root.render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  })

  // function startVKS(_user: any)  {
  //   let myself = new Participant(_user);
  //   myself.fullAccess = (params.get("full_access") === "true") || (_user.role === "Выступающий") || (_user.role === "Ведущий");
  //   sessionContainer.currentEntity = myself;
  //   api.websocketConnector.initWebsocket();
  //
  //   let configurationOfConnectors = {
  //     microphones: [],
  //     videoGrids: config.video.videoGrids
  //   }
  //
  //   let januses = [];
  //   for (let _janusData of configurationOfConnectors.videoGrids) {
  //     let publishingStrategy = new PublishingFeedJanusBaseStrategy();
  //     // let publishingStrategy = new PublishingOnlyVideoFeedJanusBaseStrategy();
  //     // let publishingStrategy = new PublishingOnlyAudioFeedJanusBaseStrategy();
  //     let receivingFeedStrategy = new ReceivingFeedJanusBaseStrategy();
  //     // let janusConnector = new JanusConnector(myself,"1",1234, "wss://megagrid.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);
  //     let janusConnector = new JanusConnector(myself,_janusData.name, _janusData.roomName, _janusData.serverUrl, publishingStrategy, receivingFeedStrategy);
  //     januses.push(janusConnector);
  //   }
  //   let publishingStrategy = new PublishingFeedJanusBaseStrategy();
  //   // let publishingStrategy = new PublishingOnlyVideoFeedJanusBaseStrategy();
  //   // let publishingStrategy = new PublishingOnlyAudioFeedJanusBaseStrategy();
  //   let receivingFeedStrategy = new ReceivingFeedJanusBaseStrategy();
  //   // let janusConnector = new JanusConnector(myself,"1",1234, "wss://megagrid.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);
  //   let janusConnector = new JanusConnector(myself,"1",1234, "wss://recordings.erconf.ru:8989/janus", publishingStrategy, receivingFeedStrategy);
  //
  //
  //   root.render(
  //     <React.StrictMode>
  //       {
  //         config.layout.header &&
  //           <Header myself={myself} />
  //       }
  //       <div style={{boxSizing: "border-box", padding: "14px"}}>
  //         <ConfApp myself={myself}
  //                  api={api}
  //                  logo={config.layout.logo}
  //                  serverURL={serverURL}
  //                  conferenceId={conferenceId}
  //                  videogridJanusConnectors={januses}
  //                  blockMobile={config.layout.block_mobile}
  //         />
  //         {/*videogridJanusConnectors={[janusConnector]} />*/}
  //       </div>
  //     </React.StrictMode>
  //   );
  // }
  //

  // api.asyncFetch(`${config.authentication.who_is_path}`, {}).then((_userRes: any) => {
  //   alert(JSON.stringify( _userRes));
  //   // startVKS(_user);
  //   let user: Participant | null = _userRes.user ? new Participant(_userRes.user) : null;
  //
  //   const router = createBrowserRouter([
  //     {
  //       path: `/*`,
  //       index: true,
  //       element: <App config={config}
  //                     myself={user}
  //                     api={api}
  //       />
  //     }
  //   ], {basename: process.env["PUBLIC_URL"]});
  //   root.render(
  //     <React.StrictMode>
  //       <RouterProvider router={router} />
  //     </React.StrictMode>
  //   );
  // });

});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
