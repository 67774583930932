import $ from "jquery";
import JanusStrategyInterface from "./strategies/JanusStrategyInterface";

export default class JanusConnector {
    get opaqueId(): string {
        return this._opaqueId;
    }
    publishing: boolean;
    connecting: boolean;
    successfullyConnectedAsPublisher?: boolean;
    myself: any;
    audioDevices: any[];
    videoDevices: any[];
    name: string;
    JanusClass: any;
    publishingFeedJanusStrategy: JanusStrategyInterface;
    receivingFeedJanusStrategy: JanusStrategyInterface;
    janusInstance: any;
    serverUrl: string
    private _opaqueId: string;
    private sfu: any;
    myid: any;
    mypvtid?: any;
    room: number;
    audioDevice?: string;
    videoDevice?: string;
    private feeds: any;
    private feedStreams: any;
    private subStreams: any;
    private slots: any;
    private mids: any;
    private subscriptions: any;
    private localTracks: any;
    private gatheredId: number;
    elementToPutChildrenTo: HTMLDivElement | null;

    constructor(_myself: any, _name: string, _roomName:number, _serverUrl: string, _publishingJanusStrategy: JanusStrategyInterface, _receivingFeedJanusStrategy: JanusStrategyInterface) {
        this.myself = _myself;
        this.publishing = true;
        this.name = _name;
        this.serverUrl = _serverUrl; //ws://89.108.102.44:8188/janus
        this.room = _roomName;
        this.gatheredId = -1;
        this.publishingFeedJanusStrategy = _publishingJanusStrategy;
        this.receivingFeedJanusStrategy = _receivingFeedJanusStrategy;
        this.elementToPutChildrenTo = null;
        this.initParams = this.initParams.bind(this);
        this.publishingFeedJanusStrategy.janusConnector = this;
        this.receivingFeedJanusStrategy.janusConnector = this;
        this.initParams();
        this._opaqueId = `conf-${this.JanusClass.randomString(12)}`;
        this.audioDevices = [];
        this.videoDevices = [];
        this.connecting = false;
        this.connect = this.connect.bind(this);
    }

    initParams() {
        this.feeds = {};
        this.feedStreams = {};
        this.subStreams = {};
        this.slots = {};
        this.mids = {};
        this.subscriptions = {};
        this.localTracks = {};
        // @ts-ignore
        this.JanusClass = Janus;
    }

    connect(_elementToPutVideosTo: HTMLDivElement):Promise<any> {
        let me = this;
        me.connecting = true;
        me.elementToPutChildrenTo = _elementToPutVideosTo;
        return new Promise(((resolve, reject) => {
            me.JanusClass.init({debug: "all",
                callback: function() {
                    if(!me.JanusClass.isWebrtcSupported()) {
                        alert("Ваш браузер не поддерживает технологию webrtc. Пожалуйста, убедитесь, что использованг браузер Google Chrome последней версии. В случае,если обновление не помогло, обратитесь к системному администратору.");
                    return;
                }

                me.janusInstance = new me.JanusClass(
                    {
                        server: me.serverUrl,
                        // iceServers: [{urls: "stun:stun.l.google.com:19302"}],
                        // {"url": "turn:[public_ip]:3478", username:"pino", credential:"pino"}
                        iceServers: [{url: "turn:89.108.116.236:3478", username: "user", credential: "user"}],
                        success: () => {
                            me.janusInstance.attach({
                                plugin: "janus.plugin.videoroom",
                                opaqueId: me._opaqueId,
                                success: function(pluginHandle: any) {
                                    me.publishingFeedJanusStrategy.success(pluginHandle, me.janusInstance, []);
                                    resolve(me);
                                },
                                error: function(_error: any) {
                                    // alert(_error);
                                    me.publishingFeedJanusStrategy.error(_error, me.janusInstance);
                                },
                                consentDialog: function(_on:boolean) {
                                    me.publishingFeedJanusStrategy.consentDialog(_on, me.janusInstance);
                                },
                                iceState: function(_state: any) {
                                    me.publishingFeedJanusStrategy.iceState(_state, me.janusInstance);
                                },
                                mediaState: function(_medium: any, _on: any, _mid: any) {
                                    me.publishingFeedJanusStrategy.mediaState(_medium, _on, _mid, me.janusInstance);
                                },
                                webrtcState: function(_on: any) {
                                    me.publishingFeedJanusStrategy.webrtcState(_on, me.publishingFeedJanusStrategy);

                                },
                                slowLink: function(_uplink:any, _lost:any, _mid:any) {
                                    me.publishingFeedJanusStrategy.slowLink(_uplink, _lost, _mid, me.janusInstance);
                                },
                                onmessage: function(_msg: any, _jsep: any) {
                                    me.publishingFeedJanusStrategy.onmessage(_msg, _jsep, me.janusInstance);
                                },
                                onlocaltrack: function(_track:any, _on:any) {
                                    me.publishingFeedJanusStrategy.onlocaltrack(_track, _on, me.janusInstance);
                                },
                                onremotetrack: function(_track:any, _mid:any, _on:any) {
                                    me.publishingFeedJanusStrategy.onremotetrack(_track, _mid, _on, me.janusInstance);
                                },
                                oncleanup: function() {
                                    me.publishingFeedJanusStrategy.oncleanup(me.janusInstance);
                                }
                            });
                        }
                    });
            }
        });
        }));
    }

    setDevicesList(audioDevices: any[], videoDevices: any[], chosenVideo: string, chosenAudio: string) {
        this.audioDevices = audioDevices;
        this.videoDevices = videoDevices;
        this.videoDevice = chosenVideo;
        this.audioDevice = chosenAudio;
    }
}
