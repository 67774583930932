import React from "react";
import "./full_year_calendar.scss";
import { ApiEngine } from "api-engine";
//@ts-ignore
import Calendar from "rc-year-calendar";
import 'rc-year-calendar/locales/rc-year-calendar.ru';
import MulticircleSpinner from "../../../loaders/spinners/multicircle-spinner/MulticircleSpinner";


interface FullYearCalendarProps {
  api: ApiEngine;
  onCommand: (_cmd: any) => void;
}

interface FullYearCalendarState {
  conferences: any[];
  shownConferences: any[];
  conferencesIndex: number;
  totalIndex: number;
  currentYear: number;
  onThatDay: any;
}

export default class FullYearCalendar extends React.Component<FullYearCalendarProps, FullYearCalendarState> {

  private initialized: boolean;

  constructor(_props: FullYearCalendarProps) {
    super(_props);

    this.state = {
      conferences: [],
      shownConferences: [],
      conferencesIndex: 0,
      totalIndex: -1,
      currentYear: new Date().getFullYear(),
      onThatDay: null
    }

    this.initialized = false;
    this.getData = this.getData.bind(this);
  }

  componentDidMount(): void {
    let me = this;
    if (me.initialized) return;
    me.initialized = true;
    this.getData();
  }

  getData() {
    let me = this;
    me.props.api.asyncFetch(`/api/conferences/index`, {}).then((_res: any) => {
      let conferences = [..._res.result, ...me.state.conferences];
      conferences = conferences.map((_o: any) => {
        _o.startDate = new Date(_o.date_start);
        _o.startDate.setHours(_o.startDate.getHours() - 3);
        _o.color = "#00abff";
        // _o.color = "#E2800D";
        // _o.color = "#00abff";
        return _o;
      });
      me.setState({
        conferences: conferences,
        shownConferences: conferences,
        conferencesIndex: me.state.conferencesIndex + 1,
      }, () => {
      });
    });
  }

  render () {
    let me = this;

    return <div className={"full-year-calendar"}
                key={JSON.stringify(me.state.shownConferences)}
           >
      <div className={"calendar-container"}>

        <Calendar
          key={`${me.state.conferencesIndex}`}
          enableRangeSelection={false}
          enableContextMenu={false}
          language={'ru'}
          onRangeSelected={(e: any) => {} }
          dataSource={me.state.shownConferences}
          onDayClick={(_data: any) => {
            alert(_data.events.length );
            if (_data.events.length > 0)
              me.setState({onThatDay: _data});
            // alert(JSON.stringify(_data.date));
            // alert(JSON.stringify(_data.events));
            // alert(JSON.stringify(events));
          }}
        />
      </div>
      { me.state.onThatDay &&
          <div className={"modal-backdrop"}>
              <div className={"modal"}>
                  <i className={'far fa-times fa-2x'}
                     style={{position: "absolute", top: "10px", right: "10px", cursor: "pointer"}}
                     onClick={() => {
                       me.setState({onThatDay: null});
                     }
                     }
                  ></i>
                  <h3>{me.state.onThatDay.date.toLocaleString()}</h3>
                  <div className={"events-list"} style={{width: "100%"}}>
                    {
                      me.state.onThatDay.events.map((_okrug: any, _index: number) => {
                        return <div className={"okrug"}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                    key={_index}
                                    onClick={() => {
                                      // alert(JSON.stringify(_okrug));
                                      // alert(_okrug.electoral_procedure_request.id);
                                      me.props.onCommand([
                                        {
                                          "title": "deactivate_part",
                                          "value": "all"
                                        },
                                        {
                                          "title": "activate_part",
                                          "value": "electoral_procedure_request",
                                          "content": _okrug.electoral_procedure_request.id
                                        }
                                      ]);
                                    }}
                        >
                          {/* <p>{JSON.stringify(_okrug)}</p> */}
                          <p className={"date"}>{`${_okrug.startDate.toLocaleString()} - ${_okrug.endDate.toLocaleString()}`}</p>
                          <p className={'region-name'}>{_okrug.electoral_procedure_request.region}</p>
                          <p className={'voting-name'}>{_okrug.electoral_procedure_request.voting_name}</p>
                          <p className={'territory-name'}>{_okrug.electoral_procedure_request.territory_name}</p>
                          <p className={"okrug-name"}>{_okrug.name}</p>
                        </div>
                      })
                    }
                  </div>
              </div>
          </div>
      }
    </div>;
  }
}