import React from "react";
import "../../../assets/shared/positioning.css";
import "../../../assets/shared/elements.scss";
import DeviceChooser from "../device-chooser/DeviceChooser";
import JanusConnector from "../../../../gateways/janus/JanusConnector";
import Participant from "../../../models/participants/Participant";
import {ApiEngine} from "api-engine";

interface VideoControlsProps {
    localFeedJanusConnector: JanusConnector;
    conferenceId: number;
    myself: Participant;
    api: ApiEngine;
}

interface VideoControlsState {
    publishing: boolean
}

export default class VideoControls extends React.Component<VideoControlsProps, VideoControlsState> {

    constructor(_props: VideoControlsProps) {
        super(_props);
        this.state = {
            publishing: false
        }

        this.checkIfPublishing = this.checkIfPublishing.bind(this);
    }

    componentDidMount() {
        this.checkIfPublishing();
    }

    checkIfPublishing() {
        let me = this;
        if (me.props.localFeedJanusConnector.successfullyConnectedAsPublisher !== undefined) {
            let prevState = {...me.state};
            prevState.publishing = me.props.localFeedJanusConnector.successfullyConnectedAsPublisher;
            me.setState(prevState);
            return
        }
        setTimeout(me.checkIfPublishing, 500);
    }

    render() {
        let me = this;
        return <div className={"conference-control"}
                    key={`${me.state.publishing ? 1 : 2}`}
                    style={me.state.publishing ? {} : {display: "none"} }
        >
            {/*<h4>Управление</h4>*/}
            <div className={"flex flex-row flex-wrap justify-between"}>
                {/*<button className={"unnattentive-button"}>*/}
                {/*    <i className={"fas fa-broadcast-tower fa-2x"} />*/}
                {/*    <p>Начать вещание</p>*/}
                {/*</button>*/}
                {/*<button className={"unnattentive-button"}>*/}
                {/*    <i className={"fas fa-circle fa-2x"} />*/}
                {/*    <p>Запрос на выступление</p>*/}
                {/*</button>*/}
                {/*<button className={"unnattentive-button"}>*/}
                {/*    <i className={"fas fa-podium fa-2x"} />*/}
                {/*    <p>Переключиться на ведущего</p>*/}
                {/*</button>*/}
                {/*<button className={"unnattentive-button"}>*/}
                {/*    <i className={"fas fa-users fa-2x"} />*/}
                {/*    <p>Переключиться на участников</p>*/}
                {/*</button>*/}
                <DeviceChooser janusConnector={me.props.localFeedJanusConnector}
                                conferenceId={me.props.conferenceId}
                                myself={me.props.myself}
                                api={me.props.api}
                                publishing={me.state.publishing}
                />
            </div>
        </div>;
    }
}