import React from "react";
import Lobby from "./lobby/Lobby";
import VideoGrid from "./conference_parts/videogrid/VideoGrid";
import ConferenceContentPart from "./conference_parts/conference_content/ConferenceContentPart";
import ConferenceControl from "./conference_parts/conference_content/conference_control/ConferenceControl";
import Participants from "./conference_parts/participants/Participants";
import Chat from "./conference_parts/conference_content/chat/Chat";
import "../components/assets/shared/positioning.css";
import "../components/assets/shared/elements.scss";
import "./conference-container.scss";
import DocumentInterface from "./models/documents/DocumentInterface";
import Document from "./models/documents/Document";
import Conference from "./models/conferences/Conference";
import JanusConnector from "../gateways/janus/JanusConnector";
import Participant from "./models/participants/Participant";
import {ApiEngine} from "api-engine";
import MulticircleSpinner from "./loaders/spinners/multicircle-spinner/MulticircleSpinner";


interface ConferenceState {
    stream: MediaStream | null,
    serverUrl: string,
    conferenceId: number,
    documents: Document[],
    activeDocumentId: number | null,
    conference: Conference | null,
    audioDevices: any[],
    videoDevices: any[],
    videoId: string | null,
    audioId: string | null,
    generation: number
}

interface ConferenceProps {
    stream?: MediaStream,
    serverUrl: string
    conferenceId: number,
    videogridJanusConnectors: JanusConnector[],
    api: ApiEngine;
    myself: Participant;
    logo?: JSX.Element | null;
    blockMobile: boolean;
}

export default class ConferenceView extends React.Component<ConferenceProps, ConferenceState> {
    private myVideoRef: React.RefObject<HTMLVideoElement>;
    mounted: boolean;
    private conferenceContentPartRef: React.RefObject<ConferenceContentPart>;

    constructor(_props: ConferenceProps) {
        super(_props);

        this.state = {
            stream: null,
            serverUrl: this.props.serverUrl,
            conferenceId: this.props.conferenceId,
            documents: [],
            activeDocumentId: null,
            conference: null,
            audioDevices: [],
            videoDevices: [],
            videoId: null,
            audioId: null,
            generation: 0
        };

        this.streamReady = this.streamReady.bind(this);
        this.myVideoRef = React.createRef<HTMLVideoElement>();

        this.getData = this.getData.bind(this);
        this.getDataLoop = this.getDataLoop.bind(this);
        this.getAboutData = this.getAboutData.bind(this);
        this.initWebsocket = this.initWebsocket.bind(this);

        this.conferenceContentPartRef = React.createRef<ConferenceContentPart>();

        this.mounted = false;
    }

    componentWillUnmount() {
        console.log("Leaving room");
        this.props.api.websocketConnector.hangupWebSocket();
    }

    componentDidMount() {
        if (this.mounted) return;
        this.mounted = true;

        let me = this;
        me.getAboutData().then((_conf: Conference) => {
            let prevState = {...me.state};
            prevState.conference = _conf;
            me.setState(prevState, () => {
                me.getData().then((e) => {
                    me.props.api.websocketConnector.initWebsocket();
                    me.initWebsocket();
                });
            })
        })
    }

    initWebsocket() {
        let me = this;
        if (!me.props.api.websocketConnector.alive) {
            console.log("Reconnect to conf view");
            setTimeout(me.initWebsocket, 5000);
            return;
        }

        me.props.api.websocketConnector.sendMessage(JSON.stringify({
            title: "connect_documents",
            conference_id: me.props.conferenceId,
            session_id: me.props.myself.peer_id
        }));

        document.addEventListener("Document", (_data: any) => {
            // alert(JSON.stringify(_data.detail));
            // console.log(_data.detail);
            if (_data.detail.title === "RESTART") {
                me.getData().then((e) => {
                    me.initWebsocket();
                });
                return;
            }

            if (_data.detail.title === "RELOAD_CONTENT") {
                if (me.conferenceContentPartRef.current) {
                    // me.getData().then(() => {
                    if (me.conferenceContentPartRef.current)
                        me.conferenceContentPartRef.current.getData();
                    // });
                }
                return;
            }

            if (_data.detail.title === "RELOAD") {
            // alert((_data.detail.title));
            //     alert("RELOAD");
                let prevState = {...me.state};
                prevState.generation += 1;
                me.setState(prevState, () => {
                    me.getData().then((e) => {
                    });
                });
                return;
            }

            if (_data.detail.title === "SET_ACTIVE_DOC_ID") {
                let prevState = {...me.state};
                prevState.activeDocumentId = _data.detail.value;
                me.setState(prevState);
            }
        });
    }

    getAboutData():Promise<Conference> {
        let me = this;
        return new Promise((resolve) => {
            me.props.api.asyncFetch(`/api/conferences/${me.props.conferenceId}/about`, {}).then((e) => {
                let conf = new Conference(e.result);
                resolve(conf);
            })
        });
    }

    getDataLoop() {
        let me = this;
        me.getData().then((e) => {
            setTimeout(() => {
                me.getDataLoop()
            }, 4000)
        });
    }

    getData() {
        let me = this;
        return new Promise((resolve, reject) => {
            let url = `/api/conferences/${me.props.conferenceId}/documents_situation`;

            me.props.api.asyncFetch(url, {}).then((_data: any) => {
                // let data = dataMock as {documents: DocumentInterface[]};
                let addedData = _data.result.documents.map((_doc: DocumentInterface) => {
                    return new Document(_doc);
                });

                let prevState = {...me.state};

                prevState.activeDocumentId = _data.active_doc_id;
                prevState.documents = addedData;
                me.setState(prevState, () => {
                    if (me.conferenceContentPartRef.current) {
                        me.conferenceContentPartRef.current.getData();
                    }
                    resolve(true);
                });
            });

        });
    }

    streamReady(_stream: MediaStream,
                _audioDevices: any[],
                _videoDevices: any[],
                _audioId: string,
                _videoId: string) {
        let me = this;
        let prevState = {...me.state};
        prevState.stream = _stream;
        prevState.audioDevices = _audioDevices;
        prevState.videoDevices = _videoDevices;
        prevState.audioId = _audioId;
        prevState.videoId = _videoId;
        for(let janusConnector of me.props.videogridJanusConnectors) {
            janusConnector.setDevicesList(prevState.audioDevices, prevState.videoDevices, prevState.videoId, prevState.audioId);
        }
        me.setState(prevState, () => {});
    }

    render() {
        let me = this;
        return <div className={"conference-container"}>
            { !me.state.conference && <MulticircleSpinner /> }

            { !me.props.blockMobile &&
              <>
                  { me.state.conference && !me.state.stream &&
                    <Lobby streamReady={me.streamReady}
                           logo={me.props.logo}
                           blockMobile={me.props.blockMobile}
                    />
                  }
                  { me.state.conference && me.state.stream &&
                    <div className={"conference-content"}>
                        <div className={"flex flex-column mr-5 conference-column-1 h-100"}>
                            <VideoGrid
                              api={me.props.api}
                              conferenceId={me.props.conferenceId}
                              myself={me.props.myself}
                              janusConnectors={me.props.videogridJanusConnectors}
                            />
                            <ConferenceControl key={JSON.stringify(me.state.documents)}
                                               serverUrl={me.state.serverUrl}
                                               documents={me.state.documents}
                                               activeDocumentId={me.state.activeDocumentId}
                                               api={me.props.api}
                                               myself={me.props.myself}
                            />
                        </div>
                        <div className={"conference-column-2 flex flex-column mr-5"} style={{}}>
                            <div style={{display: "flex", flexDirection: "column", height: "100%"}} key={`${JSON.stringify(me.state.documents)}`}>
                                <ConferenceContentPart serverUrl={me.props.serverUrl}
                                                       ref={me.conferenceContentPartRef}
                                                       myself={me.props.myself}
                                                       activeDocumentId={me.state.activeDocumentId}
                                                       key={`${me.state.activeDocumentId}-${me.state.generation}-active-document-view-${me.state.activeDocumentId}`}
                                />
                                <Chat
                                  myself={me.props.myself}
                                  api={me.props.api}
                                  conferenceId={me.props.conferenceId}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-column conference-column-3"}>
                            <div className={`card-100`} style={{height: "100%"}}
                                 key={JSON.stringify(me.state.documents)}>
                                <Participants conference={me.state.conference}
                                              conferenceId={me.props.conferenceId}
                                              serverUrl={me.props.serverUrl}
                                              myself={me.props.myself}
                                              api={me.props.api}
                                />
                            </div>

                        </div>
                    </div>
                  }
              </>
            }
            </div>
    }
}