import DocumentInterface from "./DocumentInterface";

export default class Document implements DocumentInterface {
    bg_color: string | null;
    can_download: boolean | null;
    color: string | null;
    conference_id: number;
    created_at: string;
    file_url: string | null;
    id: number;
    iframe_url: string | null;
    keynote_id: number | null;
    name: string;
    title: string;
    show_in_iframe: boolean | null;
    show_order: number | null;
    show_pic: boolean | null;
    show_video: boolean | null;
    show_voting: boolean | null;
    show_voting_results: boolean | null;
    shown_page: number | null;
    updated_at: string;
    video_url: string | null;
    voting_in_action: boolean | null;

    constructor(_doc: DocumentInterface) {
        this.bg_color = _doc.bg_color;
        this.can_download = _doc.can_download;
        this.color = _doc.color;
        this.conference_id = _doc.conference_id;
        this.created_at = _doc.created_at;
        this.file_url = _doc.file_url;
        this.id = _doc.id;
        this.iframe_url = _doc.iframe_url
        this.keynote_id = _doc.keynote_id;
        this.name = _doc.title;
        this.title = _doc.title;
        this.show_in_iframe = _doc.show_in_iframe;
        this.show_order = _doc.show_order;
        this.show_pic = _doc.show_pic;
        this.show_video = _doc.show_video;
        this.show_voting = _doc.show_voting;
        this.show_voting_results = _doc.show_voting_results;
        this.shown_page = _doc.shown_page;
        this.updated_at = _doc.updated_at;
        this.video_url = _doc.video_url;
        this.voting_in_action = _doc.voting_in_action;
    }

}