import * as React from "react";
import "./checkmark.css";

interface CheckBoxProps {
  className?: string;
  title: string;
  value: any;
  boolean_variants?: any;
  onChange?: (_val: boolean) => void;
  checkElement?: JSX.Element | JSX.Element[];
  uncheckElement?: JSX.Element | JSX.Element[];
  elementStyle?: any;
  faIconStyle?: any;
}

interface CheckBoxState {
  value: boolean
}

export default class Checkbox extends React.Component<CheckBoxProps, CheckBoxState> {

  constructor(_props: CheckBoxProps) {
    super(_props);
    this.state = {
      value: _props.value
    }

    this.triggerValue = this.triggerValue.bind(this);
  }

  triggerValueWithValue(_value: any) {
    let me = this;
    let prevState = {...me.state};
    prevState.value = _value;
    me.setState(prevState, () => {
      if (me.props.onChange) {
        me.props.onChange(prevState.value);
      }
    });
  }

  triggerValue() {
    let me = this;
    let prevState = {...me.state};
    prevState.value = !prevState.value;
    me.setState(prevState, () => {
      if (me.props.onChange) {
        me.props.onChange(prevState.value);
      }
    });
  }

  render() {
    let me = this;
    return <div className={`checkbox-container ${me.props.className}`}>
      { (me.props.boolean_variants) &&
          Object.keys(me.props.boolean_variants).map((_title: string) => {
            let elementIsChecked = false;
            let _value = (me.props.boolean_variants)[_title];
            if ((me.state.value !== undefined) && (me.state.value !== null)  )
              elementIsChecked = (me.state.value.toString() === _value.toString());
            return <div key={`${_title} ${JSON.stringify(me.state.value)}`}
                        className={`flex content-center items-center`}>
              <p className={"checkbox-title"} style={{marginRight: "auto", padding: 0}}>{_title}</p>
              <span style={{width: "10px"}}></span>
              <span onClick={(_e) => {
                        me.triggerValueWithValue(_value)
                    }}
                    style={me.props.elementStyle}
                    className="checkmark">
                { me.props.checkElement && me.props.uncheckElement && elementIsChecked && me.props.checkElement }
                { me.props.checkElement && me.props.uncheckElement && elementIsChecked && me.props.uncheckElement }
                { (!me.props.checkElement || !me.props.uncheckElement) &&
                  <i style={{...me.props.faIconStyle, opacity: elementIsChecked ? 1 : 0.2}}
                     className={"far fa-check"}/>
                }
              </span>
          </div>;
        })
      }
      { !me.props.boolean_variants &&
          <span onClick={me.triggerValue} style={{...me.props.elementStyle}} className="checkmark">
            { me.props.checkElement && me.props.uncheckElement && me.state.value && me.props.checkElement }
            { me.props.checkElement && me.props.uncheckElement && me.state.value && me.props.uncheckElement }
            { (!me.props.checkElement || !me.props.uncheckElement) &&
                <i style={{...me.props.faIconStyle, opacity: me.state.value ? 1 : 0.2}} className={"far fa-check"}/>
            }
          </span>
      }
    </div>
  }
}