import React from "react";
import Logo from "./logo_large.svg";
import "./header.scss";
import Participant from "../../models/participants/Participant";
import {Link} from "react-router-dom";
import {UserContext} from "../../../contexts/UserContext";

interface HeaderProps {
}


export default class Header extends React.Component<HeaderProps, any> {

  constructor(_params: HeaderProps) {
    super(_params);

    this.state = {
    }

  }

  href(_url: string, _ask: boolean) {
    if (_ask) {
      let confirm = window.confirm("Вы уверены, что желаете выйти?");
      if (!confirm) return;
    }
    document.location.href = _url;
  }

  render() {
    let me = this;
    return <div className={"header"}>
      <Link to={"/"}><img src={Logo} /></Link>

      <div className={"flex flex-row items-center content-center"}>
        <UserContext.Consumer>
          {
            (data) => (
              <>
                { data.participant &&
                    <p style={{color: "var(--white)", opacity: 0.7}}>{data.participant.name()}</p>
                }
                { data.participant &&
                    <p style={{cursor: "pointer"}} className={"caramel"} onClick={() => {
                      me.href("/", false);
                    }}><i className={"fas fa-home"}/></p>
                }
                { data.participant &&
                    <p style={{cursor: "pointer"}} className={"caramel red"}
                       onClick={() => {
                         localStorage.removeItem("jwt");
                         localStorage.removeItem("csrf");
                         me.href("/", true);
                       }}><i className={"far fa-sign-out-alt"}/></p>
                }
              </>
            )
          }
        </UserContext.Consumer>
      </div>
    </div>;
  }
}

Header.contextType = UserContext;