import React from "react";
import Participant from "../../models/participants/Participant";
import "./participant-view.scss";
import ParticipantCard from "./ParticipantCard";
import {ApiEngine} from "api-engine";


interface ParticipantListViewProps {
    participant: Participant,
    api: ApiEngine,
    myself: Participant
}

interface ParticipantListViewState {
    showCard: boolean;
}

export default class ParticipantListView extends React.Component<ParticipantListViewProps, any> {
    constructor(_props: ParticipantListViewProps) {
        super(_props);

        this.state = {
            showCard: false
        }

        this.showCard = this.showCard.bind(this);
        this.hideCard = this.hideCard.bind(this);
        this.executeCommand = this.executeCommand.bind(this);
    }

    showCard() {
        let prevState = {...this.state};
        prevState.showCard = true;
        this.setState(prevState);
    }

    hideCard() {
        let prevState = {...this.state};
        prevState.showCard = false;
        this.setState(prevState);
    }

    executeCommand(_cmd: string) {
        let me = this;
        if (!me.props.myself.fullAccess) return;
        if (_cmd === "trigger_fullscreen") {
            me.props.api.websocketConnector.sendMessage(JSON.stringify(
        {
                title: "trigger_full_screen",
                session_id: me.props.participant.peer_id
            }));
        }

        if (_cmd === "trigger_video") {
            me.props.api.websocketConnector.sendMessage(JSON.stringify(
              {
                  title: "trigger_video",
                  session_id: me.props.participant.peer_id
              }));
        }

        if (_cmd === "trigger_audio") {
            me.props.api.websocketConnector.sendMessage(JSON.stringify(
              {
                  title: "trigger_audio",
                  session_id: me.props.participant.peer_id
              }));
        }
    }


    render() {
        let me = this;
        return <div onMouseEnter={me.showCard}
                    onMouseLeave={me.hideCard}
                    className={`participant-view`}>
            {/*<p>{JSON.stringify(me.props.participant.call_me)}</p>*/}
            { me.props.participant.call_me &&
              <div style={{display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center"}}>
                  <div style={{
                      display: "flex",
                      minWidth: "10px",
                      minHeight: "10px",
                      background: "var(--red)",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginTop: "auto",
                      marginBottom: "auto"
                  }}></div>
              </div>
            }
            <div className={"participant-name"}>
                <p style={{marginTop: "auto", marginBottom: "auto", textAlign: "left", width: "100%", padding: 0}}>{me.props.participant.last_name} {me.props.participant.first_name} {me.props.participant.middle_name}</p>
            </div>

            <div style={{marginLeft: "auto", display: "flex", width: "100%"}}></div>
                { me.props.myself.fullAccess &&
                  <button onClick={() => {
                      me.executeCommand("trigger_fullscreen");
                  }} style={!me.props.participant.is_on_fullscreen ? {
                      background: "var(--red)",
                      height: "40px",
                      minWidth: "30px",
                      maxWidth: "30px",
                      cursor: "pointer"
                  } : {background: "var(--green_600)", height: "40px", minWidth: "30px"}}>
                      <i className={"fas fa-desktop"}></i>
                  </button>
                }
                <button
                  onClick={() => {me.props.myself.fullAccess && me.executeCommand("trigger_audio") }}
                  style={ me.props.participant.is_muted ? {background: "var(--red)", height: "40px", minWidth: "30px", maxWidth: "30px"} : {background: "var(--green_600)", height: "40px", minWidth: "30px", maxWidth: "30px"}  }>
                    <i className={"fas fa-microphone-alt"}></i>
                </button>
                <button
                    onClick={() => {me.props.myself.fullAccess && me.executeCommand("trigger_video") }}
                    style={ me.props.participant.block_video ? {background: "var(--red)", height: "40px", minWidth: "30px", maxWidth: "30px"} : {background: "var(--green_600)", height: "40px", minWidth: "30px", maxWidth: "30px"}  }>
                    <i className={"fas fa-video"}></i>
                </button>
            { me.state.showCard &&
                <ParticipantCard participant={me.props.participant} />
            }
        </div>
    }
}