import React from "react";
import Draggable from "react-draggable";
import "../../assets/shared/elements.scss";
import "../../assets/shared/positioning.css";
import "../../assets/shared/colors.css";
import JanusConnector from "../../../gateways/janus/JanusConnector";
import Participant from "../../models/participants/Participant";
import ScreenFull from "screenfull";


interface MyStreamViewProps {
    stream?: MediaStream,
    localFeedJanusConnector: JanusConnector,
    myself: Participant
}

interface MyStreamViewState {
    stream?: MediaStream,
    connected: boolean,
    publishMyStream?: boolean
}

export default class MyStreamView extends React.Component<MyStreamViewProps, MyStreamViewState> {
    private myVideoRef: React.RefObject<HTMLVideoElement>;
    private myVideoDispositionRef: React.RefObject<HTMLDivElement>;
    private localFeedJanusConnector: JanusConnector;

    constructor(_props: MyStreamViewProps) {
        super(_props);
        this.state = {
            stream: _props.stream,
            connected: true
        }
        this.myVideoRef = React.createRef<HTMLVideoElement>();
        this.myVideoDispositionRef = React.createRef<HTMLDivElement>();

        this.localFeedJanusConnector = this.props.localFeedJanusConnector;
        this.goFullscreen = this.goFullscreen.bind(this);
        this.connect = this.connect.bind(this);
        this.checkIfPublishing = this.checkIfPublishing.bind(this);
    }

    componentDidMount() {
    }


    checkIfPublishing() {
        let me = this;
        if (me.localFeedJanusConnector.successfullyConnectedAsPublisher !== undefined) {
            let prevState = {...me.state};
            prevState.publishMyStream = me.localFeedJanusConnector.successfullyConnectedAsPublisher;
            me.setState(prevState);
            return
        }
        setTimeout(me.checkIfPublishing, 500);
    }

    connect(_publishMyStream: boolean) {
        let me = this;
        // alert(`${me.props.localFeedJanusConnector.name} ${_publishMyStream ? 'y' : 'n'}`);
        let prevState = {...me.state};
        me.setState(prevState, () => {
            if (!me.myVideoDispositionRef) return;
            if (!me.myVideoDispositionRef.current) return;

            me.localFeedJanusConnector.connect(me.myVideoDispositionRef.current).then((e) => {
                me.localFeedJanusConnector.publishing = _publishMyStream;
                me.localFeedJanusConnector.connecting = true;
                const event = new CustomEvent<any>('NewUser', {detail: me.props.myself.name()});
                document.dispatchEvent(event);

                let sentName = `${me.props.myself.name()}#!#!#${me.props.myself.peer_id}#!#!#${me.props.myself.role}#!#!#${me.props.myself.id}`;
                // alert(sentName);

                let register = {
                    request: "join",
                    room: e.room,
                    ptype:"publisher",
                    display: sentName
                };
                // alert(JSON.stringify(register));
                me.localFeedJanusConnector.publishingFeedJanusStrategy.sfu.send({ message: register });
                me.checkIfPublishing();
            });
        });
        // if (!me.myVideoRef) return;
        // if (!me.myVideoRef.current) return;
        // me.myVideoRef.current.srcObject = me.state.stream;
        // me.myVideoRef.current.play();
    }

    dot(_color:string) {
        return <span style={{marginRight: "4px", background: "#A82845", display: "block", width: "10px", height: "10px", borderRadius: "50%"}}></span>;
    }

    goFullscreen() {
        if (this.myVideoDispositionRef.current) {
            ScreenFull.request(this.myVideoDispositionRef.current);
        }
    }

    render() {
        let me = this;
        return (
            <div onClick={me.goFullscreen}
                 className={"video-remote"}
                 style={{display: (me.state.connected && (me.state.publishMyStream)) ? "flex" : "none"}}
                 ref={me.myVideoDispositionRef}>
                {/*<p style={{fontWeight: 900}}>{me.props.myself.name()} serv{me.localFeedJanusConnector.name}({me.state.publishMyStream ? "1" : "2"})</p>*/}
                <p style={{fontWeight: 900}}>{me.props.myself.name()}</p>
            </div>
        );
    }
}