import React from "react";
import baseConstraints from "./video-constraints.json";
import "./lobby.scss";

interface LobbyProps {
    logo?: JSX.Element | null;
    blockMobile: boolean;
    streamReady: (_stream: any, _audioDevices: any[], _videoDevices: any[], _audioDevice: string, _videoDevice: string) => void
}

interface LobbyState {
    audioDeviceId: string | null,
    videoDeviceId: string | null,
    audioDevice: MediaDeviceInfo | null,
    videoDevice: MediaDeviceInfo | null,
    audioDevices: any[],
    videoDevices: any[],
    currentStream: MediaStream | null,
    currentConstraints: any,
    joinButtonDisabled: boolean,
    constraints: any
}

export default class Lobby extends React.Component<LobbyProps, LobbyState> {
    private myRef: React.RefObject<HTMLDivElement>;
    private videoPreviewRef: React.RefObject<HTMLVideoElement>;
    private iHaveErrorWithAudio: boolean;
    private mounted: boolean = false;

    constructor(_props: LobbyProps) {
        super(_props);
        this.state = {
            audioDeviceId: null,
            videoDeviceId: null,
            audioDevice: null,
            videoDevice: null,
            audioDevices: [],
            videoDevices: [],
            currentStream: null,
            currentConstraints: {},
            joinButtonDisabled: true,
            constraints: null
        };

        this.myRef = React.createRef<HTMLDivElement>();
        this.videoPreviewRef = React.createRef<HTMLVideoElement>();
        this.setVideoDeviceId = this.setVideoDeviceId.bind(this);
        this.setAudioDeviceId = this.setAudioDeviceId.bind(this);
        this.updateStream = this.updateStream.bind(this);
        this.connectToConf = this.connectToConf.bind(this);
        this.connectMediaDevices = this.connectMediaDevices.bind(this);

        this.iHaveErrorWithAudio = false;
    }

    componentDidMount() {
        if (this.mounted) return;
        this.mounted = true;

        let me = this;
        me.connectMediaDevices();
    }

    connectMediaDevices() {
        let me = this;
        console.log("Connecting media devices");
        if (navigator.mediaDevices) {
            let constraints = {audio: true, video: true};
            if (me.iHaveErrorWithAudio) {
                constraints = {audio: true, video: false};
            }
            // alert(JSON.stringify(constraints));
            // alert(123);

            navigator.mediaDevices.getUserMedia(constraints).then((e) => {
                navigator.mediaDevices.enumerateDevices().then((_devices: MediaDeviceInfo[]) => {
                    me.addDevicesToSelects(_devices);
                });
            }).catch((_e) => {
                alert("Мы не обнаружили камеру на Вашем устройстве. Пожалуйста, подключите ее и повторите попытку входа.")
                // me.iHaveErrorWithAudio = true;
                // setTimeout(me.connectMediaDevices, 3000);
                return;
            });
        } else {
            alert("На Вашем устройстве не подключены устройства ввода данных, либо не даны соответствующие разрешения вашему браузеру, либо адресу текущего сайта.");
        }
    }

    setConstraints(_constraints: { video: MediaDeviceInfo | undefined; audio: MediaDeviceInfo | undefined; }) {
        let me = this;
        if (!_constraints.video || !_constraints.audio) {
            alert("Система не может подключить камеру или микрофон, пожалуйста, перезапустите браузер");
        } else {
            let prevState = {...me.state};
            prevState.constraints = {
                video: _constraints.video,
                audio: _constraints.audio
            };
            me.setState(prevState, () => {
                me.tryToConnectToCamera();
            });
        }
    }

    async setAudioDeviceId(_audioDeviceId: React.ChangeEvent<HTMLSelectElement>) {
        let me = this;
        let prevState = {...me.state};
        prevState.audioDeviceId = _audioDeviceId.target.value;
        me.setState(prevState, () => {});
    }

    async setVideoDeviceId(_videoDeviceId: React.ChangeEvent<HTMLSelectElement>) {
        let me = this;
        let prevState = {...me.state};
        prevState.videoDeviceId = _videoDeviceId.target.value;
        me.setState(prevState, () => {
            me.updateStream().then((_stream: MediaStream) => {
                let prevState = {...me.state};
                prevState.currentStream = _stream;
                prevState.joinButtonDisabled = false;
                me.setState(prevState);
            });
        });
    }

    tryToConnectToCamera() {
        let me = this;
        if (!me.state.constraints) {
            alert("\"Система не может подключить камеру или микрофон, пожалуйста, убедитесь, что камера не используется другими программами, перезапустите браузер и проверьте настройки Вашего антивируса.");
            return;
        }
    }

    async updateStream(): Promise<MediaStream> {
        let me = this;
        return new Promise(((resolve, reject) => {
            if (!(me.state.audioDeviceId && me.state.videoDeviceId)) return;

            if (me.state.currentStream) {
                me.state.currentStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            let videoConstraints = {...baseConstraints} as any;
            videoConstraints.deviceId = {exact: me.state.videoDeviceId};
            let audioConstraints = {deviceId: me.state.audioDeviceId};

            let constraints = {
                video: videoConstraints,
                audio: audioConstraints
            };

            me.setConstraintsAndGetStream(constraints, me.videoPreviewRef).then( (_stream: any) => {
                resolve(_stream);
            });
        }));
    }

    async setConstraintsAndGetStream(_constraints: any, previewRef: React.RefObject<HTMLVideoElement>) {
        let me = this;
        return new Promise(function(resolve, reject) {
            navigator.mediaDevices
                .getUserMedia(_constraints)
                .then(stream => {
                    let prevState = {...me.state};
                    prevState.currentConstraints = _constraints;
                    me.setState(prevState, () => {
                        if (!previewRef) return;
                        if (!previewRef.current) return;

                        previewRef.current.srcObject = stream;
                        previewRef.current.play().then((e) => {
                            resolve(stream);
                        });
                    });
                });
        });
    }

    addDevicesToSelects(_devices: MediaDeviceInfo[]) {
        let me = this;
        let videoDevices = [] as {deviceId: string, label: string}[];
        let audioDevices = [] as {deviceId: string, label: string}[];
        console.log(_devices);
        _devices.forEach((_device: MediaDeviceInfo) => {
            if ('videoinput' === _device.kind) {
                videoDevices.push({
                    deviceId: _device.deviceId,
                    label: _device.label
                });
            }

            if ('audioinput' === _device.kind) {
                audioDevices.push({
                    deviceId: _device.deviceId,
                    label: _device.label
                });
            }
        });
        let prevState = {...me.state};
        prevState.videoDevices = videoDevices;
        prevState.audioDevices = audioDevices;
        me.setState(prevState);
    }

    connectToConf() {
        // alert(this.state.currentStream);
        if (!this.state.videoDeviceId || !this.state.audioDeviceId) return;
        this.props.streamReady(this.state.currentStream, this.state.audioDevices, this.state.videoDevices, this.state.audioDeviceId, this.state.videoDeviceId);
    }
    
    render() {
        let me = this;
        return <div ref={me.myRef}
                    className={"lobby"}>

            <div className={"main-window"}>
                { me.props.logo &&
                    <div style={{
                        position: "absolute",
                        width: "14vw",
                        top: "1%",
                        right: "1%"
                    }}>
                        {me.props.logo}
                    </div>
                }

                <h2>Вход в собрание</h2>
                <div className="select" key={JSON.stringify(me.state.audioDevices)} >
                    <select defaultValue={"null"} onChange={me.setAudioDeviceId} id="select-audio">
                        <option value={"null"} >Выберите микрофон</option>
                        {   me.state.audioDevices.map((e, eId) => {
                            return <option key={`audio-${eId}`} value={e.deviceId}>{e.label}</option>
                        })
                        }
                    </select>
                </div>
                <div key={`aud-dev-id-${me.state.audioDeviceId}`} className="select" style={!me.state.audioDeviceId ? {opacity: 0.5, pointerEvents: "none"} : {} }>
                    <select defaultValue={"null"} onChange={me.setVideoDeviceId} id="select-video">
                        <option value={"null"} >Выберите камеру</option>
                        {   me.state.videoDevices.map((e, eId) => {
                            return <option key={`audio-${eId}`} value={e.deviceId}>{e.label}</option>
                        })
                        }
                    </select>
                </div>
                <div className={"intro-video"}>
                    <video ref={me.videoPreviewRef} id="video-0" playsInline={true} controls={true} muted={true} />
                </div>

                <button onClick={me.connectToConf} style={me.state.joinButtonDisabled ? {opacity: 0.5, pointerEvents: "none"} : {} } >
                <span style={{marginLeft: "auto", marginRight: "auto"}}>
                    ПРИСОЕДИНИТЬСЯ
                </span>
                </button>
                <br/>
            </div>
        </div>;
    }
}