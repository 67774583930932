import React from "react";
import "./multicircle-spinner.css";

interface CircleSpinnerProps {
  circleColor?: string;
  dotsColor?: string;
  scale?: number
}

export default class MulticircleSpinner extends React.Component<CircleSpinnerProps, any> {
  constructor(_props: CircleSpinnerProps) {
    super(_props);
  }

  render() {
    let me = this;
    return <div>
      <div className="multicircle-loader"></div>
    </div>;
  }
}
