import React from "react";
import "../../../assets/shared/positioning.css";
import "../../../assets/shared/elements.scss";
import Document from "../../../models/documents/Document";
import DocumentControlView from "./DocumentControlView";
import Participant from "../../../models/participants/Participant";
import {ApiEngine} from "api-engine";

interface ConferenceControlProps {
    serverUrl: string;
    documents: Document[];
    activeDocumentId: number | null;
    api: ApiEngine;
    myself: Participant;
}

interface ConferenceControlState {
    documents: Document[];
    activeDocumentId: number | null;
    loading: boolean;
    contextMenuOpen: boolean;
    generation: number;
}

export default class ConferenceControl extends React.Component<ConferenceControlProps, ConferenceControlState> {
    mounted: boolean = false;

    constructor(_props: ConferenceControlProps) {
        super(_props);

        this.state = {
            documents: this.props.documents,
            activeDocumentId: this.props.activeDocumentId,
            loading: false,
            contextMenuOpen: false,
            generation: 0
        };

        this.triggerLoading = this.triggerLoading.bind(this);
        this.onContextMenuOpen = this.onContextMenuOpen.bind(this);
        this.onContextMenuClose = this.onContextMenuClose.bind(this);
    }

    componentDidMount() {
        if (this.mounted) return;
        this.mounted = true;

        let me = this;

        document.addEventListener("Flow", (_data: any) => {
            // console.log(_data.detail);
            if (_data.detail.title === "SET_ACTIVE_DOC_ID") {
                // alert(`Income ${_data.detail.value}`);
                me.setState({
                    generation: me.state.generation + 1,
                    activeDocumentId: _data.detail.value});
            }

            if (_data.detail.title === "RELOAD_CONTENT") {
                let documents = [...me.state.documents];
                // alert(`Income ${_data.detail.value.content.id}`);
                let documentIndex = documents.findIndex((_d: Document) => {
                    // alert(`Fact: ${_d.id}`);
                    return _d.id === _data.detail.value.content.id
                });
                if (documentIndex === -1) {
                    // alert("No doc")
                    return;
                }
                documents[documentIndex] = _data.detail.value.content;
                me.setState({
                    generation: me.state.generation + 1,
                    documents: documents});
            }
        });
    }

    triggerLoading() {
        let me = this;
        let prevState = {...me.state};
        prevState.loading = !prevState.loading;
        me.setState(prevState);
    }

    onContextMenuOpen() {
        let me = this;
        let prevState = {... this.state};
        prevState.contextMenuOpen = true;
        me.setState(prevState);
    }

    onContextMenuClose() {
        let me = this;
        let prevState = {... this.state};
        prevState.contextMenuOpen = false;
        me.setState(prevState);
    }

    render() {
        let me = this;
        return <div style={{marginTop: "7px", height: "100%"}}
                    className={"card-100 conference-control"}
        >
            <span className={"flex flex-row justify-between"}>
                <h4>Ход ведения
                    { (true || me.props.myself.fullAccess) && <i
                      onClick={() => {
                          me.props.api.websocketConnector.sendMessage(JSON.stringify({
                              title: "reload",
                              // document_id: me.state.document.id,
                              // value: me.state.currentPage
                          }));
                      }}
                      className={"fal fa-undo"} style={{marginLeft: "auto"}}></i> }</h4>

            </span>
            <div style={{   height: "100%",
                            maxHeight: "28vh",
                            overflowX: "hidden",
                            // overflowY: me.state.contextMenuOpen ? "visible" : "scroll",
                            overflowY: "scroll",
                            direction: "ltr",
                            borderRadius: "0px 0px 10px 10px"}}>
                <div style={me.state.loading ? {opacity: 0.5, pointerEvents: "none"} : {} }>
                    { me.state.documents.map((_d: Document, _docIndex:number) => {
                        return <DocumentControlView
                                  api={me.props.api}
                                  myself={me.props.myself}
                                  onContextMenuOpen={me.onContextMenuOpen}
                                  onContextMenuClose={me.onContextMenuClose}
                                  triggerLoading={me.triggerLoading}
                                  serverUrl={me.props.serverUrl}
                                  active={_d.id === me.state.activeDocumentId}
                                  key={`${me.state.generation}${JSON.stringify(_d)}${_d.id}-${_docIndex}`}
                                  document={_d}
                            />;
                      })
                    }

                </div>
            </div>

        </div>;
    }
}